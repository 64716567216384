import { createSlice } from "@reduxjs/toolkit";
import { ISpace } from "../../../interfaces";
import { store } from "../../store";
import { GQL_SPACES } from "../../../graphql/Queries";
import { apolloClient } from "../../../ApolloClient";



interface ISpaceSliceState {
    spaces: ISpace[],
    spaceLoading: boolean,
}

const initialState: ISpaceSliceState = {
    spaces: [],
    spaceLoading: false,
}

export const spaceSlice = createSlice({
    name: "spaceSlice",
    initialState,
    reducers: {
        setSpaces: (state, action) => {
            state.spaces = action.payload
        },
        setSpaceLoading: (state, action) => {
            state.spaceLoading = action.payload
        },
        getSpaces: (state,) => {
            state.spaceLoading = true
            fetchSpaces()
        },
    }
})

export const { setSpaces, getSpaces, setSpaceLoading
} = spaceSlice.actions

const fetchSpaces = () => {
    apolloClient.query({
        query: GQL_SPACES,
        fetchPolicy: 'network-only',
        variables: {
            // reference: reference,
        }
    }
    ).then(response => {
        store.dispatch(setSpaces(response.data.spaces))
        store.dispatch(setSpaceLoading(false))
    })
}

export default spaceSlice.reducer