import { FC, useEffect, useState } from 'react'
import { IPlanningShowFilter, ISpace } from '../../interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../redux/store'
import { Flexbox } from '../utils/Flexbox'
import { Checkbox, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Typography, useTheme } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { getBeginningOfTheMonth, getBeginningOfTheWeek, getHourFormatted } from '../../utils/helpers'
import { SpacesPlanningColumn } from './SpacesPlanningColumn'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/fr'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { EnumMessageType, EnumPlanningBy, EnumPlanningShowFilterAction } from '../../enums'
import { addMessage } from '../messages/Message'
import { setDatePlanningAction, setPlanningByAction, setPlanningShowFilter } from '../../redux/features/global/globalSlice'
// import { setDatePlanningAction } from '../../redux/features/global/globalSlice'


interface IProps {
}

export const SpacesPlanning: FC<IProps> = (props) => {
    const datePlanning: Dayjs = dayjs(useSelector((state: IRootState) => state.globalReducer.datePlanning)).locale('fr')
    const planningBy: EnumPlanningBy = useSelector((state: IRootState) => state.globalReducer.planningBy)
    const planningShowFilter: IPlanningShowFilter = useSelector((state: IRootState) => state.globalReducer.planningShowFilter)
    const datePlanningBegin: Dayjs = planningBy === EnumPlanningBy.week ? getBeginningOfTheWeek(datePlanning) : getBeginningOfTheMonth(datePlanning)
    const theme = useTheme()
    const startHour = 6
    const endHour = 23
    const hours = []
    for (let index = startHour; index < endHour; index++) {
        hours.push(index)
    }
    const hourHeight = 45
    const minuteHeight = hourHeight / 60
    const columnHeaderHeight = 30
    const nbDaysOfPlanning = planningBy === EnumPlanningBy.week ? 7 : datePlanning.daysInMonth()

    const dispatch = useDispatch()


    const previousDatePlanning = () => {
        if (planningBy === EnumPlanningBy.week) {
            handleDatePlanning(datePlanning.subtract(nbDaysOfPlanning, 'day'))
        } else if (planningBy === EnumPlanningBy.month) {
            handleDatePlanning(datePlanning.subtract(1, 'month'))
        } else {
            addMessage({
                type: EnumMessageType.Error,
                message: "Calcul de previousDatePlanning impossible sur ce type de planning inconnu.",
            })
        }
    }

    const nextDatePlanning = () => {
        if (planningBy === EnumPlanningBy.week) {
            handleDatePlanning(datePlanning.add(nbDaysOfPlanning, 'day'))
        } else if (planningBy === EnumPlanningBy.month) {
            handleDatePlanning(datePlanning.add(1, 'month'))
        } else {
            addMessage({
                type: EnumMessageType.Error,
                message: "Calcul de nextDatePlanning impossible sur ce type de planning inconnu.",
            })
        }
    }

    const handleDatePlanning = (newDate: Dayjs | null) => {
        if (newDate) {
            dispatch(setDatePlanningAction(newDate.format()))
        }
    }

    const handleChangePlanningBy = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === EnumPlanningBy.week) {
            dispatch(setPlanningByAction(EnumPlanningBy.week))
        } else if (event.target.value === EnumPlanningBy.month) {
            dispatch(setPlanningByAction(EnumPlanningBy.month))
        } else {
            addMessage({
                type: EnumMessageType.Error,
                message: "Type de planning inconnu.",
            })
        }
    }

    const handlePlanningShowFilter = (planningShowFilterAction: EnumPlanningShowFilterAction) => {
        if (planningShowFilterAction == EnumPlanningShowFilterAction.toggleSpaceWorkshop) {
            dispatch(setPlanningShowFilter({ ...planningShowFilter, spaceWorkshop: !planningShowFilter.spaceWorkshop }))
        } else if (planningShowFilterAction == EnumPlanningShowFilterAction.toggleSpaceTerrace) {
            dispatch(setPlanningShowFilter({ ...planningShowFilter, spaceTerrace: !planningShowFilter.spaceTerrace }))
        } else {
            addMessage({
                type: EnumMessageType.Error,
                location: "SpacesPlanning",
                message: "planningShowFilterAction inconnue !"
            })
        }
    }

    useEffect(() => {
        handleDatePlanning(datePlanning)
    }, [planningBy])

    return (
        <>
            <Flexbox direction='column' sx={{ width: "100%", mx: { xs: 3, sm: 8 } }}>
                <Flexbox sx={{
                    justifyContent: "space-around",
                    width: "100%",
                }}>
                    <Flexbox>
                        <IconButton onClick={() => previousDatePlanning()} sx={{ color: theme.palette.primary.main }}>
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                label="Date"
                                value={datePlanning}
                                onChange={(newDate) => handleDatePlanning(newDate)}
                            />
                        </LocalizationProvider>
                        <IconButton onClick={() => nextDatePlanning()} sx={{ color: theme.palette.primary.main }}>
                            <KeyboardArrowRightIcon />
                        </IconButton>
                    </Flexbox>
                    <Flexbox direction='column' sx={{
                        alignItems: "flex-start",
                    }}>
                        <FormControlLabel control={
                            <Checkbox
                                checked={planningShowFilter.spaceWorkshop}
                                onChange={() => handlePlanningShowFilter(EnumPlanningShowFilterAction.toggleSpaceWorkshop)}
                            />
                        } label="Atelier" />
                        <FormControlLabel control={
                            <Checkbox
                                checked={planningShowFilter.spaceTerrace}
                                onChange={() => handlePlanningShowFilter(EnumPlanningShowFilterAction.toggleSpaceTerrace)}
                            />
                        } label="Terrasse" />
                    </Flexbox>
                    <Flexbox>
                        <FormControl>
                            <RadioGroup
                                value={planningBy}
                                onChange={handleChangePlanningBy}
                            >
                                <FormControlLabel value={EnumPlanningBy.week} control={<Radio />} label="Semaine" />
                                <FormControlLabel value={EnumPlanningBy.month} control={<Radio />} label="Mois" />
                            </RadioGroup>
                        </FormControl>
                    </Flexbox>

                </Flexbox>


                <Flexbox sx={{ mt: 4, alignItems: "flex-start", width: "100%" }}>
                    <Flexbox direction='column'>
                        <Typography sx={{ height: columnHeaderHeight }}>
                            &nbsp;
                        </Typography>
                        {
                            hours.map((hour, index) => <Flexbox key={index} sx={{ alignItems: "flex-start" }}>
                                <Flexbox
                                    sx={{
                                        width: "20px",
                                        height: `${hourHeight}px`,
                                        marginTop: `-${hourHeight / 2}px`,
                                        justifyContent: "flex-end",
                                        mr: 1,
                                    }}
                                >
                                    <Typography sx={{ color: theme.palette.primary.main }}>
                                        {getHourFormatted(hour)}
                                    </Typography>
                                </Flexbox>
                                <Flexbox
                                    sx={{
                                        borderTopStyle: "solid",
                                        borderTopWidth: 1,
                                        borderColor: theme.palette.primary.main,
                                        width: "20px",
                                        height: `${hourHeight}px`,
                                        borderRightStyle: "dashed",
                                        borderRightWidth: 1,
                                    }}
                                >&nbsp;</Flexbox>
                            </Flexbox>)
                        }
                    </Flexbox>
                    {Array.from({ length: nbDaysOfPlanning }, (_, index) => (
                        <SpacesPlanningColumn key={index} planningBy={planningBy} date={datePlanningBegin.add(index, 'day')} minuteHeight={minuteHeight} hourHeight={hourHeight} startHour={startHour} endHour={endHour} columnHeaderHeight={columnHeaderHeight} />
                    ))}
                </Flexbox>
            </Flexbox>
        </>
    )
}
