import { FC, useState } from 'react'
import { Box, Typography, useTheme } from "@mui/material";
import * as MuiCarousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import { red } from '@mui/material/colors';
import { WaveIcon } from './svg_icons/WaveIcon';
import { EnumTitleSectionMode } from '../enums';

interface IProps {
    title: String,
    subtitle?: String,
    mode: EnumTitleSectionMode,
}

export const TitleSection: FC<IProps> = (props) => {
    const theme = useTheme()

    return (
        <>
            {
                props.mode === EnumTitleSectionMode.standard && <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <Typography sx={{ fontFamily: theme.typography.alternative, color: theme.palette.primary.main, fontSize: { xs: 20, sm: 30, md: 40 }, fontWeight: "regular" }}>
                        {props.title}
                    </Typography>
                    {
                        props.subtitle && <Typography sx={{
                            fontFamily: theme.typography.alternative,
                            color: theme.palette.secondary.main,
                            fontSize: { xs: 16, sm: 20, md: 30 },
                            fontWeight: "regular",
                            textAlign: "center",
                        }}>
                            {props.subtitle}
                        </Typography>
                    }
                    <WaveIcon color="secondary" sx={{ width: { xs: "215px", sm: "335px" }, mt: 2, mb: 4 }} />
                </Box>
            }
            {
                props.mode === EnumTitleSectionMode.admin && <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <Typography sx={{ fontFamily: theme.typography.alternative, color: theme.palette.primary.main, fontSize: 30, my: 2 }}>
                        {props.title}
                    </Typography>
                </Box>
            }
        </>

    )
}


