import { FC, useState } from 'react'
import { Box, Typography, useTheme } from "@mui/material";
import { TitleSection } from '../components/TitleSection';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { EnumTherapist, EnumTherapistMode, EnumTitleSectionMode } from '../enums';
import { TherapistCard } from '../components/TherapistCard';
import { KazOceaSpan } from '../components/KazOceaSpan';
import { Flexbox } from '../components/utils/Flexbox';

interface IProps { }

export const TherapistsSection: FC<IProps> = (props) => {



    return (
        <Box id="TherapistsSection" className='TherapistsSection' sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", my: 4 }}>
            <TitleSection title="Nos thérapeutes" mode={EnumTitleSectionMode.standard} />
            <Box sx={{ mb: 4, mt: 2, px: 2, textAlign: "center" }}>
                <Typography>
                    L’équipe de <KazOceaSpan /> est constituée de thérapeutes spécialisés en périnatalité, enfant, femme et famille, et sont à l’écoute de vos situations et besoins.
                </Typography>
            </Box>
            <Grid container spacing={2} sx={{ mx: 4, mb: 0, width: "100%" }}>
                <Grid xs={12} sm={6} md={4}>
                    <TherapistCard mode={EnumTherapistMode.card} therapist={EnumTherapist.audreyMeese} />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <TherapistCard mode={EnumTherapistMode.card} therapist={EnumTherapist.vanessaLevy} />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <TherapistCard mode={EnumTherapistMode.card} therapist={EnumTherapist.valeriePayet} />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <TherapistCard mode={EnumTherapistMode.card} therapist={EnumTherapist.malikaFontaine} />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <TherapistCard mode={EnumTherapistMode.card} therapist={EnumTherapist.liseRousseliere} />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <TherapistCard mode={EnumTherapistMode.card} therapist={EnumTherapist.benedicteLeParc} />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <TherapistCard mode={EnumTherapistMode.card} therapist={EnumTherapist.marineDupeyron} />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <TherapistCard mode={EnumTherapistMode.card} therapist={EnumTherapist.veroniqueFontaine} />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <TherapistCard mode={EnumTherapistMode.card} therapist={EnumTherapist.amandineCollard} />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <TherapistCard mode={EnumTherapistMode.card} therapist={EnumTherapist.amandineBlache} />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <TherapistCard mode={EnumTherapistMode.card} therapist={EnumTherapist.francoisHoarau} />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <TherapistCard mode={EnumTherapistMode.card} therapist={EnumTherapist.erwanLeblond} />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <TherapistCard mode={EnumTherapistMode.card} therapist={EnumTherapist.louisTiersonnier} />
                </Grid>
            </Grid>
            <Box sx={{ px: 2, textAlign: "center", mt: 3 }}>
                <Typography>
                    Toutes les séances se font sur rendez-vous, par téléphone ou sur internet selon les thérapeutes.
                    <br />
                    L’accès au centre est possible si vous avez rendez-vous avec votre thérapeute.
                    <br />
                    Les espaces d’attentes sont répartis à l’extérieur, autour de la maison.
                </Typography>
            </Box>
            <Grid container spacing={2} sx={{ mx: 4, mt: 4, width: "100%" }}>
                <Grid xs={12} sm={6} md={4}>
                    <Box component="img" src={`${process.env.PUBLIC_URL}/images/carousel/carousel_002_hd.jpg`}
                        sx={{
                            width: "100%",
                            borderStyle: "solid",
                            borderWidth: 0,
                            borderRadius: "24px",
                        }}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <Box component="img" src={`${process.env.PUBLIC_URL}/images/carousel/carousel_004_hd.jpg`}
                        sx={{
                            width: "100%",
                            borderStyle: "solid",
                            borderWidth: 0,
                            borderRadius: "24px",
                        }}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <Box component="img" src={`${process.env.PUBLIC_URL}/images/carousel/carousel_005_hd.jpg`}
                        sx={{
                            width: "100%",
                            borderStyle: "solid",
                            borderWidth: 0,
                            borderRadius: "24px",
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}


