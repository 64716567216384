import React, { FC } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, SxProps, Typography, useTheme } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import { EnumKazOceaListMode } from '../enums';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface IProps {
    elements: React.JSX.Element[];
    textSx?: SxProps,
    iconSx?: SxProps,
    mode: EnumKazOceaListMode,
}

export const KazOceaList: FC<IProps> = (props) => {
    const theme = useTheme()

    return (
        <>
            {
                (props.mode === EnumKazOceaListMode.standard || props.mode === EnumKazOceaListMode.alternative) &&
                <List>
                    {props.elements.map((element, index) => (
                        <ListItem key={index} alignItems='flex-start'>
                            <ListItemIcon sx={{ minWidth: "20px", ...props.iconSx }}>
                                {
                                    props.mode && props.mode === EnumKazOceaListMode.alternative
                                        ?
                                        <ChevronRightIcon sx={{ width: "1.5em", color: theme.palette.primary.main }} />
                                        :
                                        <CircleIcon sx={{ width: "8px", color: theme.palette.primary.main }} />
                                }
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography sx={props.textSx}>
                                    {element}
                                </Typography>
                            } />
                        </ListItem>
                    ))}
                </List>
            }
            {
                props.mode === EnumKazOceaListMode.table &&
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", width: "100%" }}>
                    {props.elements.map((element, index) => (
                        <Box key={index} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", width: "100%" }}>
                            <CircleIcon sx={{ width: "8px", color: theme.palette.primary.main, ml: 2, mr: 1 }} />
                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                {element}
                            </Box>
                        </Box>
                    ))}
                </Box >
            }
        </>
    )
}


