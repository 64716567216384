import { gql } from "@apollo/client"


export const GQL_FRAGMENT_USER = gql`
  fragment User on User {
    id
    email
    firstname
    lastname
    userType
    admin
  }
`

export const GQL_FRAGMENT_CUSTOMER = gql`
  fragment Customer on Customer {
    id
    email
    firstname
    lastname
    fullname
    enterprise
  }
`


export const GQL_FRAGMENT_SPACE = gql`
  fragment Space on Space {
    id
    reference
    title
    hourPrice
    intervalTime
  }
`
export const GQL_FRAGMENT_RESERVATION = gql`
  fragment Reservation on Reservation {
    id
    title
    startAt
    endAt
    price
    state
    spaceIds
    reservationType
  }
`



