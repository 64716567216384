
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc';
import { EnumDayjsFormat } from '../enums';
import { IEmptySlot, IReservation, IReservationSlot, ISpace, ISpaceInfos } from '../interfaces';

// export const renderHtml = (p_string: (string | undefined)) => {
//     return p_string?.split("\n").map(function (item, idx) {
//         return (
//             <span key={idx}>
//                 {item}
//                 <br />
//             </span>
//         )
//     })
// }

dayjs.extend(utc);

export const getDayjsFormat = (date: string, format: EnumDayjsFormat) => {
    // console.log("input date is: ", date)
    const res = dayjs.utc(date)
    // console.log("res is: ", res)
    return res.local().format(format)
}

export const getReservationsOfDate = (reservations: IReservation[], date: Dayjs) => {
    var res: IReservation[] = []
    reservations.forEach(reservation => {
        // console.log("comparing ", dayjs.utc(reservation.startAt), " with ", date)
        if (dayjs.utc(reservation.startAt).isSame(date, 'day')) {
            // console.log("sameDay for ", dayjs.utc(reservation.startAt), " with ", date)
            res.push(reservation)
        }
    });
    return res
}

export const renderEuros = (p_amount: (string | number)) => {
    let amount = parseFloat(p_amount.toString())
    amount = Number((amount).toFixed(2))
    const splitted_amount = amount.toString().split('.')
    let string_amount = splitted_amount[0]
    if (splitted_amount.length > 1) {
        string_amount += "," + splitted_amount[1]
        if (splitted_amount[1].length === 1) {
            string_amount += "0"
        }
    } else {
        string_amount += ",00"
    }
    return `${string_amount} €`
}

export const getDayjsDuration = (startAt: Dayjs, endAt: Dayjs) => {
    // const startAt = dayjs.utc(reservation.startAt)
    // const endAt = dayjs.utc(reservation.endAt)
    return endAt.diff(startAt, 'minute')
}

export const getReservationDuration = (reservation: IReservation) => {
    // const startAt = dayjs.utc(reservation.startAt)
    // const endAt = dayjs.utc(reservation.endAt)
    // return endAt.diff(startAt, 'minute')
    return getDayjsDuration(dayjs.utc(reservation.startAt), dayjs.utc(reservation.endAt))
}

export const getReservationHeight = (reservation: IReservation, minute_height: number) => {
    const duration = getReservationDuration(reservation)
    // return `${duration * minute_height}px`
    return duration * minute_height
}

export const getEmptySlotHeight = (emptySlot: IEmptySlot, minute_height: number) => {
    const duration = getDayjsDuration(dayjs.utc(emptySlot.startAt), dayjs.utc(emptySlot.endAt))
    // return `${duration * minute_height}px`
    return duration * minute_height
}

export const getDaySlots = (reservations: IReservation[], date: Dayjs, startHour: number, endHour: number) => {
    var res: (IReservationSlot | IEmptySlot)[] = []
    var currentHDatetime = date.startOf('day').hour(startHour);
    reservations.forEach((reservation) => {
        const deltaTime = getDayjsDuration(currentHDatetime, dayjs.utc(reservation.startAt))
        if (deltaTime > 0) {
            const emptySlot: IEmptySlot = {
                interfaceType: "IEmptySlot",
                startAt: currentHDatetime,
                endAt: dayjs.utc(reservation.startAt),
            }
            res.push(emptySlot)
        }
        const reservationSlot: IReservationSlot = {
            interfaceType: "IReservationSlot",
            reservation: reservation,
        }
        res.push(reservationSlot)
        currentHDatetime = dayjs.utc(reservation.endAt)
    })
    // Last emptySlot if needed
    if (getDayjsDuration(currentHDatetime, date.startOf('day').hour(endHour)) > 0) {
        const emptySlot: IEmptySlot = {
            interfaceType: "IEmptySlot",
            startAt: currentHDatetime,
            endAt: date.startOf('day').hour(endHour),
        }
        res.push(emptySlot)
    }
    return res
}

export const getHourFormatted = (hour: number) => {
    return hour < 10 ? `0${hour}:00` : `${hour}:00`
}

export const getSpacesPriceForSlot = (spaces: ISpaceInfos[], startAt: Dayjs, endAt: Dayjs) => {
    const nbHours = (getDayjsDuration(startAt, endAt) / 60)
    let spacesHourPrice = 0
    spaces.forEach(space => {
        spacesHourPrice += parseFloat(space.hourPrice)
    });

    // var res = nbHours * parseFloat(space.hourPrice)
    var res = nbHours * spacesHourPrice
    if (res < 0) {
        res = 0
    }
    // console.log("nbHours: ", nbHours, "hourPrice: ", parseFloat(space.hourPrice), "res: ", res, "startAt:", startAt, "endAt:", endAt)
    return res
}

export const checkSlotAvailable = (space: ISpace, startAt: Dayjs, endAt: Dayjs, originalReservation: (IReservation | null)) => {
    var res = true
    space.reservations.forEach((reservation, index) => {
        if (originalReservation && originalReservation.id === reservation.id) {
            // Il s'agit de la réservation en cours de modification..
            console.log("same reservation detected.")
        } else {
            const reservationStartAt = dayjs.utc(reservation.startAt)
            const reservationEndAt = dayjs.utc(reservation.endAt)
            if (!isNotOverLap(startAt, endAt, reservationStartAt, reservationEndAt)) {
                // console.log(" res setted to false in checkSlotAvailable !!")
                res = false
            }
        }
    })
    // console.log("res: ", res)
    return res
}

export const isNotOverLap = (startAt1: Dayjs, endAt1: Dayjs, startAt2: Dayjs, endAt2: Dayjs) => {
    // Les plages doivent être cohérentes:
    // console.log("comparing plages:", startAt1, "-", endAt1, "WITH", startAt2, "-", endAt2)
    var res = true
    if (startAt1.isAfter(endAt1) || startAt2.isAfter(endAt2)) {
        // console.log(" res setted to false !!")
        res = false
    }
    // Aucune date des 2 plages ne doit être la même:
    if (
        startAt1.isSame(startAt2) ||
        endAt1.isSame(endAt2)
    ) {
        // console.log(" res setted to false !!")
        res = false
    }
    // startAt1 ne doit pas être dans la plage 2
    if (startAt1.isAfter(startAt2) && startAt1.isBefore(endAt2)) {
        // console.log(" res setted to false !!")
        res = false
    }
    // endAt1 ne doit pas être dans la plage 2
    if (endAt1.isAfter(startAt2) && endAt1.isBefore(endAt2)) {
        // console.log(" res setted to false !!")
        res = false
    }
    // startAt2 ne doit pas être dans la plage 1
    if (startAt2.isAfter(startAt1) && startAt2.isBefore(endAt1)) {
        // console.log(" res setted to false !!")
        res = false
    }
    // endAt2 ne doit pas être dans la plage 1
    if (endAt2.isAfter(startAt1) && endAt2.isBefore(endAt1)) {
        // console.log(" res setted to false !!")
        res = false
    }

    return res
}

export const getBeginningOfTheWeek = (givenDate: Dayjs) => {
    return givenDate.startOf('week').day(1).hour(12).minute(0).second(0)
}

export const getBeginningOfTheMonth = (givenDate: Dayjs) => {
    // return givenDate.startOf('month').day(1).hour(12).minute(0).second(0)
    return givenDate.startOf('month').hour(12).minute(0).second(0)
}

export const truncateString = (p_string: string, size: number) => {
    if (p_string === undefined || p_string === null) {
        return ""
    }
    return p_string.length > size ? `${p_string.slice(0, size)}...` : p_string
}

export const isWeekend = (date: Dayjs) => {
    const dayOfWeek = dayjs(date).day(); // 0: Sunday, 1: Monday, ..., 6: Saturday
    return dayOfWeek === 0 || dayOfWeek === 6;
};