import { FC, useState } from 'react';
import { IconButton, Typography, useTheme } from "@mui/material";
import { Flexbox } from '../utils/Flexbox';
import { ICustomer } from '../../interfaces';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { CustomerSelectionDialog } from './CustomerSelectionDialog';

interface IProps {
    customer?: ICustomer
    handleConfirm: (customer: ICustomer) => void;
}

export const CustomerSelection: FC<IProps> = (props) => {
    const theme = useTheme()

    const [showCustomerSelectionDialog, setShowCustomerSelectionDialog] = useState(false)

    const closeCustomerSelectionDialog = () => {
        setShowCustomerSelectionDialog(false)
    }

    const confirmCustomerSelectionDialog = (customer: ICustomer) => {
        props.handleConfirm(customer)
        setShowCustomerSelectionDialog(false)
    }


    return (
        <>
            <Flexbox sx={{
                width: "100%",
                justifyContent: "space-between",
                // backgroundColor: "red",
            }}>
                <Typography
                    sx={{ color: theme.palette.text.secondary }}
                >
                    Locataire:
                </Typography>
                {
                    props.customer
                        ?
                        <Typography>
                            {props.customer.fullname}
                        </Typography>
                        :
                        <Typography sx={{ color: theme.palette.text.disabled }}>
                            à sélectionner..
                        </Typography>
                }
                <IconButton color="secondary" onClick={() => setShowCustomerSelectionDialog(true)}><MoreHorizIcon /></IconButton>
            </Flexbox>
            <CustomerSelectionDialog
                open={showCustomerSelectionDialog}
                handleClose={closeCustomerSelectionDialog}
                handleConfirm={confirmCustomerSelectionDialog}
            />
        </>
    )
}


