import { FC, useState } from 'react'
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import * as MuiCarousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import { red } from '@mui/material/colors';
import { WaveIcon } from './svg_icons/WaveIcon';
import { EnumTherapist, EnumTherapistMode } from '../enums';
import { NAVIGATE_THERAPIST_AMANDINE_BLACHE, NAVIGATE_THERAPIST_AMANDINE_COLLARD, NAVIGATE_THERAPIST_AUDREY_MEESE, NAVIGATE_THERAPIST_BENEDICTE_LE_PARC, NAVIGATE_THERAPIST_ERWAN_LEBLOND, NAVIGATE_THERAPIST_FRANCOIS_HOARAU, NAVIGATE_THERAPIST_LISE_ROUSSELIERE, NAVIGATE_THERAPIST_LOUIS_TIERSONNIER, NAVIGATE_THERAPIST_MALIKA_FONTAINE, NAVIGATE_THERAPIST_MARINE_DUPEYRON, NAVIGATE_THERAPIST_VALERIE_PAYET, NAVIGATE_THERAPIST_VANESSA_LEVY, NAVIGATE_THERAPIST_VERONIQUE_FONTAINE } from '../utils/Navigation';
import { useNavigate } from 'react-router';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Flexbox } from './utils/Flexbox';

interface IProps {
    therapist: EnumTherapist,
    mode: EnumTherapistMode,
    previousIcon?: boolean,
    nextIcon?: boolean,
}

export const TherapistCard: FC<IProps> = (props) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const therapists = [
        {
            id: EnumTherapist.amandineBlache,
            fullname: "Amandine BLACHE",
            avatarSrc: `${process.env.PUBLIC_URL}/images/therapists/Amandine_BLACHE_avatar.jpg`,
            pageUrl: `${NAVIGATE_THERAPIST_AMANDINE_BLACHE}`,
            profession: "Art-thérapeute",
            space: "Espace Lagon",
        },
        {
            id: EnumTherapist.amandineCollard,
            fullname: "Amandine COLLARD",
            avatarSrc: `${process.env.PUBLIC_URL}/images/therapists/Amandine_COLLARD_avatar.jpg`,
            pageUrl: `${NAVIGATE_THERAPIST_AMANDINE_COLLARD}`,
            profession: "Psychologue Neuropsychologue",
            space: "Espace Lagon",
        },
        {
            id: EnumTherapist.audreyMeese,
            fullname: "Audrey MEESE",
            avatarSrc: `${process.env.PUBLIC_URL}/images/therapists/Audrey_MEESE_avatar.jpg`,
            pageUrl: `${NAVIGATE_THERAPIST_AUDREY_MEESE}`,
            profession: "Praticienne en Hypnose Ericksonienne, PNL, Cohérence cardiaque et Psychologie positive, Tabacologue",
            space: "Espace Sel marin",
        },
        {
            id: EnumTherapist.benedicteLeParc,
            fullname: "Bénédicte LE PARC",
            avatarSrc: `${process.env.PUBLIC_URL}/images/therapists/Benedicte_LE_PARC_avatar.jpg`,
            pageUrl: `${NAVIGATE_THERAPIST_BENEDICTE_LE_PARC}`,
            profession: "Orthophoniste",
            space: "Espace Embrun",
        },
        {
            id: EnumTherapist.liseRousseliere,
            fullname: "Lise ROUSSELIERE",
            avatarSrc: `${process.env.PUBLIC_URL}/images/therapists/Lise_ROUSSELIERE_avatar.jpg`,
            pageUrl: `${NAVIGATE_THERAPIST_LISE_ROUSSELIERE}`,
            profession: <Typography>
                Fasciathérapeute MDB, Sexothérapeute,
                <br />
                Accompagnement périnatal
                <br />
                Gymnastique sensorielle
            </Typography>,
            space: "Espace Alizé",
        },
        {
            id: EnumTherapist.malikaFontaine,
            fullname: "Malîka FONTAINE",
            avatarSrc: `${process.env.PUBLIC_URL}/images/therapists/Malika_FONTAINE_avatar.jpg`,
            pageUrl: `${NAVIGATE_THERAPIST_MALIKA_FONTAINE}`,
            profession: "Fasciathérapeute MDB, Micronutrition, Accompagnement périnatal, Gymnastique sensorielle",
            space: "Espace Alizé",
        },
        {
            id: EnumTherapist.marineDupeyron,
            fullname: "Marine DUPEYRON",
            avatarSrc: `${process.env.PUBLIC_URL}/images/therapists/Marine_DUPEYRON_avatar.jpg`,
            pageUrl: `${NAVIGATE_THERAPIST_MARINE_DUPEYRON}`,
            // profession: "Psychomotricienne, Accompagnement parental",
            profession: <Typography>
                Psychomotricienne,
                <br />
                Accompagnement parental
            </Typography>,
            space: "Espace Embrun",
        },
        {
            id: EnumTherapist.valeriePayet,
            fullname: "Valérie PAYET",
            avatarSrc: `${process.env.PUBLIC_URL}/images/therapists/Valerie_PAYET_avatar.jpg`,
            pageUrl: `${NAVIGATE_THERAPIST_VALERIE_PAYET}`,
            profession: "Infirmière",
            space: "Espace Coquillage",
        },
        {
            id: EnumTherapist.vanessaLevy,
            fullname: "Vanessa LEVY",
            avatarSrc: `${process.env.PUBLIC_URL}/images/therapists/Vanessa_LEVY_avatar.jpg`,
            pageUrl: `${NAVIGATE_THERAPIST_VANESSA_LEVY}`,
            // profession: "Ostéopathe D.O., Microkiné PME, Ostéopathie aquatique",
            profession: <Typography>
                Ostéopathe D.O., Microkiné PME,
                <br />
                Ostéopathie aquatique
                <br />
                Spécialisée périnatalité et pédiatrie
            </Typography>,
            space: "Espace Corail",
        },
        {
            id: EnumTherapist.veroniqueFontaine,
            fullname: "Véronique FONTAINE",
            avatarSrc: `${process.env.PUBLIC_URL}/images/therapists/Veronique_FONTAINE_avatar.jpg`,
            pageUrl: `${NAVIGATE_THERAPIST_VERONIQUE_FONTAINE}`,
            // profession: "Naturopathe, Sophrologue, Sonothérapie et Massage bien être, Périnatalité et santé de la femme",
            profession: <Typography>
                Naturopathie, Sophrologie,
                <br />
                Sonothérapie et Massage bien être,
                <br />
                Périnatalité et santé de la femme
            </Typography>,
            space: "Espace Lagon",
        },
        {
            id: EnumTherapist.francoisHoarau,
            fullname: "François HOARAU",
            avatarSrc: `${process.env.PUBLIC_URL}/images/therapists/Francois_HOARAU_avatar.jpg`,
            pageUrl: `${NAVIGATE_THERAPIST_FRANCOIS_HOARAU}`,
            profession: <Typography>
                Thérapie manuelle
                <br />
                Micronutrition
            </Typography>,
            space: "Espace Ecume",
        },
        {
            id: EnumTherapist.erwanLeblond,
            fullname: "Erwan LEBLOND",
            avatarSrc: `${process.env.PUBLIC_URL}/images/therapists/Erwan_LEBLOND_avatar.jpg`,
            pageUrl: `${NAVIGATE_THERAPIST_ERWAN_LEBLOND}`,
            profession: <Typography>
                Ergothérapeute
            </Typography>,
            space: "Espace Ecume",
        },
        {
            id: EnumTherapist.louisTiersonnier,
            fullname: "Louis TIERSONNIER",
            avatarSrc: `${process.env.PUBLIC_URL}/images/therapists/Louis_TIERSONNIER_avatar.jpg`,
            pageUrl: `${NAVIGATE_THERAPIST_LOUIS_TIERSONNIER}`,
            profession: <Typography>
                Ergothérapeute
            </Typography>,
            space: "Espace Ecume",
        },
    ]

    const getTherapistInfo = (infoType: string) => {
        const therapist = therapists.find(t => t.id === props.therapist);
        if (therapist) {
            switch (infoType) {
                case 'fullname':
                    return therapist.fullname;
                case 'avatarSrc':
                    return therapist.avatarSrc;
                case 'pageUrl':
                    return therapist.pageUrl;
                case 'profession':
                    return therapist.profession;
                case 'space':
                    return therapist.space;
                default:
                    return `Erreur de détermination de l'attibut ${infoType}`;
            }
        }
        return `Erreur de détermination du therapist`;
    }

    const fullname = getTherapistInfo("fullname")
    const avatarSrc = getTherapistInfo("avatarSrc")
    const pageUrl = getTherapistInfo("pageUrl")

    return (
        <>
            {
                props.mode === EnumTherapistMode.card &&
                <Box className='TherapistCard' sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: "300px",
                    mx: "auto",
                    my: 2,
                    backgroundColor: "white",
                    borderRadius: "12px",
                    boxShadow: "5px 5px 5px 0px rgba(0, 0, 0, 0.25)",
                    pt: 4,
                    pb: 2,
                    px: 2,
                }}>
                    <Avatar
                        alt={typeof fullname === "string" ? fullname : ""}
                        src={typeof avatarSrc === "string" ? avatarSrc : ""}
                        sx={{
                            width: "165px",
                            height: "165px",
                            borderWidth: "2px",
                            borderStyle: "solid",
                            borderColor: theme.palette.primary.main,
                            ":hover": {
                                cursor: "pointer",
                                borderColor: theme.palette.secondary.main,
                            },
                        }}
                        onClick={() => navigate(typeof pageUrl === "string" ? pageUrl : "")}
                    />
                    <Typography sx={{ fontFamily: theme.typography.alternative, fontSize: 36, color: theme.palette.secondary.main, mt: 1 }}>
                        {getTherapistInfo("fullname")}
                    </Typography>
                    <Typography sx={{
                        textAlign: "center",
                        minHeight: "74px",
                    }}>
                        {getTherapistInfo("profession")}
                    </Typography>
                    <WaveIcon color="primary" sx={{ width: "50px", mt: 1 }} />
                    <Typography sx={{ mt: -1, color: theme.palette.primary.main }}>
                        {getTherapistInfo("space")}
                    </Typography>
                    <Flexbox sx={{
                        // bgcolor: "red",
                    }}>
                        <Button variant='contained'
                            size="small"
                            color="secondary"

                            onClick={() => navigate(typeof pageUrl === "string" ? pageUrl : "")}
                            sx={{
                                my: 2,
                                fontFamily: theme.typography.secondary,
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                            }}
                        >
                            Consulter
                        </Button>
                    </Flexbox>
                </Box>

            }

            {
                props.mode === EnumTherapistMode.badge &&
                <Typography component="span"
                    sx={{
                        fontFamily: theme.typography.alternative,
                        fontSize: 16,
                        color: theme.palette.secondary.main,
                        fontWeight: 700,
                        " img ": {
                            borderColor: theme.palette.primary.main,
                        },
                        ":hover": {
                            cursor: "pointer",
                            color: theme.palette.primary.main,
                            " img ": {
                                borderColor: theme.palette.secondary.main,
                            }
                        },
                    }}
                    onClick={() => navigate(typeof pageUrl === "string" ? pageUrl : "")}

                >
                    {
                        props.previousIcon &&
                        <ChevronLeftIcon sx={{
                            mb: -.8
                        }} />
                    }
                    <Box component="img"
                        alt={typeof fullname === "string" ? fullname : ""}
                        src={typeof avatarSrc === "string" ? avatarSrc : ""}
                        sx={{
                            mb: -1,
                            mr: .5,
                            width: "24px",
                            height: "24px",
                            borderWidth: "2px",
                            borderStyle: "solid",
                            borderRadius: "50%",
                        }}
                    />
                    {getTherapistInfo("fullname")}
                    {
                        props.nextIcon &&
                        <ChevronRightIcon sx={{
                            mb: -.8
                        }} />
                    }
                </Typography>

            }
        </>
    )
}


