import { createSlice } from "@reduxjs/toolkit";
import { IMessage } from "../../../interfaces";


interface IMessagesSliceState {
    messages: IMessage[];
}

const initialState: IMessagesSliceState = {
    messages: [],
}

const nextMessagesId = (state: IMessagesSliceState) => {
    let max = 0
    for (let i = 0; i < state.messages.length; i++) {
        if (state.messages[i].id > max) {
            max = state.messages[i].id
        }
    }

    return max + 1
}

export const messagesSlice = createSlice({
    name: "messagesSlice",
    initialState,
    reducers: {
        add: (state, action) => {
            const new_message: IMessage = {
                ...action.payload,
                id: nextMessagesId(state)
            }
            state.messages.push(new_message)
        },
        clear: (state) => {
            state.messages = initialState.messages
        },
        remove: (state, action) => {
            state.messages = state.messages.filter(message => (message.id !== action.payload))
        },
    }
})

export const { add, clear, remove } = messagesSlice.actions

export default messagesSlice.reducer