import { Box, useTheme } from '@mui/material'
import { FC } from 'react'
import { AdminLayout } from './AdminLayout'
import { useNavigate } from 'react-router'
import { TitleSection } from '../../components/TitleSection'
import { EnumTitleSectionMode } from '../../enums'
import { ListCustomers } from '../../components/customers/ListCustomers'

interface IProps {
}

export const AdminCustomersPage: FC<IProps> = (props) => {

  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <div className="AdminCustomersPage">
      <AdminLayout>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", mx: 4 }}>
          <TitleSection title="Locataires" mode={EnumTitleSectionMode.admin} />
          <ListCustomers />
        </Box>
      </AdminLayout>
    </div >
  )
}
