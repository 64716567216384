import { FC, useState } from 'react'
import { Box, SxProps, Typography, useTheme } from "@mui/material";
import * as MuiCarousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import { red } from '@mui/material/colors';
import { ICarouselItem } from '../interfaces';

interface IProps {
    items: ICarouselItem[],
    carouselSx?: SxProps,
    carouselItemSx?: SxProps,
}

export const Carousel: FC<IProps> = (props) => {

    // monkey patch for issue detected here : https://github.com/Learus/react-material-ui-carousel/issues/189
    // const [firstImgLoaded, setFirstImgLoaded] = useState(false);


    const CarouselItem = (props: any) => {
        return (
            <Box component="img" src={props.item.src} sx={{ width: "100%", ...props.carouselItemSx }} />
        )
    }


    return (
        // <Box className='Carousel' sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", ...props.carouselSx }}>
        //     {
        //         !firstImgLoaded && <img src={props.items[0].src} onLoad={() => setFirstImgLoaded(true)} style={{ display: "block" }} />
        //     }
        //     {
        //         firstImgLoaded && (
        //             // <MuiCarousel.default swipe={false} stopAutoPlayOnHover={false} indicators={false} sx={{ width: "100%", height: { xs: "220px", sm: "338px", md: "506px", lg: "675px", xl: "864px" } }}>
        //             <MuiCarousel.default index={1} swipe={false} stopAutoPlayOnHover={false} indicators={false} sx={{ width: "100%" }}>
        //                 {
        //                     props.items.map((item, i) => <CarouselItem key={i} item={item} carouselItemSx={props.carouselItemSx} />)
        //                 }
        //             </MuiCarousel.default>
        //         )
        //     }
        // </Box>
        <Box className='Carousel' sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", ...props.carouselSx }}>
            <MuiCarousel.default index={1} swipe={false} stopAutoPlayOnHover={false} indicators={false} sx={{ width: "100%" }}>
                {
                    props.items.map((item, i) => <CarouselItem key={i} item={item} carouselItemSx={props.carouselItemSx} />)
                }
            </MuiCarousel.default>
        </Box>
    )
}


