import { FC, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { EnumKazoceaLogoMode, versionInfos } from '../enums';
import { KazoceaLogo } from './KazoceaLogo';
import { useQuery } from "@apollo/client";
import { GQL_GET_API_INFOS } from "../graphql/Queries";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import { IApiInfos } from '../interfaces';


interface IProps { }

export const Footer: FC<IProps> = (props) => {

    const [apiInfos, setApiInfos] = useState<IApiInfos>({
        apiVersion: "?",
        codeName: "?",
        coreVersion: "?",
        date: "?",
        environment: "?",
    })

    const theme = useTheme()

    // Pour ne pas refresh :
    const { error, loading, data } = useQuery(GQL_GET_API_INFOS)

    useEffect(() => {
        if (data) {
            setApiInfos({
                apiVersion: data.getApiInfos.apiVersion,
                codeName: data.getApiInfos.codeName,
                coreVersion: data.getApiInfos.coreVersion,
                date: data.getApiInfos.date,
                environment: data.getApiInfos.environment,
            })
        }
    }, [data])

    return (
        <>
            {/* <EnvironmentFlag apiInfos={apiInfos} /> */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: theme.palette.primary.main,
                    mt: 8,
                }}>
                <KazoceaLogo mode={EnumKazoceaLogoMode.footer} sx={{ mt: -6 }} />
                <Box sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-around",
                    alignItems: "flex-start",
                    width: { xs: "inherit", sm: "100%" },
                    mt: 1,
                    mb: 2,
                    // backgroundColor: "red",
                }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        mt: 1,
                        // backgroundColor: "green",
                    }}>
                        <LocationOnIcon sx={{ color: "white", mx: 1, mt: .5, fontSize: 30 }} />
                        <Typography sx={{ fontFamily: theme.typography.secondary, fontSize: 18, color: "white" }}>
                            93 Boulevard Hubert Delisles
                            <br />
                            97410 Saint-Pierre
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 1,
                        // backgroundColor: "blue",
                    }}>
                        <MailRoundedIcon sx={{ color: "white", mx: 1, fontSize: 30 }} />
                        <Typography
                            component="a"
                            href="mailto:contact@kazocea.com"
                            sx={{
                                fontFamily: theme.typography.secondary,
                                fontSize: 18,
                                color: "white",
                                textDecoration: "none",
                                ":hover": {
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                },
                            }}>
                            contact@kazocea.com
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row", justifyContent: "center", alignItems: "center" } }}>
                    <Typography
                        component="span"
                        sx={{ fontFamily: theme.typography.secondary, fontSize: 16, fontWeight: "100", color: "white" }}>
                        Copyright &copy; 2022-{new Date().getFullYear()} kazocea.com by&nbsp;
                    </Typography>
                    <Typography
                        component="span"
                        title={`V ${versionInfos.webVersion} - core: ${apiInfos.coreVersion}(${apiInfos.codeName} - ${apiInfos.environment} - ${apiInfos.date}) - api: ${apiInfos.apiVersion}(expected: ${versionInfos.apiExpectedVersion})`}
                        sx={{ fontFamily: theme.typography.secondary, fontSize: 16, fontWeight: "100", color: "white" }}>
                        Médéric DE LAUNAY - Tous droits réservés
                    </Typography>
                </Box>
            </Box>
        </>
    )
}


