import { FC } from 'react';
import { Typography, useTheme } from "@mui/material";
import { ICustomer } from '../../interfaces';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { SxProps } from "@mui/material";
import { Flexbox } from '../utils/Flexbox';

import DoneIcon from '@mui/icons-material/Done';

interface IProps {
    customer: ICustomer,
    sx?: SxProps,
    handleConfirm: (customer: ICustomer) => void;
}

export const CustomerSelectionItem: FC<IProps> = (props) => {

    const theme = useTheme()

    return (
        <>
            <Grid container sx={{ width: "100%", ...props.sx }} >
                <Grid xs={3} >
                    <Typography>{props.customer.lastname}</Typography>
                </Grid>
                <Grid xs={3}>
                    <Typography>{props.customer.firstname}</Typography>
                </Grid>
                <Grid xs={5}>
                    <Typography>{props.customer.email}</Typography>
                </Grid>
                <Grid xs={1} >
                    <Flexbox sx={{ justifyContent: "space-around" }}>
                        <Typography color="text.secondary">
                            <DoneIcon onClick={() => props.handleConfirm(props.customer)} sx={{
                                ":hover": {
                                    cursor: "pointer",
                                    color: theme.palette.error.main
                                }
                            }} />
                        </Typography>
                    </Flexbox>
                </Grid>

            </Grid>
        </>
    )
}


