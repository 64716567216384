// import "../css/pages/Home.css"
import { Box, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { Layout } from "./Layout"

import { WhereDescriptionSection } from '../sections/WhereDescriptionSection'
import { GeolocationSection } from '../sections/GeolocationSection'
import { TitleSection } from '../components/TitleSection'
import { Carousel } from '../components/Carousel'
import { SpaceCard } from '../components/SpaceCard'
import { EnumSpaceReference, EnumTitleSectionMode } from '../enums'

interface IProps {
}

export const SpaceWorkshopPage: FC<IProps> = (props) => {

  const theme = useTheme()

  const carouselItems = [
    {
      src: `${process.env.PUBLIC_URL}/images/spaces/workshop_01_hd.jpg`,
    },
    {
      src: `${process.env.PUBLIC_URL}/images/spaces/workshop_02_hd.jpg`,
    },
    {
      src: `${process.env.PUBLIC_URL}/images/spaces/workshop_03_hd.jpg`,
    },

  ]

  return (
    <div className="SpaceWorkshopPage">
      <Layout>
        <Box id="SpaceWorkshopSection" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", mt: { xs: 7, sm: 7, md: 12 } }}>
          <TitleSection title="L'espace Atelier Horizon" mode={EnumTitleSectionMode.standard} />
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", textAlign: "justify", mx: 4 }}>
            <Typography>
              Cet espace de 22 m2 face à la mer et équipé d’une cuisine est disponible à la location en journée ou à la demi-journée, en semaine et le week end.
              <br />
              Il peut recevoir 10 personnes assises maximum.
              <br />
              Seront à votre disposition des tables et sièges, un vidéo-projecteur, un paper board.
              <br />
              La cuisine est équipée d’un réfrigérateur, d’un four, d’un four à micro-ondes, de plaques chauffantes, d’une cafetière filtre et de quelques ustensiles de cuisine.
              <br />
              <br />
              Pour plus d’informations, contactez nous : <Typography component="a" href="mailto:contact@kazocea.com"
                sx={{
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                contact@kazocea.com
              </Typography>
            </Typography>
          </Box>
          <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 4,
            mx: 4,
            width: "100%",
            // maxWidth: "1200px",
            maxWidth: { xs: "360px", sm: "580px", md: "800px", lg: "1024px", xl: "1200px" },
          }}>
            <Carousel
              items={carouselItems}
              carouselItemSx={{
                borderStyle: "solid",
                borderWidth: 0,
                borderColor: theme.palette.secondary.main,
                borderRadius: "24px",
              }}
            />
          </Box>
          <Box sx={{ mt: 8 }}>
            <TitleSection title="Découvrez également" mode={EnumTitleSectionMode.standard} />
            <SpaceCard space={EnumSpaceReference.terrace} />
          </Box>
        </Box>
      </Layout>
    </div >
  )
}
