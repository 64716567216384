import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FC } from 'react';
import { ICustomer } from '../../interfaces';

import 'dayjs/locale/fr';
import { ListCustomersSelection } from './ListCustomersSelection';

interface IProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: (customer: ICustomer) => void;
}

export const CustomerSelectionDialog: FC<IProps> = (props) => {
    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth
            >
                <DialogTitle color="primary">
                    Sélection du locataire
                </DialogTitle>
                <DialogContent>
                    <ListCustomersSelection handleConfirm={props.handleConfirm} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} variant="outlined" color="primary">Annuler</Button>
                </DialogActions>
            </Dialog >
        </>
    )
}
