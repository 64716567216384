import { gql } from "@apollo/client"
import { GQL_FRAGMENT_CUSTOMER, GQL_FRAGMENT_RESERVATION, GQL_FRAGMENT_SPACE, GQL_FRAGMENT_USER } from "./Fragments"

// Settings
export const GQL_GET_API_INFOS = gql`
query {
    getApiInfos {
      apiVersion
      codeName
      coreVersion
      date
      environment
    }
  }
`

// Users
export const GQL_WHO_AM_I = gql`
${GQL_FRAGMENT_USER}
  query {
    whoami {
      ...User
    }
  }
`

// Customers
export const GQL_CUSTOMERS = gql`
${GQL_FRAGMENT_CUSTOMER}
query customers($searchQuery: String) {
  customers(searchQuery: $searchQuery) {
    ...Customer
    }
  }
`

// Spaces
// export const GQL_SPACE_BY_REFERENCE = gql`
// ${GQL_FRAGMENT_SPACE}
// ${GQL_FRAGMENT_RESERVATION}
// ${GQL_FRAGMENT_CUSTOMER}
// query spaceByReference($reference: String!) {
//   spaceByReference(reference: $reference) {
//     ...Space
//     reservations {
//       ...Reservation
//       customer {
//         ...Customer
//       }
//     }
//     }
//   }
// `

export const GQL_SPACES = gql`
${GQL_FRAGMENT_SPACE}
${GQL_FRAGMENT_RESERVATION}
${GQL_FRAGMENT_CUSTOMER}
query {
  spaces {
    ...Space
    reservations {
      ...Reservation
      customer {
        ...Customer
      }
    }
    }
  }
`