import { FC, useState } from 'react'
import { Box, Typography, useTheme } from "@mui/material";
import * as MuiCarousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import { red } from '@mui/material/colors';

interface IProps { }

export const KazOceaSpan: FC<IProps> = (props) => {
    const theme = useTheme()

    return (
        <>
            <Typography
                component="span"
                sx={{ textTransform: "uppercase", fontFamily: theme.typography.secondary, color: theme.palette.primary.main, fontWeight: "500" }}
            >
                Kaz
            </Typography>
            &nbsp;
            <Typography
                component="span"
                sx={{ textTransform: "uppercase", fontFamily: theme.typography.secondary, color: theme.palette.secondary.main, fontWeight: "700" }}
            >
                océa
            </Typography>
        </>
    )
}


