
export const NAVIGATE_ROOT = "/"
export const NAVIGATE_LANDING_PAGE = "/accueil"
export const NAVIGATE_WHERE_PAGE = "/nous-situer"
export const NAVIGATE_THERAPIST_VANESSA_LEVY = "/vanessa_levy"
export const NAVIGATE_THERAPIST_VALERIE_PAYET = "/valerie_payet"
export const NAVIGATE_THERAPIST_AUDREY_MEESE = "/audrey_meese"
export const NAVIGATE_THERAPIST_MALIKA_FONTAINE = "/malika_fontaine"
export const NAVIGATE_THERAPIST_LISE_ROUSSELIERE = "/lise_rousseliere"
export const NAVIGATE_THERAPIST_BENEDICTE_LE_PARC = "/benedicte_le_parc"
export const NAVIGATE_THERAPIST_MARINE_DUPEYRON = "/marine_dupeyron"
export const NAVIGATE_THERAPIST_VERONIQUE_FONTAINE = "/veronique_fontaine"
export const NAVIGATE_THERAPIST_AMANDINE_COLLARD = "/amandine_collard"
export const NAVIGATE_THERAPIST_AMANDINE_BLACHE = "/amandine_blache"
export const NAVIGATE_THERAPIST_FRANCOIS_HOARAU = "/francois_hoarau"
export const NAVIGATE_THERAPIST_ERWAN_LEBLOND = "/erwan_leblond"
export const NAVIGATE_THERAPIST_LOUIS_TIERSONNIER = "/louis_tiersonnier"
export const NAVIGATE_SPACE_WORKSHOP_PAGE = "/espace-atelier"
export const NAVIGATE_SPACE_TERRACE_PAGE = "/espace-terrasse"

export const NAVIGATE_SPACE_WORKSHOP_RULES_PAGE = "/gestion-des-ateliers"

export const NAVIGATE_ADMIN_LOGIN = "/admin/login"
export const NAVIGATE_ADMIN_PAGE = "/admin"
export const NAVIGATE_ADMIN_SPACES_PAGE = "/admin/planning"
export const NAVIGATE_ADMIN_CUSTOMERS_PAGE = "/admin/customers"

export const NAVIGATE_BUILDING = "/en-construction"


export const isActivePath = (known_path: string, current_path: string, strict: boolean = false) => {
    var res = false
    if (strict) {
        if (current_path === known_path) {
            res = true
        }
    } else {
        if (current_path.startsWith(known_path)) {
            res = true
        }
    }
    return res
}


