import { FC, useState } from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import { TitleSection } from '../components/TitleSection';
import { KazOceaSpan } from '../components/KazOceaSpan';


import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { KazOceaList } from '../components/KazOceaList';
import { TherapistCard } from '../components/TherapistCard';
import { EnumKazOceaListMode, EnumTherapist, EnumTherapistMode, EnumTitleSectionMode } from '../enums';
import { Flexbox } from '../components/utils/Flexbox';

interface IProps { }

export const PatientRulesSection: FC<IProps> = (props) => {

    const theme = useTheme()

    const listElements = [
        <>
            Lorsque vous prenez rendez-vous avec l'un de nos thérapeutes, vous recevrez un code d'accès qui vous permettra d'entrer le jour de votre rendez-vous.
        </>,
        <>
            Le digicode se trouve à proximité du petit portail.
            Si vous avez besoin d'un accès pour les personnes à mobilité réduite, les poussettes ou les vélos, vous pouvez utiliser le code spécifique sur le boîtier et vous diriger vers le grand portail situé sur votre droite qui se déverrouillera.
            Il vous suffira de pousser le battant côté droit du grand portail.
        </>,
        <>
            Nous n'avons pas de secrétariat sur place. Une fois à l'intérieur du centre, nous vous invitons à vous installer en silence devant la salle de votre thérapeute et à attendre qu'il vienne vous chercher.
        </>,
        <>
            À votre départ, nous vous remercions de refermer le portail sans le claquer afin d'assurer la sécurité de tous.
        </>,
    ]





    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", my: 4 }}>
            <TitleSection title="Le jour de votre rendez-vous" mode={EnumTitleSectionMode.standard} />
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                textAlign: "justify",
                mx: 4,
                maxWidth: "1160px",
            }}>
                <Typography>
                    Nous souhaitons vous offrir une expérience agréable et bienveillante lors de votre visite dans notre centre.
                </Typography>
                <Typography sx={{ mt: 4 }}>
                    Voici quelques informations pour vous guider tout en respectant le confort des patients et des thérapeutes.
                </Typography>
                <KazOceaList elements={listElements} mode={EnumKazOceaListMode.standard} />
                <Typography sx={{ mt: 4 }}>
                    Nous vous remercions pour votre compréhension et nous nous réjouissons de vous accueillir bientôt !
                </Typography>
            </Box>
            <Flexbox sx={{
                mt: 4,
                borderWidth: "4px",
                borderStyle: "solid",
                borderColor: theme.palette.secondary.main,
                borderRadius: "6px",
            }}>
                {/* Mobile */}
                <Flexbox sx={{
                    display: { xs: 'flex', md: 'none' },
                    borderWidth: "2px",
                    borderStyle: "solid",
                    borderColor: theme.palette.secondary.main,
                }}>
                    <video
                        width="310"
                        controls={true}
                        autoPlay={false}
                        loop={false}
                        muted={false}
                        poster={`${process.env.PUBLIC_URL}/images/covers/instructions_patients_cover.webp`}
                    >
                        <source src="https://site-reunion-space.fra1.cdn.digitaloceanspaces.com/kazocea/videos/instructions_patients_270.mp4" type="video/mp4" />
                        Problème de lecteur video.
                    </video>

                </Flexbox>
                {/* Desktop */}
                <Flexbox sx={{
                    display: { xs: 'none', md: 'flex' },
                    borderWidth: "2px",
                    borderStyle: "solid",
                    borderColor: theme.palette.secondary.main,
                }}>
                    <video
                        width="310"
                        controls={true}
                        autoPlay={false}
                        loop={false}
                        muted={false}
                        poster={`${process.env.PUBLIC_URL}/images/covers/instructions_patients_cover.webp`}
                    >
                        <source src="https://site-reunion-space.fra1.cdn.digitaloceanspaces.com/kazocea/videos/instructions_patients_720.mp4" type="video/mp4" />
                        Problème de lecteur video.
                    </video>

                </Flexbox>
            </Flexbox>
        </Box>
    )
}


