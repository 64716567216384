import { FC } from 'react'
import { IPlanningShowFilter, ISpace } from '../../interfaces'
import { Flexbox } from '../utils/Flexbox'
import { Box, Typography, useTheme } from '@mui/material'
import { getDaySlots, getReservationsOfDate } from '../../utils/helpers'
import 'dayjs/locale/fr'
import { SpacePlanningReservationSlot } from './SpacePlanningReservationSlot'
import { SpacePlanningEmptySlot } from './SpacePlanningEmptySlot'
import { EnumDayjsFormat, EnumPlanningBy, EnumSpaceReference } from '../../enums'
import dayjs, { Dayjs } from "dayjs"
import 'dayjs/locale/fr'
import { useSelector } from 'react-redux'
import { IRootState } from '../../redux/store'
import { SpacePlanningColumn } from './SpacePlanningColumn'


interface IProps {
    planningBy: EnumPlanningBy,
    date: Dayjs,
    startHour: number,
    endHour: number,
    hourHeight: number,
    minuteHeight: number,
    columnHeaderHeight: number,
}

export const SpacesPlanningColumn: FC<IProps> = (props) => {
    // const reservationsOfDate = getReservationsOfDate(props.space.reservations, props.date)
    const spaces: ISpace[] = useSelector((state: IRootState) => state.spaceReducer.spaces)
    const planningShowFilter: IPlanningShowFilter = useSelector((state: IRootState) => state.globalReducer.planningShowFilter)

    // const reservationsOfDateBySpace = spaces.map(space => getReservationsOfDate(space.reservations, props.date))

    // console.log("TODO check : reservationsOfDateBySpace : ", reservationsOfDateBySpace)

    const theme = useTheme()
    const isSameDay = props.date.isSame(dayjs().locale('fr'), 'day')
    return (
        <>
            <Flexbox direction='column'
                sx={{
                    width: "100%",
                }}>
                <Typography sx={{
                    height: props.columnHeaderHeight,
                    width: "100%",
                    textAlign: "center",
                    color: isSameDay ? theme.palette.primary.contrastText : theme.palette.primary.main,
                    backgroundColor: isSameDay ? theme.palette.primary.main : "inherit",
                    borderTopLeftRadius: 12,
                    borderTopRightRadius: 12,
                    borderStyle: "solid",
                    borderWidth: .5,
                }}>
                    {props.planningBy === EnumPlanningBy.week && props.date.format(EnumDayjsFormat.day_name_day_number)}
                    {props.planningBy === EnumPlanningBy.month && `${props.date.format(EnumDayjsFormat.day_small)[0].toUpperCase()} ${props.date.format(EnumDayjsFormat.day_number)}`}
                </Typography>
                <Flexbox sx={{ width: "100%" }}>
                    {
                        spaces.map(space => (
                            <>
                                {

                                    (
                                        (space.reference === EnumSpaceReference.workshop && planningShowFilter.spaceWorkshop) ||
                                        (space.reference === EnumSpaceReference.terrace && planningShowFilter.spaceTerrace)
                                    ) &&
                                    <SpacePlanningColumn
                                        space={space}
                                        planningBy={props.planningBy}
                                        date={props.date}
                                        startHour={props.startHour}
                                        endHour={props.endHour}
                                        hourHeight={props.hourHeight}
                                        minuteHeight={props.minuteHeight}
                                        columnHeaderHeight={props.columnHeaderHeight}
                                    />
                                }
                            </>
                        ))
                    }
                </Flexbox>
            </Flexbox>
        </>
    )
}
