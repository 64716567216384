import { FC } from 'react';
import { Box, Typography } from "@mui/material";
import { TitleSection } from '../components/TitleSection';
import { EnumSpaceReference, EnumTitleSectionMode } from '../enums';
import { SpaceCard } from '../components/SpaceCard';
import { KazOceaSpan } from '../components/KazOceaSpan';
import { Flexbox } from '../components/utils/Flexbox';
import { HighlightSpan } from '../components/HighlightSpan';

interface IProps { }

export const SpacesSection: FC<IProps> = (props) => {



    return (
        <Box id="SpacesSection" className='SpacesSection' sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", my: 4 }}>
            {/* <TitleSection title="Nos espaces à louer" mode={EnumTitleSectionMode.standard} /> */}
            <TitleSection title="Nos espaces à louer" subtitle="Pour vos événements autour de la périnatalité, de la naissance et de la famille" mode={EnumTitleSectionMode.standard} />
            <Box sx={{ mb: 4, mt: 2, px: 2, textAlign: "center" }}>
                <Typography>
                    Le centre thérapeutique Naissance et Famille <KazOceaSpan /> met à la disposition des professionnels deux espaces à la location.
                    <br />
                    Deux espaces atypiques, l’un intérieur, l’autre extérieur, pour mener vos ateliers, formations, cours, etc., face à la mer.
                </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <SpaceCard space={EnumSpaceReference.workshop} />
                <SpaceCard space={EnumSpaceReference.terrace} />
            </Box>
            <Flexbox sx={{ mt: 2, textAlign: "center" }}>
                <Typography>
                    Yoga périnatal, pilates, cercle de femmes, atelier portage ou massage bébé, café allaitement, formation,...
                    <br />
                    <HighlightSpan content="Vous êtes les bienvenus, du moment que votre évènement s’inscrit dans une des thématiques cœur du centre." />
                </Typography>
            </Flexbox>
        </Box>
    )
}


