
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


export const WaveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 337 25" {...props}>
      <path d="M1 8.50926C16.1667 2.8426 60 -5.09074 114 8.50926C181.5 25.5093 293.5 31.5093 336 8.50926" fill='none' stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </SvgIcon>
  )
}

