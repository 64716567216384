import { FC, useEffect, useState } from 'react';
import { Typography, useTheme } from "@mui/material";
import { ICustomer } from '../../interfaces';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { SxProps } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useMutation } from '@apollo/client';
import { GQL_MUTATION_DELETE_CUSTOMER, GQL_MUTATION_UPDATE_CUSTOMER } from '../../graphql/Mutations';
import { Flexbox } from '../utils/Flexbox';
import { DeleteConfirm } from '../utils/DeleteConfirm';
import { addMessage } from '../messages/Message';
import { EnumMessageType } from '../../enums';
import { useDispatch } from 'react-redux';
import { UpdateCustomerDialog } from './UpdateCustomerDialog';
import { getCustomers } from '../../redux/features/customers/customersSlice';

interface IProps {
    customer: ICustomer,
    sx?: SxProps,
}

export const CustomerItem: FC<IProps> = (props) => {
    const [showDeleteCustomerDialog, setShowDeleteCustomerDialog] = useState(false)
    const [deleteCustomer, { data: deleteCustomerData, loading: deleteCustomerLoading, error: deleteCustomerError }] = useMutation(GQL_MUTATION_DELETE_CUSTOMER)
    const [showUpdateCustomerDialog, setShowUpdateCustomerDialog] = useState(false)
    const [updateCustomer, { data: updateCustomerData, loading: updateCustomerLoading, error: updateCustomerError }] = useMutation(GQL_MUTATION_UPDATE_CUSTOMER)

    const theme = useTheme()
    const dispatch = useDispatch()

    const closeDeleteCustomerDialog = () => {
        setShowDeleteCustomerDialog(false)
    }

    const confirmDeleteCustomerDialog = () => {
        deleteCustomer({
            variables: {
                customerId: props.customer.id
            }
        })
    }

    useEffect(() => {
        if (deleteCustomerError) {
            addMessage({
                // location: "CustomerItem",
                type: EnumMessageType.Error,
                message: deleteCustomerError.message,
            })
        } else if (deleteCustomerData) {
            if (deleteCustomerData.deleteCustomer.statusCode === 200) {
                addMessage({
                    type: EnumMessageType.Success,
                    message: "locataire supprimé.",
                })
                closeDeleteCustomerDialog()
                dispatch(getCustomers())
            } else {
                deleteCustomerData.deleteCustomer.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteCustomerData, deleteCustomerError])

    const closeUpdateCustomerDialog = () => {
        setShowUpdateCustomerDialog(false)
    }

    const confirmUpdateCustomerDialog = (customer: ICustomer) => {
        updateCustomer({
            variables: { customerId: customer.id, ...customer }
        })
        setShowUpdateCustomerDialog(false)
    }

    useEffect(() => {
        if (updateCustomerError) {
            addMessage({
                // location: "CustomerItem",
                type: EnumMessageType.Error,
                message: updateCustomerError.message,
            })
        } else if (updateCustomerData) {
            if (updateCustomerData.updateCustomer.statusCode === 200) {
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Locataire modifié.",
                })
                dispatch(getCustomers())
            } else {
                updateCustomerData.updateCustomer.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updateCustomerData, updateCustomerError])

    return (
        <>
            <Grid container sx={{ width: "100%", ...props.sx }} >
                <Grid xs={3} >
                    <Typography>{props.customer.lastname}</Typography>
                </Grid>
                <Grid xs={3}>
                    <Typography>{props.customer.firstname}</Typography>
                </Grid>
                <Grid xs={5}>
                    <Typography>{props.customer.email}</Typography>
                </Grid>
                <Grid xs={1} >
                    <Flexbox sx={{ justifyContent: "space-around" }}>
                        <Typography color="text.secondary">
                            <DeleteOutlineOutlinedIcon onClick={() => setShowDeleteCustomerDialog(true)} sx={{
                                ":hover": {
                                    cursor: "pointer",
                                    color: theme.palette.error.main
                                }
                            }} />
                        </Typography>
                        <Typography color="text.secondary">
                            <EditIcon onClick={() => setShowUpdateCustomerDialog(true)} sx={{
                                ":hover": {
                                    cursor: "pointer",
                                    color: theme.palette.secondary.main
                                }
                            }} />
                        </Typography>
                    </Flexbox>
                </Grid>

            </Grid>
            <DeleteConfirm
                open={showDeleteCustomerDialog}
                title="Suppression du locataire"
                content="Confirmez-vous la suppression définitive de ce locataire ?"
                handleClose={closeDeleteCustomerDialog}
                handleConfirm={confirmDeleteCustomerDialog}
            />
            <UpdateCustomerDialog
                open={showUpdateCustomerDialog}
                customer={props.customer}
                handleClose={closeUpdateCustomerDialog}
                handleConfirm={confirmUpdateCustomerDialog}
            />
        </>
    )
}


