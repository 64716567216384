import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { Layout } from "../Layout";
import { WaveIcon } from '../../components/svg_icons/WaveIcon';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { KazOceaList } from '../../components/KazOceaList';
import { EnumKazOceaListMode, EnumTherapist } from '../../enums';
import { TherapistNavigation } from '../../components/TherapistNavigation';


interface IProps {
}

export const MarineDupeyron: FC<IProps> = (props) => {

  const theme = useTheme()

  const listElements = [
    <>
      Tout enfant dont les parents s’inquiètent de son développement ou de son comportement.
    </>,
    <>
      Toute personne (enfant, adolescent ou adulte) présentant des troubles tonico- émotionnels : instabilité, nervosité, agressivité ou, au contraire, inhibition, passivité, lenteur...
    </>,
    <>
      Tout enfant en retard dans ses acquisitions psychomotrices : marche, propreté,..
    </>,
    <>
      Tout enfant présentant un trouble du neurodéveloppement.
    </>,
    <>
      Toute personne en difficulté d’apprentissage.
    </>,
    <>
      Toute personne stressée, déprimée, ayant des difficultés d’adaptation personnelle
      ou professionnelle.
    </>,
    <>
      Toute personne mal latéralisée, mal organisée dans le temps et/ou dans l’espace.
    </>,
    <>
      Toute personne présentant des difficultés d’adaptation liées à un handicap mental,
      moteur ou psychique.
    </>,
    <>
      Toute personne âgée préoccupée par la réduction de son autonomie.
    </>,
  ]

  // A cause de React, faut forcer le scrollTo top sur les nouvelles pages.
  useEffect(() => {
    // window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Ajouter un comportement de défilement fluide
      });
    }, 500);
  }, [])



  return (
    <div>
      <Layout>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "800px",
          mx: "auto",
          pt: { xs: 10, md: 16 },
        }}>
          <Avatar
            alt="Marine DUPEYRON"
            src={`${process.env.PUBLIC_URL}/images/therapists/Marine_DUPEYRON_avatar.jpg`}
            sx={{
              width: "165px",
              height: "165px",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
            }}
          />
          <Typography sx={{ fontFamily: theme.typography.alternative, fontSize: 36, color: theme.palette.secondary.main, mt: 1 }}>
            Marine DUPEYRON
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Psychomotricienne,
            <br />
            Accompagnement parental
          </Typography>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 4, mb: 2 }} />
          <Box sx={{ px: 2, mb: 2 }}>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mb: 1 }}>
              Qui suis-je?
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Psychomotricienne depuis 2015, j’ai d’abord exercé 4 ans en gérontopsychiatrie et conjointement avec des enfants présentant des troubles des fonctions intellectuelles.
              <br />
              <br />
              Ensuite, pendant 3 ans, j’ai exercé en CAMSP auprès d’enfants entre 0 et 6 ans présentant des troubles variés: manque d’aisance corporelle avec des décalages ou des retards de développement (liés par exemple à une grande prématurité ou des syndromes génétiques), difficultés de régulation tonico-émotionnelle, manque de stimulation, troubles du neuro-développement,...
              <br />
              <br />
              Après avoir évalué les capacités globales de l’enfant, mes séances s’organisent autour de techniques corporelles, manuelles et artistiques variées (ex: le jeu et l’éveil psychomoteur, l’éducation gestuelle, la relaxation psychomotrice, l’intégration sensorielle,... ).
              <br />
              <br />
              Je mène le projet thérapeutique en étroite collaboration avec les parents car je place le soutien, l’écoute et l’accompagnement parental au cœur de ma pratique.
              <br />
              <br />
              Enfin et, pour permettre une cohérence dans la prise en charge de l’enfant, je peux faire le lien avec son établissement scolaire ou tout autre professionnel également engagé dans son suivi.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mt: 4, mb: 1 }}>
              La psychomotricité, c’est quoi, pour qui, comment?
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Professionnel de santé, le psychomotricien reçoit des patients de la petite enfance à l’âge adulte jusqu’à la sénescence sur prescription médicale.
              <br />
              <br />
              Il reçoit:
            </Typography>
            <KazOceaList mode={EnumKazOceaListMode.standard} elements={listElements} textSx={{ fontFamily: theme.typography.writer, fontSize: "16px" }} />
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 2, mb: 1 }}>
              Pour commencer, par le biais d’un entretien, de mises en situation et de tests standardisés, le psychomotricien évalue les compétences du sujet en mettant en lumière ses potentialités, ses difficultés, retards et/ou manques éventuels et pose un diagnostic psychomoteur.
              <br />
              <br />
              Ensuite, il propose des activités psychomotrices qui vont solliciter l’individu dans sa capacité à se mettre en mouvement et en relation avec son environnement en encourageant ses compétences émotionnelles, intellectuelles et relationnnelles.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mt: 4, mb: 1 }}>
              Infos pratiques
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Je vous accueille les vendredis entre 8h et 18h dans l’espace Embrun, sur rdv (prise de rdv par téléphone au
              &nbsp;
              <Typography
                component="a"
                href='tel:0692396189'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                06.92.39.61.89
              </Typography>
              ).
              <br />
              <br />
              Les séances durent en moyenne 45 min et se font majoritairement en individuel mais peuvent également se faire en groupe.
              <br />
              <br />
              Le coût de la séance est de 50 euros (actuellement les soins psychomoteurs ne sont pas remboursés par la Sécurité Sociale: des financements peuvent être envisagés sur demande spécifique à la CPAM, auprès de certaines mutuelles et, dans le cas d’un handicap reconnu, l’allocation décidée par la Maison Départementale de la Personne Handicapée (MDPH) permettra le règlement des séances).
            </Typography>
          </Box>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 2, mb: 2 }} />
          <Typography sx={{ mt: -1, color: theme.palette.secondary.main }}>
            Espace Embrun
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ mx: 4, textAlign: "center" }}>
          <Grid xs={12} >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/images/spaces/embrun.jpg`}
              sx={{
                width: { xs: "250px", sm: "500px" },
                borderStyle: "solid",
                borderWidth: 2,
                borderColor: theme.palette.secondary.main,
                borderRadius: "24px",
                mt: 1,
              }}
            />
          </Grid>
        </Grid>
        <TherapistNavigation currentTherapist={EnumTherapist.marineDupeyron} sx={{ mt: 4 }} />
      </Layout>
    </div >
  )
}
