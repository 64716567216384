import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ICustomer, IEmptySlot, IReservationPrototype, ISpace, ISpaceInfos } from '../../interfaces';
import { Flexbox } from '../utils/Flexbox';
import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import { EnumMessageType, EnumReservationState, EnumReservationType } from '../../enums';
import 'dayjs/locale/fr';
import { CustomerSelection } from '../customers/CustomerSelection';
import { checkSlotAvailable, getDayjsDuration, getSpacesPriceForSlot } from '../../utils/helpers';
import { addMessage } from '../messages/Message';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../redux/store';
// import { getSpacesInfos } from '../../redux/features/global/globalSlice';

interface IProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: (reservationPrototype: IReservationPrototype) => void;
    space: ISpace,
    emptySlot: IEmptySlot,
}

export const CreateReservationDialog: FC<IProps> = (props) => {

    const initialState: IReservationPrototype = {
        customer: undefined,
        space: props.space,
        startAt: props.emptySlot.startAt,
        endAt: props.emptySlot.endAt,
        price: getSpacesPriceForSlot([props.space], props.emptySlot.startAt, props.emptySlot.endAt).toString(),
        title: "",
        state: EnumReservationState.created,
        spacesCheckedIds: [props.space.id],
        reservationType: EnumReservationType.standard,
    }
    var stateToSend: IReservationPrototype = initialState

    const [customer, setCustomer] = useState<ICustomer | undefined>(initialState.customer)
    const [startAt, setStartAt] = useState<Dayjs>(initialState.startAt.local())
    const [endAt, setEndAt] = useState<Dayjs>(initialState.endAt.local())
    const [price, setPrice] = useState<string>(initialState.price)
    const [title, setTitle] = useState<string>(initialState.title)
    const [state, setState] = useState<EnumReservationState>(initialState.state)
    const [spacesCheckedIds, setSpacesCheckedIds] = useState<string[]>(initialState.spacesCheckedIds)
    const [reservationType, setReservationType] = useState<EnumReservationType>(initialState.reservationType)

    // const spacesInfos: ISpaceInfos[] = useSelector((state: IRootState) => state.globalReducer.spacesInfos)
    const spaces: ISpace[] = useSelector((state: IRootState) => state.spaceReducer.spaces)

    // const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(getSpacesInfos())
    // }, [])

    const theme = useTheme()

    const prepareStateToSend = () => {
        stateToSend = {
            ...stateToSend,
            customer,
            startAt,
            endAt,
            price,
            title,
            state,
            spacesCheckedIds,
            reservationType,
        }
        if (reservationType === EnumReservationType.disabled) {
            stateToSend = {
                ...stateToSend,
                customer: undefined,
                price: "0",
                state: initialState.state,
            }
        }

    }

    useEffect(() => {
        // if (spacesInfos) {
        //     const spacesChecked = spacesInfos.filter((spaceInfo) => spacesCheckedIds.includes(spaceInfo.id))
        //     setPrice(getSpacesPriceForSlot(spacesChecked, startAt, endAt).toString())
        // }
        const spacesChecked = spaces.filter((space) => spacesCheckedIds.includes(space.id))
        setPrice(getSpacesPriceForSlot(spacesChecked, startAt, endAt).toString())
    }, [startAt, endAt, spacesCheckedIds])

    const resetState = () => {
        setCustomer(initialState.customer)
        setStartAt(initialState.startAt)
        setEndAt(initialState.endAt)
        setTitle(initialState.title)
        setState(initialState.state)
        setSpacesCheckedIds(initialState.spacesCheckedIds)
        setReservationType(initialState.reservationType)
    }

    const checkDatas = () => {
        var res = true
        // Le customer doit être sélectionné si reservation standard:
        if (reservationType === EnumReservationType.standard && !customer) {
            res = false
            addMessage({
                type: EnumMessageType.Error,
                message: "Le locataire est obligatoire."
            })
        }
        // Un espace doit être sélectionné:
        if (spacesCheckedIds.length <= 0) {
            res = false
            addMessage({
                type: EnumMessageType.Error,
                message: "Aucun espace sélectionné."
            })
        }
        if (getDayjsDuration(startAt, endAt) <= 0) {
            res = false
            addMessage({
                type: EnumMessageType.Error,
                message: "Les dates sont incohérentes."
            })
        }
        if (!checkSlotAvailable(props.space, startAt, endAt, null)) {
            res = false
            addMessage({
                type: EnumMessageType.Error,
                message: "Une réservation existe déjà sur ce créneau."
            })
        }
        return res
    }

    const handleConfirm = () => {
        if (checkDatas()) {
            // On unset les valeur en cas de rappel de la dialog:
            prepareStateToSend()
            resetState()
            props.handleConfirm(stateToSend)
        }
    }

    const handlePrice = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPrice(event.currentTarget.value)
    }

    const handleStartAt = (newStartAt: Dayjs | null) => {
        if (newStartAt) {
            setStartAt(newStartAt)
        }
    }
    const handleEndAt = (newEndAt: Dayjs | null) => {
        if (newEndAt) {
            setEndAt(newEndAt)
        }
    }

    const handleCustomerSelection = (customer: ICustomer) => {
        setCustomer(customer)
    }

    const handleTitle = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTitle(event.currentTarget.value)
    }

    const handleState = (newState: string) => {
        if (
            newState === EnumReservationState.created ||
            newState === EnumReservationState.confirmed
        ) {
            setState(newState)
        } else {
            console.error("state ", newState, " unknown !")
        }
    }

    const handleSpacesChecked = (spaceId: string) => {
        if (spacesCheckedIds.includes(spaceId)) {
            setSpacesCheckedIds(spacesCheckedIds.filter(aSpaceId => aSpaceId !== spaceId));
        } else {
            setSpacesCheckedIds([...spacesCheckedIds, spaceId])
        }
    }

    const handleChangeReservationType = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === EnumReservationType.standard) {
            setReservationType(EnumReservationType.standard)
        } else if (event.target.value === EnumReservationType.disabled) {
            setReservationType(EnumReservationType.disabled)
        } else {
            addMessage({
                type: EnumMessageType.Error,
                message: "Type de réservation inconnue.",
            })
        }
    }

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth
            >
                <DialogTitle color="primary">
                    Nouvelle réservation
                </DialogTitle>
                <DialogContent>
                    <Flexbox direction='column'
                        sx={{
                            marginTop: 0,
                        }}
                    >
                        <Flexbox sx={{ width: "100%", mb: 2 }}>
                            <FormControl sx={{ width: "100%" }} >
                                <RadioGroup
                                    value={reservationType}
                                    onChange={handleChangeReservationType}
                                >
                                    <Flexbox sx={{ width: "100%", justifyContent: "space-between" }} >
                                        <FormControlLabel value={EnumReservationType.standard} control={<Radio />} label="Standard" />
                                        <FormControlLabel value={EnumReservationType.disabled} control={<Radio />} label="Indisponibilité" />
                                    </Flexbox>
                                </RadioGroup>
                            </FormControl>
                        </Flexbox>
                        <Flexbox sx={{ width: "100%", justifyContent: "space-between" }} >
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <DateTimePicker
                                    label="Du"
                                    value={startAt}
                                    onChange={(newStartAt) => handleStartAt(newStartAt)}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <DateTimePicker
                                    label="Au"
                                    value={endAt}
                                    onChange={(newEndAt) => handleEndAt(newEndAt)}
                                />
                            </LocalizationProvider>
                        </Flexbox>


                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Libellé"
                            color="primary"
                            onChange={(event) => { handleTitle(event) }}
                            value={title}
                            autoComplete='off'
                            type="text"
                            autoFocus
                        />
                        {
                            reservationType === EnumReservationType.standard && <>
                                <Flexbox sx={{ width: "100%", mt: 1 }}>
                                    <CustomerSelection handleConfirm={handleCustomerSelection} customer={customer} />
                                </Flexbox>
                            </>
                        }
                        <Flexbox
                            sx={{
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                            }}>
                            <Typography sx={{ mt: 1, color: theme.palette.text.secondary }}>
                                Espaces:
                            </Typography>
                            <FormGroup>
                                <Flexbox>
                                    {
                                        // spacesInfos && spacesInfos.map((spaceInfo) =>
                                        spaces.map((space) =>
                                            <FormControlLabel key={`space-infos-checkbox-${space.id}`} control={
                                                <Checkbox
                                                    checked={spacesCheckedIds.includes(space.id)}
                                                    onChange={() => handleSpacesChecked(space.id)}
                                                />
                                            } label={space.title} />
                                        )
                                    }
                                </Flexbox>
                            </FormGroup>
                        </Flexbox>
                        {
                            reservationType === EnumReservationType.standard && <>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Prix"
                                    color="primary"
                                    onChange={(event) => { handlePrice(event) }}
                                    value={price}
                                    autoComplete='off'
                                    type="number"
                                />

                            </>
                        }
                        {
                            reservationType === EnumReservationType.standard && <>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="select-reservation-state">Statut</InputLabel>
                                    <Select
                                        id="select-reservation-state"
                                        value={state}
                                        label="Statut"
                                        onChange={(event) => { handleState(event.target.value) }}
                                        sx={{ width: "50%" }}
                                    >
                                        <MenuItem value={EnumReservationState.created}>Créé</MenuItem>
                                        <MenuItem value={EnumReservationState.confirmed}>Confirmé</MenuItem>
                                    </Select>
                                </FormControl>
                            </>
                        }
                    </Flexbox>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} variant="outlined" color="primary">Annuler</Button>
                    <Button onClick={handleConfirm} variant="contained" color="secondary">
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    )
}
