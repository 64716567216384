import { FC, useEffect } from 'react';
import { Box, Link, useTheme } from "@mui/material";
import { NAVIGATE_ADMIN_CUSTOMERS_PAGE, NAVIGATE_ADMIN_LOGIN, NAVIGATE_ADMIN_PAGE, NAVIGATE_ADMIN_SPACES_PAGE, isActivePath } from '../utils/Navigation';
import { KazoceaLogo } from './KazoceaLogo';
import { EnumKazoceaLogoMode, EnumMessageType } from '../enums';
import { CustomSpan } from './CustomSpan';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../redux/store';
import { useGetCurrentUser } from '../redux/features/users/currentUserSlice';
import { removeJwt } from '../redux/features/global/apolloSlice';
import { addMessage } from './messages/Message';
import { useLocation } from 'react-router-dom';

interface IProps {
}

interface IPage {
    title: string
    path: string
    strictUrl?: boolean,
}


const pages: IPage[] = [
    {
        title: 'Accueil',
        path: NAVIGATE_ADMIN_PAGE,
        strictUrl: true,
    },
    {
        title: 'Locataires',
        path: NAVIGATE_ADMIN_CUSTOMERS_PAGE,
    },
    {
        title: 'Planning',
        path: NAVIGATE_ADMIN_SPACES_PAGE,
    },
];



export const AdminAppBar: FC<IProps> = (props) => {

    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()

    const hasJwt = useSelector((state: IRootState) => state.apolloReducer.hasJwt)



    useEffect(() => {
        if (!hasJwt) {
            // Il n'y a pas de JWT, on redirect sur login..
            // Ne pas mettre de message à cause des redirects..
            navigate(`${NAVIGATE_ADMIN_LOGIN}`)
        }
    }, [])

    const currentUser = useGetCurrentUser()
    // const currentUser: (IUser | undefined) = useSelector((state: IRootState) => state.currentUserReducer.user)

    const dispatch = useDispatch()

    const logout = (withMessage: boolean = false) => {
        dispatch(removeJwt())
        if (withMessage) {
            addMessage({
                type: EnumMessageType.Success,
                message: "Déconnexion effectuée.",
            })
        }
        // Refresh page :
        // navigate(0)
        navigate(NAVIGATE_ADMIN_LOGIN)

    }


    return (
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // width: "100%",
            // backgroundColor: "red",
            backgroundColor: theme.palette.background.default,
            borderBottomStyle: "solid",
            borderBottomWidth: 2,
            borderBottomColor: theme.palette.primary.main,
            py: 1,
            flexWrap: "wrap",
        }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <KazoceaLogo mode={EnumKazoceaLogoMode.logoOnly} sx={{ width: "50px" }} />
                {
                    currentUser &&
                    <CustomSpan primaryPart={currentUser.firstname} secondaryPart={currentUser.lastname} spacedParts />
                }
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
            }}>
                {pages.map((page, index) => (
                    // <Link key={index} sx={{ textDecoration: "none", px: 2, ":hover": { cursor: "pointer", textDecoration: "underline" } }} color={page.title === 'Accueil' ? "primary" : "secondary"} onClick={() => navigate(page.path)}>
                    <Link key={index} sx={{ textDecoration: "none", px: 2, ":hover": { cursor: "pointer", textDecoration: "underline" } }} color={isActivePath(page.path, location.pathname, page.strictUrl) ? "primary" : "secondary"} onClick={() => navigate(page.path)}>
                        {page.title}
                    </Link>
                ))}
                <Link sx={{ textDecoration: "none", px: 2, ":hover": { cursor: "pointer", textDecoration: "underline" } }} color="secondary" onClick={() => logout(true)}>
                    Déconnexion
                </Link>

            </Box>

        </Box>
    )
}


