import { FC } from 'react';
import { Box } from "@mui/material";
import { TitleSection } from '../components/TitleSection';
import { EnumTitleSectionMode } from '../enums';
import { FacebookFeed } from '../components/FacebookFeed';
import { InstagramFeed } from '../components/InstagramFeed';

interface IProps { }

export const SocialSection: FC<IProps> = (props) => {



    return (
        <Box id="SocialSection" className='SocialSection' sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            my: 4,
            gap: 4,
        }}>
            <TitleSection title="Suivez-nous sur les Réseaux Sociaux !" mode={EnumTitleSectionMode.standard} />
            <FacebookFeed />
            <InstagramFeed />
        </Box>
    )
}


