import { Avatar, Box, Button, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { Layout } from "../Layout";
import { WaveIcon } from '../../components/svg_icons/WaveIcon';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import DownloadIcon from '@mui/icons-material/Download';
import { TherapistNavigation } from '../../components/TherapistNavigation';
import { EnumTherapist } from '../../enums';


interface IProps {
}

export const BenedicteLeParc: FC<IProps> = (props) => {

  const theme = useTheme()

  // A cause de React, faut forcer le scrollTo top sur les nouvelles pages.
  useEffect(() => {
    // window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Ajouter un comportement de défilement fluide
      });
    }, 500);
  }, [])

  return (
    <div>
      <Layout>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "800px",
          mx: "auto",
          pt: { xs: 10, md: 16 },
        }}>
          <Avatar
            alt="Bénédicte LE PARC"
            src={`${process.env.PUBLIC_URL}/images/therapists/Benedicte_LE_PARC_avatar.jpg`}
            sx={{
              width: "165px",
              height: "165px",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
            }}
          />
          <Typography sx={{ fontFamily: theme.typography.alternative, fontSize: 36, color: theme.palette.secondary.main, mt: 1 }}>
            Bénédicte LE PARC
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Orthophoniste
          </Typography>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 4, mb: 2 }} />
          <Box sx={{ px: 2, mb: 2 }}>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Je suis Bénédicte LE PARC Orthophoniste à KAZ OCEA, Espace EMBRUN.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              La pratique de l'orthophonie comporte la promotion de la santé, la prévention, le bilan orthophonique et le traitement des troubles de la communication, du langage dans toutes ses dimensions, de la cognition mathématique, de la parole, de la voix et des fonctions oro-myo-faciales.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              Retrouvez ici l'ensemble des actes orthophoniques ainsi que les honoraires conformes aux tarifs de la sécurité sociale.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 2 }}>
              <Button variant='outlined'
                size="small"
                color="secondary"
                startIcon={<DownloadIcon />}
                href={`${process.env.PUBLIC_URL}/files/Affiche-NGAP-20230701-DOM orthophoniste.pdf`}
                target="_blank"
              >
                affiche NGAP DOM
              </Button>
            </Box>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              Pour toute demande de rendez-vous, veuillez vous inscrire sur la liste d'attente commune des orthophonistes de La Réunion via la plateforme Inzeecare.com ou bien contacter ce numéro :
              &nbsp;
              <Typography
                component="a"
                href='tel:0692275235'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                06 92 27 52 35
              </Typography>
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 4, mb: 4 }}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/files/Inzeecare_flyerA5_particuliers_web_orthophoniste.jpeg`}
                sx={{
                  width: { xs: "250px", sm: "500px" },
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: theme.palette.secondary.main,
                  borderRadius: "24px",
                  mt: 1,
                }}
              />
            </Box>
          </Box>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 2, mb: 2 }} />
          <Typography sx={{ mt: -1, color: theme.palette.secondary.main }}>
            Espace Embrun
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ mx: 4, textAlign: "center" }}>
          <Grid xs={12} >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/images/spaces/embrun.jpg`}
              sx={{
                width: { xs: "250px", sm: "500px" },
                borderStyle: "solid",
                borderWidth: 2,
                borderColor: theme.palette.secondary.main,
                borderRadius: "24px",
                mt: 1,
              }}
            />
          </Grid>
        </Grid>
        <TherapistNavigation currentTherapist={EnumTherapist.benedicteLeParc} sx={{ mt: 4 }} />
      </Layout>
    </div >
  )
}
