import { FC } from 'react';
import { Typography, useTheme } from "@mui/material";
import { EnumWriterSpanMode } from '../enums';

interface IProps {
    content: string
    mode: EnumWriterSpanMode
}

export const WriterSpan: FC<IProps> = (props) => {
    const theme = useTheme()

    return (
        <>
            {
                props.mode === EnumWriterSpanMode.colorSecondary && (
                    <Typography
                        component="span"
                        sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main }}
                    >
                        {props.content}
                    </Typography>
                )
            }
            {
                props.mode === EnumWriterSpanMode.italic && (
                    <Typography
                        component="span"
                        sx={{ fontFamily: theme.typography.writer, fontSize: "16px", fontStyle: "italic" }}
                    >
                        {props.content}
                    </Typography>
                )
            }
            {
                props.mode === EnumWriterSpanMode.bold && (
                    <Typography
                        component="span"
                        sx={{ fontFamily: theme.typography.writer, fontSize: "16px", fontWeight: "600", }}
                    >
                        {props.content}
                    </Typography>
                )
            }
        </>
    )
}


