import { FC, useState } from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import { TitleSection } from '../components/TitleSection';
import { KazOceaSpan } from '../components/KazOceaSpan';


import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { KazOceaList } from '../components/KazOceaList';
import { TherapistCard } from '../components/TherapistCard';
import { EnumHighlightSpanMode, EnumKazOceaListMode, EnumTherapist, EnumTherapistMode, EnumTitleSectionMode } from '../enums';
import { Flexbox } from '../components/utils/Flexbox';
import InfoIcon from '@mui/icons-material/Info';
import { HighlightSpan } from '../components/HighlightSpan';
import { WaveIcon } from '../components/svg_icons/WaveIcon';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

interface IProps { }

export const SpaceWorkshopRulesSection: FC<IProps> = (props) => {

    const theme = useTheme()

    const getWaveSeparator = () => {
        return <Flexbox sx={{
            width: "100%",
            maxWidth: "800px",
            mx: "auto",
        }}>
            <WaveIcon color="secondary" sx={{ width: "300px", mt: 8, mb: 2 }} />
        </Flexbox>
    }

    const getSalleAtelier = () => {
        return <Typography component="span" sx={{ color: theme.palette.primary.main, fontWeight: "700" }}>Atelier Horizon</Typography>
    }

    const listElements1 = [
        <>
            Ouvrez le cache de protection contre les intempéries
        </>,
        <>
            Assurez-vous que la flèche est orientée vers <LockOutlinedIcon /> « CADENAS FERME ». Si non, tournez le bouton jusqu’à ce que ce soit le cas.
        </>,
        <>
            Faites glisser le bouton de réinitialisation vers le bas (tout en bas entre le C et le D) et relâchez-le.
        </>,
        <>
            Entrez le code transmis par mail
        </>,
        <>
            Tournez le bouton pour mettre la flèche orientée vers la position <LockOpenOutlinedIcon /> « CADENAS OUVERT »
        </>,
        <>
            Ouvrez le boîtier avec précaution: vous trouvez un jeu de 3 clefs
        </>,
        <>
            Récupérez le trousseau de clefs et refermez le boîtier en repositionnant le bouton en mode <LockOutlinedIcon /> « CADENAS FERME »
        </>,
    ]

    const listElements2 = [
        <>
            <HighlightSpan content="La clef des WC:" bold={true} mode={EnumHighlightSpanMode.black} /> Si aucun thérapeute n’est présent au centre ou si la femme de ménage ne l’a pas déjà ouvert lors de son passage tôt le matin en semaine, les WC seront fermés.
            Vous pourrez donc les ouvrir pour vous et vos participants.
        </>,
        <>
            <HighlightSpan content="La clef des volets électriques côté mer:" bold={true} mode={EnumHighlightSpanMode.black} /> De même que pour les WC, si aucun thérapeute n’est présent au centre ou si la femme de ménage n’est pas passée, les volets seront fermés.
            Il vous faudra donc les ouvrir avec cette clef.
            <br />
            La serrure se trouve sur la droite de la salle {getSalleAtelier()}.
            La clef déclenchera l’ouverture des 3 volets côté mer.
            <br />
            <br />
            <Typography component="span" sx={{ textDecoration: "underline" }}>
                Petite précision:
            </Typography>
            <Typography component="span" sx={{ fontStyle: "italic", ml: 1 }}>
                Pour ne pas abimer le mécanisme: lorsque vous tournez la clef dans la serrure, vers la droite pour déclencher l'ouverture ou vers la gauche pour la fermeture (dans le sens des flèches),  dés que le mouvement des volets s'active, laissez la clef revenir au centre et retirez là, ne la maintenez pas vers la droite ou la gauche.
            </Typography>
            <Grid container spacing={2} sx={{ mx: 0, mt: 4, width: "100%", justifyContent: "space-around" }}>
                <Grid xs={12} sm={6} md={4}>
                    <Box component="img" src={`${process.env.PUBLIC_URL}/images/rules/05_fermeture_volets.jpeg`}
                        sx={{
                            width: "100%",
                            borderStyle: "solid",
                            borderWidth: 0,
                            borderRadius: "24px",
                        }}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <Box component="img" src={`${process.env.PUBLIC_URL}/images/rules/06_fermeture_volets.jpeg`}
                        sx={{
                            width: "100%",
                            borderStyle: "solid",
                            borderWidth: 0,
                            borderRadius: "24px",
                        }}
                    />
                </Grid>
            </Grid>
        </>,
        <>
            <HighlightSpan content="La clef de la salle" bold={true} mode={EnumHighlightSpanMode.black} /> {getSalleAtelier()}.
        </>,
    ]

    const listElements3 = [
        <>
            dans l’espace de rangement caché par le rideau : des tables et bancs pliants, un paperboard, une lampe halogène, du matériel d’entretien.
            <br />
            Les autres affaires appartiennent aux thérapeutes du centre.
        </>,
        <>
            dans l’espace cuisine, vous trouverez une cafetière Nespresso et une cafetière filtre, un micro-onde, four, et réfrigérateur.
            <br />
            Vous pourrez donc amener du café ou thé pour vos participants si vous le souhaitez.
            <br />
            Dans les placards de la cuisine se trouvent un vidéo projecteur, des feutres pour le paperboard, une théière, quelques assiettes, verres, couverts et tasses, des nappes et produits d’entretien.
        </>,
    ]

    const listElements4 = [
        <>
            La salle {getSalleAtelier()} doit être rendue propre.
            Produits et matériel d’entretien sont à votre disposition dans la salle atelier si besoin.
        </>,
        <>
            Merci de bien fermer à clef la salle {getSalleAtelier()}.
        </>,
        <>
            Si aucun thérapeute n’est présent dans le centre, vous pouvez fermer les volets électriques et les WC.
            <br />
            Pour repérer s’il y a des thérapeutes, il suffit de vérifier s’il y a des chaises bleu ou jaune placées près des salles de soins, tout autour de la maison. Ces chaises sont automatiquement sorties quand un thérapeute est présent.
            <br />
            <br />
            Seuls les bancs en bois restent toujours à l’extérieur.
            <br />
            Si des thérapeutes sont présents, vous fermerez à clef uniquement la salle {getSalleAtelier()}.
            <br />
            <Grid container spacing={2} sx={{ mx: 0, mt: 4, width: "100%", justifyContent: "center" }}>
                <Grid xs={12} sm={6} md={4}>
                    <Box component="img" src={`${process.env.PUBLIC_URL}/images/spaces/coquillage.jpg`}
                        sx={{
                            width: "100%",
                            borderStyle: "solid",
                            borderWidth: 0,
                            borderRadius: "24px",
                        }}
                    />
                </Grid>
            </Grid>
        </>,
        <>
            Remettre le jeu de 3 clefs dans le boîtier à code, en suivant la procédure identique de départ.
        </>,
    ]

    const listElements5 = [
        <>
            2 codes concernent les 2 portails d'entrée de <KazOceaSpan />,
        </>,
        <>
            1 code correspond à la boîte à clefs
        </>,
    ]





    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", my: 4 }}>
            <TitleSection title="Gestion des ateliers" mode={EnumTitleSectionMode.standard} />
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                textAlign: "justify",
                mx: 4,
                maxWidth: "1160px",
            }}>
                <Typography>
                    Bonjour et bienvenue à <KazOceaSpan />, c’est un plaisir de vous avoir parmi nous.
                    <br />
                    Nous espérons que vous apprécierez animer votre atelier, cours, formation dans cet espace atypique!
                </Typography>
                <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "20px", color: theme.palette.secondary.main, mt: 4, mb: 1 }}>
                    Quelques infos pratiques à vous donner pour le jour J:
                </Typography>
                <Typography>
                    Vous avez reçu par mail plusieurs codes:
                </Typography>
                <KazOceaList mode={EnumKazOceaListMode.standard} elements={listElements5} textSx={{ fontFamily: theme.typography.writer, fontSize: "16px" }} />
                <Typography sx={{
                    fontFamily: theme.typography.writer, fontSize: "16px",
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    mt: 4,
                    mb: 0,
                }}>
                    Codes portail et pmr/poussettes/vélo:
                </Typography>
                <Flexbox sx={{ color: theme.palette.cancel.main, mt: 0, mb: 1 }}>
                    <InfoIcon fontSize='small' sx={{ mr: 1 }} />
                    <Typography fontSize='small' sx={{ fontFamily: theme.typography.writer }} >
                        Ce code change régulièrement
                    </Typography>
                </Flexbox>
                <Typography>
                    Ce code vous permet d’entrer à <KazOceaSpan />, vous pourrez le transmettre également à vos participants pour qu’ils puissent entrer.
                    <br />
                    Ce code est actif de 7h30 à 19h30.
                    <br />
                    <br />
                    Le code portail PMR est à composer sur le même boîtier que le code portillon.
                    <br />
                    Il faut compter 60 secondes, le temps de marcher jusqu’au grand portail, pour qu’il se déverrouille.
                    <br />
                    Poussez le battant droit pour entrer.
                    <br />
                    <br />
                    Pour ressortir du centre par le grand portail, le bouton PMR se trouve près de la salle <Typography component="span" sx={{ color: theme.palette.primary.main, fontWeight: "700" }}>Sel marin</Typography>.
                </Typography>

                <Grid container spacing={2} sx={{ mx: 0, mt: 4, mb: 1, width: "100%", justifyContent: "center" }}>
                    <Grid xs={12} sm={6} md={4}>
                        <Box component="img" src={`${process.env.PUBLIC_URL}/images/rules/01_bouton_portail.jpeg`}
                            sx={{
                                width: "100%",
                                borderStyle: "solid",
                                borderWidth: 0,
                                borderRadius: "24px",
                            }}
                        />
                    </Grid>
                </Grid>
                {getWaveSeparator()}
                <Typography sx={{
                    fontFamily: theme.typography.writer,
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    mt: 4,
                    mb: 1,
                }}>
                    Code pour la boîte à clefs:
                </Typography>
                <Typography>
                    A votre arrivée, la chasse au trésor commence!
                    <br />
                    Vous devez trouver la boîte à clefs cachée dans le local poubelle en bois.
                    <br />
                    Ouvrez le battant droit, regardez derrière la poubelle jaune.
                </Typography>
                <Grid container spacing={2} sx={{ mx: 0, mt: 4, width: "100%", justifyContent: "space-around" }}>
                    <Grid xs={12} sm={6} md={4}>
                        <Box component="img" src={`${process.env.PUBLIC_URL}/images/rules/02_local_poubelle.jpeg`}
                            sx={{
                                width: "100%",
                                borderStyle: "solid",
                                borderWidth: 0,
                                borderRadius: "24px",
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={6} md={4}>
                        <Box component="img" src={`${process.env.PUBLIC_URL}/images/rules/03_poubelle.jpeg`}
                            sx={{
                                width: "100%",
                                borderStyle: "solid",
                                borderWidth: 0,
                                borderRadius: "24px",
                            }}
                        />
                    </Grid>
                </Grid>
                <Flexbox direction='column' sx={{
                    ml: 4,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    mt: 4
                }}>
                    <Typography sx={{ textDecoration: "underline" }}>
                        Boîte à clefs:
                    </Typography>
                    <KazOceaList mode={EnumKazOceaListMode.standard} elements={listElements1} textSx={{ fontFamily: theme.typography.writer, fontSize: "16px" }} />
                </Flexbox>
                <Grid container spacing={2} sx={{ mx: 0, mt: 4, width: "100%", justifyContent: "center" }}>
                    <Grid xs={12} sm={6} md={4}>
                        <Box component="img" src={`${process.env.PUBLIC_URL}/images/rules/04_locker.jpeg`}
                            sx={{
                                width: "100%",
                                borderStyle: "solid",
                                borderWidth: 0,
                                borderRadius: "24px",
                            }}
                        />
                    </Grid>
                </Grid>
                <Flexbox direction='column' sx={{
                    ml: 4,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    mt: 4
                }}>
                    <Typography sx={{ textDecoration: "underline" }}>
                        Le trousseau est composé de 3 clefs :
                    </Typography>
                    <KazOceaList mode={EnumKazOceaListMode.standard} elements={listElements2} textSx={{ fontFamily: theme.typography.writer, fontSize: "16px" }} />
                </Flexbox>
                {getWaveSeparator()}
                <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "20px", color: theme.palette.secondary.main, mt: 4, mb: 1 }}>
                    Rappel:
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    Dans la salle atelier, vous avez à votre disposition:
                </Typography>
                <KazOceaList mode={EnumKazOceaListMode.standard} elements={listElements3} textSx={{ fontFamily: theme.typography.writer, fontSize: "16px" }} />
                <Typography sx={{ mt: 0 }}>
                    Avant ou pendant votre atelier, si vous avez besoin de faire patienter les participants, merci de les orienter côté mer pour préserver le calme pour les thérapeutes présents en soin.
                    <br />
                    Merci également de leur demander de ne pas faire trop de bruit côté mer, pendant les pauses, car il y a une salle de soin corail près de la salle atelier (si le thérapeute est en rdv).
                    <br />
                    <br />
                    <KazOceaSpan /> est un <HighlightSpan mode={EnumHighlightSpanMode.standard} bold={true} content="centre non fumeur" />, il est donc interdit de fumer dans l'établissement même sur la terrasse, merci d'avance de le rappeler à vos participants.
                </Typography>
                {getWaveSeparator()}
                <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "20px", color: theme.palette.secondary.main, mt: 8, mb: 1 }}>
                    En fin d’atelier lors de votre départ:
                </Typography>
                <KazOceaList mode={EnumKazOceaListMode.standard} elements={listElements4} textSx={{ fontFamily: theme.typography.writer, fontSize: "16px" }} />
                {getWaveSeparator()}
                <Flexbox sx={{ width: "100%" }}>
                    <Typography sx={{
                        fontFamily: theme.typography.secondary,
                        fontSize: 18,
                        textAlign: "center",
                        mt: 4,
                    }}>
                        Merci d’avance pour votre compréhension !
                        <br />
                        <br />
                        Nous vous souhaitons un bel atelier à <KazOceaSpan /> !
                    </Typography>
                </Flexbox>
            </Box>
        </Box>
    )
}


