// import "../css/global.css"
import { Box, useTheme } from '@mui/material';
import { FC, ReactElement, useEffect } from 'react';
import { Footer } from '../components/Footer';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import { ScrollToTop } from '../components/ScrollToTop';


interface IProps {
  children: ReactElement | ReactElement[];
}

export const Layout: FC<IProps> = (props) => {
  const theme = useTheme()

  useEffect(() => {
    // Fonction pour extraire les paramètres de l'URL
    const getQueryParam = (name: string) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      return urlSearchParams.get(name);
    };

    // Obtenir la valeur de la variable 'scrollTo' depuis l'URL
    const sectionToScroll = getQueryParam('scrollTo');

    if (sectionToScroll) {
      // Obtenir l'élément correspondant à l'ID spécifié dans l'URL
      const targetElement = document.getElementById(sectionToScroll);

      if (targetElement) {
        // // Effectuer le défilement vers l'élément
        // window.scrollTo({
        //   // top: targetElement.offsetTop, // Position de l'élément par rapport au haut de la page
        //   top: targetElement.offsetTop - 100,
        //   behavior: 'smooth', // Ajouter un comportement de défilement fluide
        // });
        // Attendre 500 milliseconds (ou le temps nécessaire au chargement du carousel) avant de déclencher le défilement
        setTimeout(() => {
          // Effectuer le défilement vers l'élément
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth', // Ajouter un comportement de défilement fluide
          });
        }, 500); // Vous pouvez ajuster ce délai en fonction du temps nécessaire au chargement du carousel
      }
    }
  }, []); // L'effet s'exécute une seule fois après le rendu initial


  return (
    <Box className="Layout" sx={{ backgroundColor: theme.palette.background.default }}>
      <ResponsiveAppBar />
      {/* <Container maxWidth="xl" className='page-container' sx={{ minHeight: "800px", px: "0 !important" }}>
        {props.children}
      </Container> */}
      <Box className='page-container' sx={{ minHeight: "800px", px: "0 !important" }}>
        {props.children}
      </Box>
      <ScrollToTop />
      <Footer />
    </Box>
  )
}
