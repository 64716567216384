import { createSlice } from "@reduxjs/toolkit";
import { GQL_CUSTOMERS } from "../../../graphql/Queries";
import { ICustomer } from "../../../interfaces";
import { store } from "../../store";
import { apolloClient } from "../../../ApolloClient";

interface ICustomersSliceState {
    customers: ICustomer[],
    customersLoading: boolean,
    searchCustomersQuery: string,
}

const initialState: ICustomersSliceState = {
    customers: [],
    customersLoading: false,
    searchCustomersQuery: "",
}

export const customersSlice = createSlice({
    name: "customersSlice",
    initialState,
    reducers: {
        setCustomersAction: (state, action) => {
            state.customers = action.payload
        },
        setCustomersLoading: (state, action) => {
            state.customersLoading = action.payload
        },
        getCustomers: (state) => {
            // // On fetch que si c'est vide:
            // if (state.customers.length <= 0) {
            //     state.customersLoading = true
            //     fetchCustomers(state.searchCustomersQuery)
            // }
            state.customersLoading = true
            fetchCustomers(state.searchCustomersQuery)
        },
        setCustomersQuery: (state, action) => {
            state.searchCustomersQuery = action.payload
            state.customersLoading = true
            fetchCustomers(state.searchCustomersQuery)
        },
    }
})

export const { setCustomersAction, getCustomers, setCustomersQuery, setCustomersLoading
} = customersSlice.actions

const fetchCustomers = (searchQuery: string) => {
    apolloClient.query({
        query: GQL_CUSTOMERS,
        fetchPolicy: 'network-only',
        variables: {
            searchQuery: searchQuery,
        }
    }
    ).then(response => {
        store.dispatch(setCustomersAction(response.data.customers))
        store.dispatch(setCustomersLoading(false))
    })
}

export default customersSlice.reducer