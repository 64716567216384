import { FC, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { IApiInfos } from '../interfaces';


interface IProps {
    apiInfos: IApiInfos,
}

export const EnvironmentFlag: FC<IProps> = (props) => {

    const [visible, setVisible] = useState<boolean>(false)
    const theme = useTheme()

    useEffect(() => {
        if (props.apiInfos.environment !== "?" && props.apiInfos.environment !== "production") {
            setVisible(true)
        } else {
            setVisible(false)
        }
    }, [props.apiInfos])

    return (
        <Box sx={{
            display: visible ? "flex" : "none",
            justifyContent: "center",
            position: "fixed",
            top: "20px",
            right: "-50px",
            backgroundColor: theme.palette.primary.main,
            color: "whitesmoke",
            px: 8,
            py: 1,
            transform: "rotate(45deg)",
            zIndex: 2000,
            width: "60px",
            ":hover": {
                cursor: "pointer",
            }
        }}
            onClick={() => setVisible(false)}
        >
            <Typography>
                {props.apiInfos.environment}
            </Typography>
        </Box>
    )
}


