import { FC, useState } from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import { TitleSection } from '../components/TitleSection';
import { KazOceaSpan } from '../components/KazOceaSpan';


import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { KazOceaList } from '../components/KazOceaList';
import { TherapistCard } from '../components/TherapistCard';
import { EnumKazOceaListMode, EnumTherapist, EnumTherapistMode, EnumTitleSectionMode } from '../enums';

interface IProps { }

export const WhereDescriptionSection: FC<IProps> = (props) => {

    const theme = useTheme()

    const listElements = [
        <>
            L'espace <Typography component="span" sx={{ color: theme.palette.primary.main, fontWeight: "700" }}>Sel marin</Typography> est occupé par <TherapistCard mode={EnumTherapistMode.badge} therapist={EnumTherapist.audreyMeese} />
        </>,
        <>
            L'espace <Typography component="span" sx={{ color: theme.palette.primary.main, fontWeight: "700" }}>Alizé</Typography> est partagé par <TherapistCard mode={EnumTherapistMode.badge} therapist={EnumTherapist.malikaFontaine} /> et <TherapistCard mode={EnumTherapistMode.badge} therapist={EnumTherapist.liseRousseliere} />
        </>,
        <>
            La salle <Typography component="span" sx={{ color: theme.palette.primary.main, fontWeight: "700" }}>Coquillage</Typography> est occupée par <TherapistCard mode={EnumTherapistMode.badge} therapist={EnumTherapist.valeriePayet} />
        </>,
        <>
            L'espace <Typography component="span" sx={{ color: theme.palette.primary.main, fontWeight: "700" }}>Embrun</Typography> partagé par <TherapistCard mode={EnumTherapistMode.badge} therapist={EnumTherapist.benedicteLeParc} /> et <TherapistCard mode={EnumTherapistMode.badge} therapist={EnumTherapist.marineDupeyron} />
        </>,
        <>
            L'espace <Typography component="span" sx={{ color: theme.palette.primary.main, fontWeight: "700" }}>Lagon</Typography> est partagé par <TherapistCard mode={EnumTherapistMode.badge} therapist={EnumTherapist.amandineCollard} />, <TherapistCard mode={EnumTherapistMode.badge} therapist={EnumTherapist.veroniqueFontaine} /> et <TherapistCard mode={EnumTherapistMode.badge} therapist={EnumTherapist.amandineBlache} />
        </>,
        <>
            L'espace <Typography component="span" sx={{ color: theme.palette.primary.main, fontWeight: "700" }}>Corail</Typography> est occupé par <TherapistCard mode={EnumTherapistMode.badge} therapist={EnumTherapist.vanessaLevy} /> et ses collaboratrices
        </>,
        <>
            La salle <Typography component="span" sx={{ color: theme.palette.primary.main, fontWeight: "700" }}>Ecume</Typography> est occupée par <TherapistCard mode={EnumTherapistMode.badge} therapist={EnumTherapist.francoisHoarau} />, <TherapistCard mode={EnumTherapistMode.badge} therapist={EnumTherapist.erwanLeblond} /> et <TherapistCard mode={EnumTherapistMode.badge} therapist={EnumTherapist.louisTiersonnier} />
        </>,
    ]


    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", my: 4 }}>
            <TitleSection title="Nous situer" mode={EnumTitleSectionMode.standard} />
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", textAlign: "justify", mx: 4 }}>
                <Typography>
                    Le centre thérapeutique Naissance et Famille <KazOceaSpan /> se situe au 93 Boulevard Hubert Delisle
                    à SAINT-PIERRE, au bord de la mer, en face de la gendarmerie.
                </Typography>
                <Typography sx={{ mt: 4 }}>
                    Différents espaces de soins sont attribués aux thérapeutes:
                </Typography>
                <KazOceaList elements={listElements} mode={EnumKazOceaListMode.standard} />
            </Box>
        </Box>
    )
}


