// import "../css/components/MetadocLogo.css"
import { FC } from "react"
import { EnumKazoceaLogoMode } from "../enums"
import { Box, SxProps, Typography, useTheme } from "@mui/material"



interface IProps {
    mode: EnumKazoceaLogoMode,
    sx: SxProps,
}


export const KazoceaLogo: FC<IProps> = (props) => {

    const theme = useTheme()

    const StandardVersion = () => <Box {...props}>
        <img src={`${process.env.PUBLIC_URL}/images/kazocea_logo.png`} width="100%" />
    </Box>
    const AccueilVersion = () =>
        <Box {...props}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={`${process.env.PUBLIC_URL}/images/logo_only.png`} width="100px" />
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <Typography sx={{ textTransform: "uppercase", fontFamily: theme.typography.secondary, color: theme.palette.primary.main, fontSize: 44, fontWeight: "light", lineHeight: 1 }}>
                            kaz
                        </Typography>
                        <Typography sx={{ textTransform: "uppercase", fontFamily: theme.typography.secondary, color: theme.palette.secondary.main, fontSize: 44, fontWeight: "bold", lineHeight: 1 }}>
                            océa
                        </Typography>

                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography sx={{ textTransform: "uppercase", letterSpacing: "-0.5px", fontFamily: theme.typography.secondary, color: theme.palette.secondary.main, fontSize: 10, fontWeight: "600" }}>
                            centre thérapeutique
                        </Typography>
                        <Typography sx={{ ml: .8, textTransform: "uppercase", letterSpacing: "-0.5px", fontFamily: theme.typography.secondary, color: theme.palette.primary.main, fontSize: 10, fontWeight: "600" }}>
                            naissance &amp; famille
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    const AccueilMobileVersion = () =>
        <Box {...props}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={`${process.env.PUBLIC_URL}/images/logo_only.png`} width="50px" />
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <Typography sx={{ textTransform: "uppercase", fontFamily: theme.typography.secondary, color: theme.palette.primary.main, fontSize: 22, fontWeight: "light", lineHeight: 1 }}>
                            kaz
                        </Typography>
                        <Typography sx={{ textTransform: "uppercase", fontFamily: theme.typography.secondary, color: theme.palette.secondary.main, fontSize: 22, fontWeight: "bold", lineHeight: 1 }}>
                            océa
                        </Typography>

                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography sx={{ textTransform: "uppercase", letterSpacing: "-0.5px", fontFamily: theme.typography.secondary, color: theme.palette.secondary.main, fontSize: 5, fontWeight: "600" }}>
                            centre thérapeutique
                        </Typography>
                        <Typography sx={{ ml: .4, textTransform: "uppercase", letterSpacing: "-0.5px", fontFamily: theme.typography.secondary, color: theme.palette.primary.main, fontSize: 5, fontWeight: "600" }}>
                            naissance &amp; famille
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    // const AccueilVersion = () => <div>
    //     <img src={`${process.env.PUBLIC_URL}/images/kazocea_logo.png`} />
    // </div>
    // const TinyVersion = () => <div>
    //     <img src={`${process.env.PUBLIC_URL}/images/kazocea_logo.png`} />
    // </div>
    const FooterVersion = () =>
        <Box {...props}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ backgroundColor: theme.palette.primary.main, borderRadius: "50%", borderWidth: "12px", borderColor: theme.palette.primary.main, borderStyle: "solid" }}>
                    <img src={`${process.env.PUBLIC_URL}/images/logo_only_white.png`} width="100px" />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: theme.palette.primary.main, mt: -2 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <Typography sx={{ textTransform: "uppercase", fontFamily: theme.typography.secondary, color: "white", fontSize: 44, fontWeight: "light", lineHeight: 1 }}>
                            kaz
                        </Typography>
                        <Typography sx={{ textTransform: "uppercase", fontFamily: theme.typography.secondary, color: "white", fontSize: 44, fontWeight: "bold", lineHeight: 1 }}>
                            océa
                        </Typography>

                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography sx={{ textTransform: "uppercase", letterSpacing: "-0.5px", fontFamily: theme.typography.secondary, color: "white", fontSize: 10, fontWeight: "600" }}>
                            centre thérapeutique
                        </Typography>
                        <Typography sx={{ ml: .8, textTransform: "uppercase", letterSpacing: "-0.5px", fontFamily: theme.typography.secondary, color: "white", fontSize: 10, fontWeight: "600" }}>
                            naissance &amp; famille
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box >



    const LogoOnlyVersion = () =>
        <Box {...props}>
            <img src={`${process.env.PUBLIC_URL}/images/logo_only.png`} width="100%" />
        </Box>

    return (
        <>
            {
                props.mode === EnumKazoceaLogoMode.standard && <StandardVersion />
            }
            {
                props.mode === EnumKazoceaLogoMode.accueil && <AccueilVersion />
            }
            {
                props.mode === EnumKazoceaLogoMode.accueilMobile && <AccueilMobileVersion />
            }
            {
                props.mode === EnumKazoceaLogoMode.footer && <FooterVersion />
            }
            {
                props.mode === EnumKazoceaLogoMode.logoOnly && <LogoOnlyVersion />
            }

        </>
    )

    // return (
    //     <div className={`MetadocLogo ${mode}`}>
    //         <img src={`${process.env.PUBLIC_URL}/images/metadoc_logo.png`} />
    //     </div>
    // )
}


