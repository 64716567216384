import { FC, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GQL_GET_API_INFOS } from "../graphql/Queries";
import { IApiInfos } from '../interfaces';
import { EnvironmentFlag } from './EnvironmentFlag';
import { versionInfos } from '../enums';


interface IProps { }

export const AdminFooter: FC<IProps> = (props) => {

    const [apiInfos, setApiInfos] = useState<IApiInfos>({
        apiVersion: "?",
        codeName: "?",
        coreVersion: "?",
        date: "?",
        environment: "?",
    })

    const theme = useTheme()

    // Pour ne pas refresh :
    const { error, loading, data } = useQuery(GQL_GET_API_INFOS)

    useEffect(() => {
        if (data) {
            setApiInfos({
                apiVersion: data.getApiInfos.apiVersion,
                codeName: data.getApiInfos.codeName,
                coreVersion: data.getApiInfos.coreVersion,
                date: data.getApiInfos.date,
                environment: data.getApiInfos.environment,
            })
        }
    }, [data])

    return (
        <>
            <EnvironmentFlag apiInfos={apiInfos} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: theme.palette.primary.main,
                    mt: 8,
                }}>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row", justifyContent: "center", alignItems: "center" } }}>
                    <Typography
                        component="span"
                        sx={{ fontFamily: theme.typography.secondary, fontSize: 16, fontWeight: "100", color: "white" }}>
                        Copyright &copy; 2022-{new Date().getFullYear()} kazocea.com by&nbsp;
                    </Typography>
                    <Typography
                        component="span"
                        title={`V ${versionInfos.webVersion} - core: ${apiInfos.coreVersion}(${apiInfos.codeName} - ${apiInfos.environment} - ${apiInfos.date}) - api: ${apiInfos.apiVersion}(expected: ${versionInfos.apiExpectedVersion})`}
                        sx={{ fontFamily: theme.typography.secondary, fontSize: 16, fontWeight: "100", color: "white" }}>
                        Médéric DE LAUNAY - Tous droits réservés
                    </Typography>
                </Box>
            </Box>
        </>
    )
}


