import { Box, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { KazoceaLogo } from '../components/KazoceaLogo'
import { EnumKazoceaLogoMode } from '../enums'

interface IProps {
}

export const Building: FC<IProps> = (props) => {

  const theme = useTheme()

  return (
    <div className="Building">
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", mx: 4 }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "400px" }}>
          <KazoceaLogo mode={EnumKazoceaLogoMode.standard} sx={{ width: "100%" }} />
        </Box>
        <Typography color="text.primary" sx={{ fontFamily: theme.typography.secondary, fontSize: 24 }}>
          Site en construction..
        </Typography>
      </Box>
    </div >
  )
}
