import { gql } from "@apollo/client"
import { GQL_FRAGMENT_CUSTOMER } from "./Fragments"

// Login
export const GQL_MUTATION_LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(input: {credentials: {email: $email, password: $password}}) {
      authToken
      user {
        id
        email
        firstname
        lastname
        userType
        admin
      }
      statusCode
      status
      errors
    }
  }
`

// Customers
export const GQL_MUTATION_CREATE_CUSTOMER = gql`
${GQL_FRAGMENT_CUSTOMER}
mutation createCustomer(
  $email: String,
    $firstname: String!,
    $lastname: String!,
    $enterprise: String!,
    ) {
  createCustomer(input: {
    email: $email
    firstname: $firstname
    lastname: $lastname
    enterprise: $enterprise
  }) {
    customer {
      ...Customer
    }
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_UPDATE_CUSTOMER = gql`
mutation updateCustomer(
    $customerId: ID!,
    $email: String,
    $firstname: String,
    $lastname: String,
    $enterprise: String,
    ) {
  updateCustomer(input: {
    customerId: $customerId
    email: $email
    firstname: $firstname
    lastname: $lastname
    enterprise: $enterprise
  }) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_CUSTOMER = gql`
mutation deleteCustomer($customerId: ID!) {
  deleteCustomer(input: {customerId: $customerId}) {
    statusCode
    errors
  }
}
`

// Reservations
export const GQL_MUTATION_CREATE_RESERVATION = gql`
mutation createReservation(
  $customerId: ID,
  $spaceIds: [ID!]!,
  $title: String!,
  $startAt: String!,
  $endAt: String!,
  $price: Float!,
  $state: EnumReservationState!,
  $reservationType: EnumReservationType!,
  ) {
  createReservation(input: {
    customerId: $customerId
    spaceIds: $spaceIds
    title: $title
    startAt: $startAt
    endAt: $endAt
    price: $price
    state: $state
    reservationType: $reservationType
  }) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_UPDATE_RESERVATION = gql`
mutation updateReservation(
  $reservationId: ID!,
  $customerId: ID,
  $spaceIds: [ID!]!,
  $title: String!,
  $startAt: String!,
  $endAt: String!,
  $price: Float!,
  $state: EnumReservationState!,
  ) {
  updateReservation(input: {
    reservationId: $reservationId
    customerId: $customerId
    spaceIds: $spaceIds
    title: $title
    startAt: $startAt
    endAt: $endAt
    price: $price
    state: $state
  }) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_RESERVATION = gql`
mutation deleteReservation($reservationId: ID!) {
  deleteReservation(input: {reservationId: $reservationId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DUPLICATE_RESERVATION = gql`
mutation duplicateReservation(
  $reservationId: ID!,
  $frequency: EnumDuplicateReservationFrequency!,
  $quantity: Int!,
  ) {
  duplicateReservation(input: {
    reservationId: $reservationId
    frequency: $frequency
    quantity: $quantity
  }) {
    statusCode
    errors
  }
}
`
