import { FC, useState } from 'react'
import { Avatar, Box, SxProps, Typography, useTheme } from "@mui/material";
import * as MuiCarousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import { red } from '@mui/material/colors';
import { WaveIcon } from './svg_icons/WaveIcon';
import { EnumTherapist, EnumTherapistMode } from '../enums';
import { NAVIGATE_THERAPIST_AMANDINE_BLACHE, NAVIGATE_THERAPIST_AMANDINE_COLLARD, NAVIGATE_THERAPIST_AUDREY_MEESE, NAVIGATE_THERAPIST_BENEDICTE_LE_PARC, NAVIGATE_THERAPIST_LISE_ROUSSELIERE, NAVIGATE_THERAPIST_MALIKA_FONTAINE, NAVIGATE_THERAPIST_MARINE_DUPEYRON, NAVIGATE_THERAPIST_VALERIE_PAYET, NAVIGATE_THERAPIST_VANESSA_LEVY, NAVIGATE_THERAPIST_VERONIQUE_FONTAINE } from '../utils/Navigation';
import { useNavigate } from 'react-router';
import { TherapistCard } from './TherapistCard';

interface IProps {
    currentTherapist: EnumTherapist,
    sx?: SxProps,
}

export const TherapistNavigation: FC<IProps> = (props) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const therapists = [
        EnumTherapist.audreyMeese,
        EnumTherapist.vanessaLevy,
        EnumTherapist.valeriePayet,
        EnumTherapist.malikaFontaine,
        EnumTherapist.liseRousseliere,
        EnumTherapist.benedicteLeParc,
        EnumTherapist.marineDupeyron,
        EnumTherapist.veroniqueFontaine,
        EnumTherapist.amandineCollard,
        EnumTherapist.amandineBlache,
        EnumTherapist.francoisHoarau,
        EnumTherapist.erwanLeblond,
        EnumTherapist.louisTiersonnier,
    ]

    const getPreviousTherapist = () => {
        return therapists[therapists.indexOf(props.currentTherapist) - 1]
    }

    const getNextTherapist = () => {
        return therapists[therapists.indexOf(props.currentTherapist) + 1]
    }

    return (
        <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "100%", ...props.sx }}>
            {
                props.currentTherapist !== therapists[0] &&
                <TherapistCard mode={EnumTherapistMode.badge} therapist={getPreviousTherapist()} previousIcon={true} />
            }
            {
                props.currentTherapist !== therapists[therapists.length - 1] &&
                <TherapistCard mode={EnumTherapistMode.badge} therapist={getNextTherapist()} nextIcon={true} />
            }
        </Box>
    )
}


