// import "../css/pages/Home.css"
import { Box } from '@mui/material'
import { FC } from 'react'
import { Layout } from "./Layout"

import { Carousel } from '../components/Carousel'
import { DescriptionSection } from '../sections/DescriptionSection'
import { TherapistsSection } from '../sections/TherapistsSection'
import { SpacesSection } from '../sections/SpacesSection'
import { SocialSection } from '../sections/SocialSection'

interface IProps {
}

export const Home: FC<IProps> = (props) => {

  const carouselItems = [
    {
      src: `${process.env.PUBLIC_URL}/images/carousel/carousel_006_hd.jpg`,
    },
    {
      src: `${process.env.PUBLIC_URL}/images/carousel/carousel_001_hd.jpg`,
    },
    // {
    //   src: `${process.env.PUBLIC_URL}/images/carousel/carousel_002_hd.jpg`,
    // },
    {
      src: `${process.env.PUBLIC_URL}/images/carousel/carousel_003_hd.jpg`,
    },
    // {
    //   src: `${process.env.PUBLIC_URL}/images/carousel/carousel_004_hd.jpg`,
    // },
    // {
    //   src: `${process.env.PUBLIC_URL}/images/carousel/carousel_005_hd.jpg`,
    // },

  ]

  return (
    <div className="Home">
      <Layout>
        <Box id="AccueilSection" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Carousel items={carouselItems} carouselSx={{ mt: { xs: 7, sm: 7, md: 12 } }} />
          <DescriptionSection />
          <TherapistsSection />
          <SpacesSection />
          <SocialSection />

        </Box>
      </Layout>
    </div >
  )
}
