import { FC, useState } from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import { TitleSection } from '../components/TitleSection';
import { KazOceaSpan } from '../components/KazOceaSpan';


import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { KazOceaList } from '../components/KazOceaList';
import { EnumKazOceaListMode, EnumTitleSectionMode } from '../enums';
import { CustomSpan } from '../components/CustomSpan';
import { DescriptionSubSection1 } from './DescriptionSubSection1';
import { SubtitleSection } from '../components/SubtitleSection';
import { Flexbox } from '../components/utils/Flexbox';
import { HighlightSpan } from '../components/HighlightSpan';

interface IProps { }

export const DescriptionSection: FC<IProps> = (props) => {

    const theme = useTheme()

    const listElements = [
        // <>
        //     Un lieu et une équipe qui puisent leur force de l’ <Typography component="span" sx={{ color: theme.palette.secondary.main, fontWeight: "700" }}>Océan</Typography>...
        // </>,
        <>
            Un lieu et une équipe qui puisent leur force de l’ <CustomSpan primaryPart='o' secondaryPart='céan' />...
        </>,
        <>
            Une maison où la <CustomSpan primaryPart='c' secondaryPart='ollaboration' /> et la <CustomSpan primaryPart='c' secondaryPart='réativité' /> s’expriment...
        </>,
        <>
            Une <CustomSpan primaryPart='é' secondaryPart='quipe' /> unie autour d’un même objectif...
        </>,
        <>
            Pour un <CustomSpan primaryPart='a' secondaryPart='ccompagnement' /> de la famille dès le projet de conception, et de tous, dès la naissance.
        </>
    ]

    return (
        <Box className='DescriptionSection' sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", my: 4 }}>
            <TitleSection title="Le centre thérapeutique du front de mer" mode={EnumTitleSectionMode.standard} />
            <DescriptionSubSection1 />
            <Flexbox sx={{ width: "100%", justifyContent: "flex-start" }}>
                <Flexbox sx={{ mx: 5, width: "100%", mb: 1 }}>
                    <SubtitleSection title="KAZ OCEA, une histoire de famille(s)" />
                </Flexbox>
            </Flexbox>
            <Grid container spacing={2} sx={{ mx: 4, display: "flex", alignItems: "center" }}>
                <Grid xs={12} sm={6} >
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", textAlign: "justify" }}>
                        <Typography>
                            <KazOceaSpan /> est un projet imaginé par deux sœurs, Pauline et Vanessa. Un rêve.
                            <br />
                            <br />
                            Celui de <HighlightSpan content="conjuguer les talents" /> de ce binôme fraternel et complémentaire depuis l’enfance. Combiner les savoir-faire en gestion et management de Pauline, gérante d’exploitations médico- sociales depuis 2009 à ceux de Vanessa, ostéopathe spécialisée en périnatalité et pédiatrie depuis 2007.
                            <br />
                            <br />
                            Celui de créer un espace qui <HighlightSpan content="réunit et fédère" /> des thérapeutes spécialisés dans l’accompagnement de la naissance, de la femme et de la famille à Saint-Pierre.
                            <br />
                            <br />
                            Celui de concevoir un <HighlightSpan content="lieu d'accueil et de bien-être" /> dans un cadre ressourçant, au bord de la mer, où elles ont grandi.
                            <br />
                            <br />
                            Celui de transformer une ancienne maison familiale en <HighlightSpan content="un espace de soin pluridisciplinaire... dédié à la famille !" />
                            <br />
                            <br />
                            <HighlightSpan content="Un rêve devenu réalité" /> avec la naissance de <KazOceaSpan />, en 2023.
                        </Typography>
                        <KazOceaList mode={EnumKazOceaListMode.standard} elements={listElements} iconSx={{ mt: .5 }} />
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                            <Typography component="span" sx={{ color: theme.palette.secondary.main, fontWeight: "700", px: 0.5 }}>O</Typography>
                            <Typography component="span" sx={{ color: theme.palette.primary.main, fontWeight: "700", px: 0.5, pb: 0.5 }}>+</Typography>
                            <Typography component="span" sx={{ color: theme.palette.secondary.main, fontWeight: "700", px: 0.5 }}>C</Typography>
                            <Typography component="span" sx={{ color: theme.palette.primary.main, fontWeight: "700", px: 0.5, pb: 0.5 }}>+</Typography>
                            <Typography component="span" sx={{ color: theme.palette.secondary.main, fontWeight: "700", px: 0.5, textTransform: "uppercase" }}>é</Typography>
                            <Typography component="span" sx={{ color: theme.palette.primary.main, fontWeight: "700", px: 0.5, pb: 0.5 }}>+</Typography>
                            <Typography component="span" sx={{ color: theme.palette.secondary.main, fontWeight: "700", px: 0.5 }}>A</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid container spacing={2} xs={12} sm={6}>
                    <Grid xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}>
                        <img src={`${process.env.PUBLIC_URL}/images/mozaic/mozaic_005.jpg`} width="100%" />
                    </Grid>
                    <Grid xs={12} md={6} >
                        <img src={`${process.env.PUBLIC_URL}/images/mozaic/mozaic_002.jpg`} width="100%" />
                    </Grid>
                    <Grid xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }} >
                        <img src={`${process.env.PUBLIC_URL}/images/mozaic/mozaic_006.jpg`} width="100%" />
                    </Grid>
                    <Grid xs={12} md={6} >
                        <img src={`${process.env.PUBLIC_URL}/images/mozaic/mozaic_007.jpg`} width="100%" />
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    )
}


