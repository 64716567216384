import { Box, useTheme } from '@mui/material';
import { FC, ReactElement } from 'react';
import { Footer } from '../../components/Footer';
import { ScrollToTop } from '../../components/ScrollToTop';
import { AdminAppBar } from '../../components/AdminAppBar';
import { Messages } from '../../components/messages/Messages';
import { AdminFooter } from '../../components/AdminFooter';


interface IProps {
  children: ReactElement | ReactElement[];
}

export const AdminLayout: FC<IProps> = (props) => {
  const theme = useTheme()

  return (
    <Box className="AdminLayout" sx={{
      backgroundColor: theme.palette.background.default,
      minHeight: "800px",
    }}>
      <AdminAppBar />
      <Messages />
      {props.children}
      <AdminFooter />
    </Box>
  )
}
