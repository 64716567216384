import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { Layout } from "../Layout";
import { WaveIcon } from '../../components/svg_icons/WaveIcon';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { TherapistNavigation } from '../../components/TherapistNavigation';
import { EnumTherapist } from '../../enums';


interface IProps {
}

export const LiseRousseliere: FC<IProps> = (props) => {

  const theme = useTheme()

  // A cause de React, faut forcer le scrollTo top sur les nouvelles pages.
  useEffect(() => {
    // window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Ajouter un comportement de défilement fluide
      });
    }, 500);
  }, [])

  return (
    <div>
      <Layout>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "800px",
          mx: "auto",
          pt: { xs: 10, md: 16 },
        }}>
          <Avatar
            alt="Lise ROUSSELIERE"
            src={`${process.env.PUBLIC_URL}/images/therapists/Lise_ROUSSELIERE_avatar.jpg`}
            sx={{
              width: "165px",
              height: "165px",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
            }}
          />
          <Typography sx={{ fontFamily: theme.typography.alternative, fontSize: 36, color: theme.palette.secondary.main, mt: 1 }}>
            Lise ROUSSELIERE
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Fasciathérapeute MDB, Sexothérapeute,
            <br />
            Accompagnement périnatal
            <br />
            Gymnastique sensorielle
          </Typography>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 4, mb: 2 }} />
          <Box sx={{ px: 2, mb: 2 }}>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              J'obtiens mon DIU en sexualité humaine en 2015, spécialité que j'ai découverte dans mes diverses formations paramédicales en me spécialisant en rééducation pelvienne. J'ai toujours été intéressée par l'humain et ce qui le compose. L'approche de la personne à travers cette formation m'ouvre de nouvelles portes pour être à l'écoute de mes patients et répondre avec bienveillance aux questionnements les plus intimes.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Dans mon parcours personnel, je rencontre la fasciathérapie méthode danis bois en 2016, qui est une évidence, elle vient toucher le corps dans la profondeur de l'être, et le sensible. Amener la personne à se rencontrer à travers le sensible, la guider pour l'amener à mieux se connaître. C'est ce qui peut définir mes objectifs de soins. Mon expérience personnelle et professionnelle viennent ensemble enrichir ma pratique. Les douleurs pelviennes, les douleurs chroniques, le vécu de traumatisme, l'accompagnement dans les maladies , l'accompagnement à la grossesse, l'accompagnement à l'éducation affective et sexuelle... ne sont que quelques exemples de ce que je peux proposer.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              En cours de formation jusqu'à 2024 de somato psychopédagogie, la gymnastique sensorielle vient compléter mes prises en charge, avec un outil complémentaire.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              Je vous propose deux formats de séances: 60 min à 70 euros ou 90 min à 90 euros.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              J'accueille en fasciathérapie et gymnastique sensorielle du nourrisson à sans limite d'âge.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              En sexothérapie les consultations sont ouvertes au couple ou personne seule, les moments de paroles aux parents, familles, adolescents, ou tout autre personnes en questionnements sur la sexualité sans forcément de problématique.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              Vous pouvez prendre rendez vous en me contactant directement au
              &nbsp;
              <Typography
                component="a"
                href='tel:0693320650'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                0693320650
              </Typography>
              &nbsp;
              ou sur
              &nbsp;
              <Typography
                component="a"
                href='https://www.resalib.fr/praticien/75674-rousseliere-lise-sexotherapeute-saint-pierre'
                target='blank'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                https://www.resalib.fr/praticien/75674-rousseliere-lise-sexotherapeute-saint-pierre.
              </Typography>
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 4, mb: 4 }}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/images/therapists/Lise_ROUSSELIERE_qr_code.png`}
                sx={{
                  width: { xs: "100px", sm: "200px" },
                  borderStyle: "solid",
                  borderWidth: 0,
                  borderColor: theme.palette.secondary.main,
                  // borderRadius: "24px",
                  mt: 1,
                }}
              />
            </Box>
          </Box>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 2, mb: 2 }} />
          <Typography sx={{ mt: -1, color: theme.palette.secondary.main }}>
            Espace Alizé
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ mx: 4, textAlign: "center" }}>
          <Grid xs={12} >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/images/spaces/alizé.jpg`}
              sx={{
                width: { xs: "250px", sm: "500px" },
                borderStyle: "solid",
                borderWidth: 2,
                borderColor: theme.palette.secondary.main,
                borderRadius: "24px",
                mt: 1,
              }}
            />
          </Grid>
        </Grid>
        <TherapistNavigation currentTherapist={EnumTherapist.liseRousseliere} sx={{ mt: 4 }} />
      </Layout>
    </div >
  )
}
