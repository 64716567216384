import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { Layout } from "../Layout";
import { WaveIcon } from '../../components/svg_icons/WaveIcon';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { EnumTherapist } from '../../enums';
import { TherapistNavigation } from '../../components/TherapistNavigation';
import { KazOceaSpan } from '../../components/KazOceaSpan';


interface IProps {
}

export const AmandineBlache: FC<IProps> = (props) => {

  const theme = useTheme()

  // A cause de React, faut forcer le scrollTo top sur les nouvelles pages.
  useEffect(() => {
    // window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Ajouter un comportement de défilement fluide
      });
    }, 500);
  }, [])

  return (
    <div>
      <Layout>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "800px",
          mx: "auto",
          pt: { xs: 10, md: 16 },
        }}>
          <Avatar
            alt="Amandine BLACHE"
            src={`${process.env.PUBLIC_URL}/images/therapists/Amandine_BLACHE_avatar.jpg`}
            sx={{
              width: "165px",
              height: "165px",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
            }}
          />
          <Typography sx={{ fontFamily: theme.typography.alternative, fontSize: 36, color: theme.palette.secondary.main, mt: 1 }}>
            Amandine BLACHE
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Art-thérapeute
          </Typography>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 4, mb: 2 }} />
          <Box sx={{ px: 2, mb: 2 }}>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Je suis art-thérapeute reconnue par l’Etat (Répertoire National des Certifications Professionnelles, niveau VI).
              <br />
              <br />
              L’art-thérapie est une pratique complémentaire à la médecine conventionnelle. Approche qui permet d’entendre chaque personne comme un être à part entière.
              <br />
              <br />
              Mon accompagnement ne demande aucune prédisposition artistique puisque la méthode art-thérapeutique s’intéresse au processus et non à l’oeuvre.
              <br />
              Dans cet espace propice à l'expression, la sensibilité artistique n’est donc pas nécessaire. Ce qui compte ici est de pouvoir déposer sa souffrance, se rencontrer autrement, libérer certaines tensions.
              <br />
              <br />
              Au centre <KazOceaSpan />, j’accueille en séance toute personne, âgée de 5 ans et plus, souhaitant aller vers un mieux-être et pour qui il peut être difficile de dire sa douleur, ses tourments.
              <br />
              <br />
              J’interviens aussi en institutions. Depuis bientôt 3 ans en clinique psychiatrique auprès d'adolescents et de jeunes adultes. J’ai également travaillé auprès d’enfants de 5 à 12 ans et de personnes âgées.
              <br />
              <br />
              Les entretiens et séances sont à 60 euros.
              <br />
              Les séances d’art-thérapie ne sont pas prises en charge par la Sécurité Sociale. Pensez à demander à votre mutuelle car certaines les remboursent en partie.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              Contactez-moi au
              &nbsp;
              <Typography
                component="a"
                href='tel:0693913976'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                06 93 91 39 76
              </Typography>
              &nbsp;
              ou par mail
              &nbsp;
              <Typography
                component="a"
                href="mailto:amandine.blache@artherapie.re"
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: 20,
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                }}>
                amandine.blache@artherapie.re
              </Typography>
            </Typography>
          </Box>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 2, mb: 2 }} />
          <Typography sx={{ mt: -1, color: theme.palette.secondary.main }}>
            Espace Lagon
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ mx: 4, textAlign: "center" }}>
          <Grid xs={12} >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/images/spaces/lagon_04.jpg`}
              sx={{
                width: { xs: "250px", sm: "500px" },
                borderStyle: "solid",
                borderWidth: 2,
                borderColor: theme.palette.secondary.main,
                borderRadius: "24px",
                mt: 1,
              }}
            />
          </Grid>
        </Grid>
        <TherapistNavigation currentTherapist={EnumTherapist.amandineBlache} sx={{ mt: 4 }} />
      </Layout>
    </div >
  )
}
