import { FC, useState } from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import { TitleSection } from '../components/TitleSection';
import { KazOceaSpan } from '../components/KazOceaSpan';


import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { KazOceaList } from '../components/KazOceaList';
import { EnumHighlightSpanMode, EnumKazOceaListMode, EnumTitleSectionMode } from '../enums';
import { CustomSpan } from '../components/CustomSpan';
import { Flexbox } from '../components/utils/Flexbox';
import { SubtitleSection } from '../components/SubtitleSection';
import { HighlightSpan } from '../components/HighlightSpan';

interface IProps { }

export const DescriptionSubSection1: FC<IProps> = (props) => {

    const listElements = [
        <>
            Des consultations individuelles auprès de <HighlightSpan mode={EnumHighlightSpanMode.alternative} content="nos thérapeutes" />.
        </>,
        <>
            <HighlightSpan mode={EnumHighlightSpanMode.alternative} content="Des évènements" /> organisés par l’équipe KAZ OCEA ou par des intervenants externes.
        </>,
        <>
            <HighlightSpan mode={EnumHighlightSpanMode.alternative} content="Des espaces en location" /> pour vos ateliers ou expositions autour des thématiques cœur du centre.
        </>,
    ]

    const theme = useTheme()

    return (
        <Flexbox direction='column' sx={{ width: "100%", justifyContent: "flex-start", alignItems: "flex-start", mb: 2 }}>
            <Flexbox direction='column' sx={{ justifyContent: "flex-start", alignItems: "flex-start", mx: 5 }}>
                <SubtitleSection title="Pour un accompagnement parental et périnatal" />
                <Typography sx={{ mb: 4 }}>
                    <KazOceaSpan /> est un espace de soin dédié à <HighlightSpan content='l’accompagnement de la famille, dès le projet de conception, et de tous, dès la naissance' />.
                    <br />
                    <br />
                    Cet espace créé dans un cadre ressourçant sur le front de mer à Saint Pierre de La Réunion, rassemble une <HighlightSpan content="équipe de thérapeutes pluridisciplinaires pour une prise en charge globale des patients" />.
                    <br />
                    <br />
                    L’interprofessionnalité de l’équipe permet de présenter, dans un même lieu, des solutions aux questionnements, troubles et problématiques physiques, mentaux, sociaux ou émotionnels touchant à la naissance, aux femmes et à la famille.
                </Typography>
            </Flexbox>
            <Flexbox direction='column' sx={{ justifyContent: "flex-start", alignItems: "flex-start", mx: 5 }}>
                <SubtitleSection title="Pour prendre soin de vous et de vos familles" />
                <Typography sx={{ mb: 2 }}>
                    <KazOceaSpan /> est un espace thérapeutique pour <HighlightSpan content="prendre soin de soi" />, (re)prendre confiance en soi et en son corps.
                    <br />
                    <br />
                    Nous vous accompagnons à toutes les étapes de votre vie et de votre parcours: conception, grossesse, naissance, vie familiale, parentalité. Ce sont des thématiques qui nous tiennent à coeur.
                </Typography>
            </Flexbox>
            <Flexbox direction='column' sx={{ justifyContent: "flex-start", alignItems: "flex-start", mx: 5 }}>
                <Typography>
                    <KazOceaSpan /> est un lieu-ressource qui vous propose:
                </Typography>
                <KazOceaList mode={EnumKazOceaListMode.standard} elements={listElements} iconSx={{ mt: .5 }} />
            </Flexbox>
        </Flexbox>
    )
}


