import { FC, useState } from 'react'
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import * as MuiCarousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import { red } from '@mui/material/colors';
import { WaveIcon } from './svg_icons/WaveIcon';
import { EnumSpaceReference, EnumTherapist } from '../enums';
import { KazOceaSpan } from './KazOceaSpan';
import { useNavigate } from 'react-router';
import { NAVIGATE_SPACE_TERRACE_PAGE, NAVIGATE_SPACE_WORKSHOP_PAGE } from '../utils/Navigation';

interface IProps {
    space: EnumSpaceReference,
}

export const SpaceCard: FC<IProps> = (props) => {
    const theme = useTheme()

    const navigate = useNavigate()

    const spaces = [
        {
            id: EnumSpaceReference.workshop,
            title: "L’espace Atelier Horizon",
            avatarSrc: `${process.env.PUBLIC_URL}/images/spaces/workshop.jpg`,
            pageUrl: `${NAVIGATE_SPACE_WORKSHOP_PAGE}?scrollTo=SpaceWorkshopSection`,
            description: <Box sx={{ textAlign: { xs: "justify", md: "inherit" }, fontFamily: theme.typography.primary }}>
                Cet espace de 22m² face à la mer et équipé d’une cuisine est disponible à la location en journée ou à la demi-journée, en semaine et le week end. Pour plus d’informations, contactez nous :
                &nbsp;
                <Typography component="a" href="mailto:contact@kazocea.com"
                    sx={{
                        color: theme.palette.secondary.main,
                        textDecoration: "none",
                        ":hover": { textDecoration: "underline" }
                    }}>
                    contact@kazocea.com
                </Typography>
            </Box>,
        },
        {
            id: EnumSpaceReference.terrace,
            title: "L’espace Terrasse",
            avatarSrc: `${process.env.PUBLIC_URL}/images/spaces/terrace.jpg`,
            pageUrl: `${NAVIGATE_SPACE_TERRACE_PAGE}?scrollTo=SpaceTerraceSection`,
            description: <Box sx={{ textAlign: { xs: "justify", md: "inherit" }, fontFamily: theme.typography.primary }}>
                <KazOceaSpan /> bénéficie d’une magnifique et spacieuse terrasse face à la mer, disponible à la location à des horaires spécifiques en semaine et le week end. Pour plus d’informations, contactez nous :
                &nbsp;
                <Typography component="a" href="mailto:contact@kazocea.com"
                    sx={{
                        color: theme.palette.secondary.main,
                        textDecoration: "none",
                        ":hover": { textDecoration: "underline" }
                    }}>
                    contact@kazocea.com
                </Typography>
            </Box>,
        },
    ]

    const getSpaceInfo = (infoType: string) => {
        const space = spaces.find(s => s.id === props.space);
        if (space) {
            switch (infoType) {
                case 'title':
                    return space.title;
                case 'avatarSrc':
                    return space.avatarSrc;
                case 'pageUrl':
                    return space.pageUrl;
                case 'description':
                    return space.description;
                default:
                    return `Erreur de détermination de l'attibut ${infoType}`;
            }
        }
        return `Erreur de détermination du space`;
    }


    const avatarSrc = getSpaceInfo("avatarSrc");
    const pageUrl = getSpaceInfo("pageUrl");

    return (
        <>
            {/* Desktop */}
            <Box className='SpaceCard'
                sx={{
                    display: { xs: "none", md: "flex" },
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: { xs: "360px", md: "800px", lg: "1024px" },
                    mx: "auto",
                    my: 2,
                    p: 2,
                    backgroundColor: "white",
                    borderRadius: "12px",
                    boxShadow: "5px 5px 5px 0px rgba(0, 0, 0, 0.25)",
                }}>

                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", px: 2 }}>
                    <img src={typeof avatarSrc === "string" ? avatarSrc : ""} width="180px" />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "space-between" }}>
                    <Typography sx={{ fontFamily: theme.typography.secondary, fontSize: 32, color: theme.palette.primary.main, mt: -1, mb: 0 }}>
                        {getSpaceInfo("title")}
                    </Typography>
                    {getSpaceInfo("description")}
                    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 2 }}>
                        <Button variant='contained'
                            size="small"
                            color="secondary"

                            onClick={() => navigate(typeof pageUrl === "string" ? pageUrl : "")}
                            sx={{
                                // my: 2,
                                fontFamily: theme.typography.secondary,
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                            }}
                        >
                            Voir la salle
                        </Button>
                    </Box>
                </Box>
            </Box>

            {/* Mobile */}
            <Box className='SpaceCard'
                sx={{
                    display: { xs: "flex", md: "none" },
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: { xs: "330px" },
                    mx: "auto",
                    my: 2,
                    p: 2,
                    backgroundColor: "white",
                    borderRadius: "12px",
                    boxShadow: "5px 5px 5px 0px rgba(0, 0, 0, 0.25)",
                }}>

                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", px: 2 }}>
                    <img src={typeof avatarSrc === "string" ? avatarSrc : ""} width="180px" />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                    <Typography sx={{ fontFamily: theme.typography.secondary, fontSize: 30, color: theme.palette.primary.main, mt: 1, mb: 1, textAlign: "center" }}>
                        {getSpaceInfo("title")}
                    </Typography>
                    {getSpaceInfo("description")}
                    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 2 }}>
                        <Button variant='contained'
                            size="small"
                            color="secondary"

                            onClick={() => navigate(typeof pageUrl === "string" ? pageUrl : "")}
                            sx={{
                                mt: 2,
                                fontFamily: theme.typography.secondary,
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                            }}
                        >
                            Voir la salle
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}


