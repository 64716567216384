import { Box, Button, TextField, Typography, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { KazoceaLogo } from '../../components/KazoceaLogo'
import { EnumKazoceaLogoMode, EnumMessageType } from '../../enums'
import { useMutation } from '@apollo/client'
import { GQL_MUTATION_LOGIN } from '../../graphql/Mutations'
import { addMessage } from "../../components/messages/Message"
import { useDispatch, useSelector } from 'react-redux'
import { setJwt } from '../../redux/features/global/apolloSlice'
import { useNavigate } from 'react-router'
import { NAVIGATE_ADMIN_PAGE } from '../../utils/Navigation'
import { IRootState } from '../../redux/store'
import { Messages } from '../../components/messages/Messages'

interface IProps {
}

export const AdminLoginPage: FC<IProps> = (props) => {
  const hasJwt = useSelector((state: IRootState) => state.apolloReducer.hasJwt)
  // const [email, setEmail] = useState("mederic.delaunay@gmail.com")
  // const [password, setPassword] = useState("totototo")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")


  useEffect(() => {
    if (hasJwt) {
      // Il y a un JWT, on redirect sur home..
      navigate(`${NAVIGATE_ADMIN_PAGE}`)
    }
  }, [])


  const [login, { data: loginData, loading: loginLoading, error: loginError }] = useMutation(GQL_MUTATION_LOGIN)

  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (loginError) {
      addMessage({
        location: "Login",
        type: EnumMessageType.Error,
        message: loginError.message,
      })
    } else if (loginData) {
      if (loginData.login.statusCode === 200) {
        dispatch(setJwt(loginData.login.authToken))
        // Refresh page: (redirect sur home au reload..)
        navigate(0)
        navigate(NAVIGATE_ADMIN_PAGE)
      } else {
        loginData.login.errors.map((error: string) => {
          addMessage({
            type: EnumMessageType.Error,
            message: error,
          })
        })
      }
    }
  }, [loginData, loginError])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login({
      variables: {
        email: email,
        password: password,
      }
    })
  };

  return (
    <div className="AdminLoginPage">
      <Messages />
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", mx: 4 }}>
        <KazoceaLogo mode={EnumKazoceaLogoMode.standard} sx={{ width: "200px" }} />
        <Typography color="text.primary" sx={{ fontFamily: theme.typography.secondary, fontSize: 24 }}>
          Connexion
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            color="primary"
            onChange={(event) => { setEmail(event.target.value) }}
            value={email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event) => { setPassword(event.target.value) }}
            value={password}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}
          >
            Se connecter
          </Button>
        </Box>
      </Box>
    </div >
  )
}
