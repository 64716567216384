import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { Layout } from "../Layout";
import { WaveIcon } from '../../components/svg_icons/WaveIcon';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { TherapistNavigation } from '../../components/TherapistNavigation';
import { EnumTherapist } from '../../enums';


interface IProps {
}

export const ValeriePayet: FC<IProps> = (props) => {

  const theme = useTheme()

  // A cause de React, faut forcer le scrollTo top sur les nouvelles pages.
  useEffect(() => {
    // window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Ajouter un comportement de défilement fluide
      });
    }, 500);
  }, [])

  return (
    <div>
      <Layout>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "800px",
          mx: "auto",
          pt: { xs: 10, md: 16 },
        }}>
          <Avatar
            alt="Valerie PAYET"
            src={`${process.env.PUBLIC_URL}/images/therapists/Valerie_PAYET_avatar.jpg`}
            sx={{
              width: "165px",
              height: "165px",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
            }}
          />
          <Typography sx={{ fontFamily: theme.typography.alternative, fontSize: 36, color: theme.palette.secondary.main, mt: 1 }}>
            Valerie PAYET
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Infirmière
          </Typography>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 4, mb: 2 }} />
          <Box sx={{ px: 2, mb: 2 }}>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Diplômée de l'Institut de Formation Théodore Simon de Neuilly-Sur-Marne (93), je suis revenue sur mon île après mes études où j'exerce la profession d'infirmière depuis 2007.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Installée en centre ville de Saint-Pierre depuis 2014, j'ai rejoint Kaz Océa dès son ouverture afin d'y poursuivre mon activité.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              Je reçois donc les patients qui le souhaitent au cabinet mais j'interviens également à domicile, essentiellement dans Saint-Pierre, uniquement sur rendez-vous et je suis joignable au
              &nbsp;
              <Typography
                component="a"
                href='tel:0692850455'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                0692 85 04 55
              </Typography>
            </Typography>
          </Box>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 2, mb: 2 }} />
          <Typography sx={{ mt: -1, color: theme.palette.secondary.main }}>
            Espace Coquillage
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ mx: 4, textAlign: "center" }}>
          <Grid xs={12} >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/images/spaces/coquillage.jpg`}
              sx={{
                width: { xs: "250px", sm: "500px" },
                borderStyle: "solid",
                borderWidth: 2,
                borderColor: theme.palette.secondary.main,
                borderRadius: "24px",
                mt: 1,
              }}
            />
          </Grid>
        </Grid>
        <TherapistNavigation currentTherapist={EnumTherapist.valeriePayet} sx={{ mt: 4 }} />
      </Layout>
    </div >
  )
}
