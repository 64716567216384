import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { Layout } from "../Layout";
import { WaveIcon } from '../../components/svg_icons/WaveIcon';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { TherapistNavigation } from '../../components/TherapistNavigation';
import { EnumTherapist } from '../../enums';
import { KazOceaSpan } from '../../components/KazOceaSpan';


interface IProps {
}

export const FrancoisHoarau: FC<IProps> = (props) => {

  const theme = useTheme()

  // A cause de React, faut forcer le scrollTo top sur les nouvelles pages.
  useEffect(() => {
    // window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Ajouter un comportement de défilement fluide
      });
    }, 500);
  }, [])

  return (
    <div>
      <Layout>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "800px",
          mx: "auto",
          pt: { xs: 10, md: 16 },
        }}>
          <Avatar
            alt="François HOARAU"
            src={`${process.env.PUBLIC_URL}/images/therapists/Francois_HOARAU_avatar.jpg`}
            sx={{
              width: "165px",
              height: "165px",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
            }}
          />
          <Typography sx={{ fontFamily: theme.typography.alternative, fontSize: 36, color: theme.palette.secondary.main, mt: 1 }}>
            Francois HOARAU
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Thérapie manuelle
            <br />
            Micronutrition
          </Typography>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 4, mb: 2 }} />
          <Box sx={{ px: 2, mb: 2 }}>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Médecin généraliste de formation initiale , j'exerce à Saint Pierre depuis plus de 25 ans.
              <br />
              J'ai complété cet acquis durant ces années par des approches complémentaires qui me sont devenues nécessaires à une bonne pratique de la médecine générale.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              J'ai suivi un cursus ostéopathique et je suis diplômé de la « maison de la thérapie manuelle »
              <br />
              Je me suis ensuite formé par le biais de plusieurs stages complémentaires d'ostéopathie.
              <br />
              J'ai intégré également à cette base des concepts de la médecine chinoise et indienne.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              J'ai validé le diplôme inter-universitaire de Micronutrition qui m'a ouvert à la compréhension de ce qu'une approche nutritionnelle fine, bien documentée biologiquement , synthétique, peut apporter dans la prise en charge de très nombreuses pathologies. Elle permet souvent de faire des liens transversaux entre les spécialités médicales parfois cloisonnées pour proposer des traitements nutritionnels à certaines pathologies lourdes.
            </Typography>

            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              Exercer à <KazOceaSpan /> offre des conditions agréables de consultation dans un cadre inspirant.
              <br />
              Vous pourrez me consulter dans la salle Ecume
            </Typography>


            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              Vous pouvez prendre rendez vous sur le site
              &nbsp;
              <Typography
                component="a"
                href='https://www.resalib.fr/praticien/93114-francois-hoarau-conseiller-en-nutrition-saint-pierre'
                target='blank'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                https://www.resalib.fr/praticien/93114-francois-hoarau-conseiller-en-nutrition-saint-pierre
              </Typography>
              &nbsp;
              en sélectionnant mon agenda ou laisser un texto au numéro
              &nbsp;
              <Typography
                component="a"
                href='tel:0692259649'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                0692 25 96 49
              </Typography>
            </Typography>
          </Box>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 2, mb: 2 }} />
          <Typography sx={{ mt: -1, color: theme.palette.secondary.main }}>
            Espace Ecume
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ mx: 4, textAlign: "center" }}>
          <Grid xs={12} >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/images/spaces/ecume.jpg`}
              sx={{
                width: { xs: "250px", sm: "500px" },
                borderStyle: "solid",
                borderWidth: 2,
                borderColor: theme.palette.secondary.main,
                borderRadius: "24px",
                mt: 1,
              }}
            />
          </Grid>
        </Grid>
        <TherapistNavigation currentTherapist={EnumTherapist.francoisHoarau} sx={{ mt: 4 }} />
      </Layout>
    </div >
  )
}
