import { FC } from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { TitleSection } from '../components/TitleSection';
import { KazOceaSpan } from '../components/KazOceaSpan';


import { KazOceaList } from '../components/KazOceaList';
import { TherapistCard } from '../components/TherapistCard';
import { EnumKazOceaListMode, EnumTherapist, EnumTherapistMode } from '../enums';

interface IProps { }

export const GeolocationSection: FC<IProps> = (props) => {

    const theme = useTheme()


    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: { xs: "360px", sm: "580px", md: "880px", lg: "1160px", xl: "1400px" },
            height: { xs: "600px" },
            my: 4,
        }}>
            {/* <TitleSection title="Nous situer" /> */}
            {/* <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}> */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d29729.69083342062!2d55.464477!3d-21.342157!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDIwJzMxLjgiUyA1NcKwMjcnNTIuMSJF!5e0!3m2!1sfr!2sus!4v1691756617852!5m2!1sfr!2sus"
                style={{ borderStyle: "solid", borderWidth: "2px", borderRadius: "12px", borderColor: theme.palette.primary.main, width: "100%", height: "100%" }} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            {/* </Box> */}
        </Box >
    )
}


