import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { Layout } from "../Layout";
import { WaveIcon } from '../../components/svg_icons/WaveIcon';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { TherapistNavigation } from '../../components/TherapistNavigation';
import { EnumTherapist } from '../../enums';


interface IProps {
}

export const MalikaFontaine: FC<IProps> = (props) => {

  const theme = useTheme()

  // A cause de React, faut forcer le scrollTo top sur les nouvelles pages.
  useEffect(() => {
    // window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Ajouter un comportement de défilement fluide
      });
    }, 500);
  }, [])

  return (
    <div>
      <Layout>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "800px",
          mx: "auto",
          pt: { xs: 10, md: 16 },
        }}>
          <Avatar
            alt="Malîka FONTAINE"
            src={`${process.env.PUBLIC_URL}/images/therapists/Malika_FONTAINE_avatar.jpg`}
            sx={{
              width: "165px",
              height: "165px",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
            }}
          />
          <Typography sx={{ fontFamily: theme.typography.alternative, fontSize: 36, color: theme.palette.secondary.main, mt: 1 }}>
            Malîka FONTAINE
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Fasciathérapeute MDB, Micronutrition, Accompagnement périnatal, Gymnastique sensorielle
          </Typography>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 4, mb: 2 }} />
          <Box sx={{ px: 2, mb: 2 }}>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mb: 1 }}>
              À Propos de Moi
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Passionnée par le bien-être holistique, profondément connectée à l'expérience humaine, je me suis reconvertie en tant que profession paramédicale, il y a maintenant dix ans, après avoir obtenu un Master en Urbanisme.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Toujours curieuse, je ressentais le besoin de comprendre la personne dans sa globalité, au-delà de ses symptômes.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              J'ai commencé par obtenir un Diplôme Universitaire en Micronutrition pour aborder les aspects nutritionnels. Chaque année, je poursuis ma formation pour approfondir mes connaissances et mieux accompagner mes patients.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Toujours dans une approche Holistique, je me suis formée en Thérapie Cognitive Basée sur la Pleine Conscience (MBCT) pour gérer le stress et l'anxiété.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Puis j'ai découvert la Fasciathérapie. La Fasciathérapie a enrichi et mis du sens dans ma pratique en lui apportant une nouvelle perspective.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Cette méthode me permet de me connecter à l'individu dans sa globalité et de l'accompagner dans son cheminement.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mt: 4, mb: 1 }}>
              Qu'est-ce que la Fasciathérapie ?
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              La Fasciathérapie est une thérapie manuelle et gestuelle douce et non invasive développée par Danis Bois dans les années 1980.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Elle prend en compte la connexion corps-esprit et favorise la conscience corporelle.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Les avantages incluent le soulagement de la douleur, l'amélioration de la flexibilité, la réduction du stress, la clarté mentale et un sentiment de bien-être.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mt: 4, mb: 1 }}>
              Séances de Fasciathérapie
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Des séances personnalisées adaptées à vos besoins, combinant à la fois de la thérapie manuelle et gestuelle.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Mon engagement est de vous accueillir dans un espace sûr, respectueux et exempt de jugement.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              La première séance d'évaluation (90 minutes) pour discuter de vos problématiques, de vos objectifs et du plan de traitement (90 &euro;).
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mt: 4, mb: 1 }}>
              Qui peut bénéficier de la Fasciathérapie ?
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Convient à tout le monde, en particulier à ceux qui luttent contre l'anxiété, la douleur chronique ou aiguë, les problèmes abdominaux ou uro-gynécologiques. La méthode fournit un soutien pendant les périodes de chimiothérapie, de grossesse.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mt: 4, mb: 1 }}>
              Réservez Votre Séance
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Planifiez votre séance facilement via
              &nbsp;
              <Typography
                component="a"
                href='https://www.resalib.fr/praticien/33321-fasciatherapie-mdb-kaz-ocea-fasciatherapeute-saint-pierre'
                target='blank'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                RESALIB
              </Typography>
              &nbsp;
              ou contactez-moi au
              &nbsp;
              <Typography
                component="a"
                href='tel:0692555613'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                0692555613
              </Typography>
              &nbsp;
              (les lundis et mercredis matins) ou par SMS au
              &nbsp;
              <Typography
                component="a"
                href='tel:0692118212'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                0692118212
              </Typography>
              .
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mt: 4, mb: 1 }}>
              Localisation
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Rendez-vous à l'espace bien-être Alizée pour vos séances.
            </Typography>
          </Box>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 2, mb: 2 }} />
          <Typography sx={{ mt: -1, color: theme.palette.secondary.main }}>
            Espace Alizé
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ mx: 4, textAlign: "center" }}>
          <Grid xs={12} >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/images/spaces/alizé.jpg`}
              sx={{
                width: { xs: "250px", sm: "500px" },
                borderStyle: "solid",
                borderWidth: 2,
                borderColor: theme.palette.secondary.main,
                borderRadius: "24px",
                mt: 1,
              }}
            />
          </Grid>
        </Grid>
        <TherapistNavigation currentTherapist={EnumTherapist.malikaFontaine} sx={{ mt: 4 }} />
      </Layout>
    </div >
  )
}
