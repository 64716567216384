import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { ICustomer } from '../../interfaces';
import { Flexbox } from '../utils/Flexbox';

interface IProps {
    open: boolean;
    customer: ICustomer;
    handleClose: () => void;
    handleConfirm: (customer: ICustomer) => void;
}

export const UpdateCustomerDialog: FC<IProps> = (props) => {

    const initialState: ICustomer = props.customer
    var stateToSend: ICustomer = initialState

    const [email, setEmail] = useState<string>(initialState.email)
    const [firstname, setFirstname] = useState<string>(initialState.firstname)
    const [lastname, setLastname] = useState<string>(initialState.lastname)
    const [enterprise, setEnterprise] = useState<string>(initialState.enterprise)

    const prepareStateToSend = () => {
        stateToSend = {
            ...initialState,
            email,
            firstname,
            lastname,
            enterprise,
        }
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        prepareStateToSend()
        props.handleConfirm(stateToSend)
    }

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth
            >
                <Box component="form" onSubmit={handleConfirm}>
                    <DialogTitle color="primary">
                        Modifier le locataire
                    </DialogTitle>
                    <DialogContent>
                        <Flexbox direction='column'
                            sx={{
                                marginTop: 1,
                            }}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Email"
                                autoFocus
                                color="primary"
                                onChange={(event) => { setEmail(event.target.value) }}
                                value={email}
                                autoComplete='off'
                                type="email"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Prénom"
                                color="primary"
                                onChange={(event) => { setFirstname(event.target.value) }}
                                value={firstname}
                                autoComplete='off'
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Nom de famille"
                                color="primary"
                                onChange={(event) => { setLastname(event.target.value) }}
                                value={lastname}
                                autoComplete='off'
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Entreprise"
                                color="primary"
                                onChange={(event) => { setEnterprise(event.target.value) }}
                                value={enterprise}
                                autoComplete='off'
                            />
                        </Flexbox>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.handleClose} variant="outlined" color="primary">Annuler</Button>
                        <Button type='submit' variant="contained" color="secondary">
                            Modifier
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}
