import { FC } from 'react'
import { ISpace } from '../../interfaces'
import { Flexbox } from '../utils/Flexbox'
import { Box } from '@mui/material'
import { getDaySlots, getReservationsOfDate } from '../../utils/helpers'
import { SpacePlanningReservationSlot } from './SpacePlanningReservationSlot'
import { SpacePlanningEmptySlot } from './SpacePlanningEmptySlot'
import { EnumPlanningBy } from '../../enums'
import { Dayjs } from "dayjs"
import 'dayjs/locale/fr'


interface IProps {
    planningBy: EnumPlanningBy,
    date: Dayjs,
    startHour: number,
    endHour: number,
    hourHeight: number,
    minuteHeight: number,
    columnHeaderHeight: number,
    space: ISpace,
}

export const SpacePlanningColumn: FC<IProps> = (props) => {
    const reservationsOfDate = getReservationsOfDate(props.space.reservations, props.date)
    return (
        <>
            <Flexbox direction='column'
                sx={{
                    width: "100%",
                }}>
                {
                    getDaySlots(reservationsOfDate, props.date, props.startHour, props.endHour).map((daySlot, index) => <Box key={index} sx={{ width: "100%" }}>
                        {
                            (daySlot.interfaceType === "IReservationSlot")
                                ?
                                <SpacePlanningReservationSlot space={props.space} key={`${props.date.toString()}-${index}`} planningBy={props.planningBy} reservation={daySlot.reservation} hourHeight={props.hourHeight} minuteHeight={props.minuteHeight} />
                                :
                                <SpacePlanningEmptySlot date={props.date} space={props.space} key={`${props.date.toString()}-${index}`} emptySlot={daySlot} hourHeight={props.hourHeight} minuteHeight={props.minuteHeight} />
                        }
                    </Box>
                    )
                }
            </Flexbox>
        </>
    )
}
