import { configureStore } from "@reduxjs/toolkit"
import globalReducer from "./features/global/globalSlice"
import messagesReducer from "./features/global/messagesSlice"
import apolloReducer from "./features/global/apolloSlice"
import currentUserReducer from "./features/users/currentUserSlice"
import customersReducer from "./features/customers/customersSlice"
import spaceReducer from "./features/spaces/spaceSlice"


export const store = configureStore({
    reducer: {
        globalReducer,
        messagesReducer,
        apolloReducer,
        currentUserReducer,
        customersReducer,
        spaceReducer,
    }
})

export type IRootState = ReturnType<typeof store.getState>

// export const setAllRefetchNeeded = (from: string) => {
//     console.log("setAllRefetchNeeded called from : ", from);

//     store.dispatch(setCurrentUserRefetchNeeded(true))
//     // store.dispatch(setCustomersRefetchNeeded(true))
//     store.dispatch(getCustomers())
// }
