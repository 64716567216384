import { Box, Button, Divider, Link, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { AdminLayout } from './AdminLayout'
import { WaveIcon } from '../../components/svg_icons/WaveIcon'
import { NAVIGATE_ADMIN_CUSTOMERS_PAGE, NAVIGATE_ADMIN_SPACES_PAGE, NAVIGATE_SPACE_WORKSHOP_RULES_PAGE } from '../../utils/Navigation'
import { useNavigate } from 'react-router'
import { Flexbox } from '../../components/utils/Flexbox'
import { KazoceaLogo } from '../../components/KazoceaLogo'
import { EnumKazoceaLogoMode } from '../../enums'
import { CopyToClipboard } from '../../components/utils/CopyToClipboard'

interface IProps {
}

export const AdminPage: FC<IProps> = (props) => {

  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <div className="AdminPage">
      <AdminLayout>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <KazoceaLogo mode={EnumKazoceaLogoMode.logoOnly} sx={{ width: "100px", mt: 4 }} />
          <WaveIcon color="primary" sx={{ width: "200px", my: 4 }} />
          <Flexbox direction='column' sx={{
            justifyContent: "space-around",
            // width: "100%",
          }}
          >
            <Button variant='text' color='secondary' onClick={() => navigate(NAVIGATE_ADMIN_CUSTOMERS_PAGE)}>
              Locataires
            </Button>
            <Button variant='text' color='secondary' onClick={() => navigate(NAVIGATE_ADMIN_SPACES_PAGE)}>
              Planning
            </Button>

            <Divider sx={{ width: "100%", my: 2 }} />

            <Flexbox>
              <Button variant='text'
                color="primary"
                href={NAVIGATE_SPACE_WORKSHOP_RULES_PAGE}
                target="_blank"
              >
                Gestion des ateliers
              </Button>

              <CopyToClipboard
                text={`${window.location.origin}${NAVIGATE_SPACE_WORKSHOP_RULES_PAGE}`}
                message="Lien copié."
              />

            </Flexbox>

          </Flexbox>
        </Box>
      </AdminLayout>
    </div >
  )
}
