// import "../css/pages/Home.css"
import { Box } from '@mui/material'
import { FC } from 'react'
import { Layout } from "./Layout"

import { Carousel } from '../components/Carousel'
import { DescriptionSection } from '../sections/DescriptionSection'
import { TherapistsSection } from '../sections/TherapistsSection'
import { SpacesSection } from '../sections/SpacesSection'
import { WhereDescriptionSection } from '../sections/WhereDescriptionSection'
import { GeolocationSection } from '../sections/GeolocationSection'
import { PatientRulesSection } from '../sections/PatientRulesSection'

interface IProps {
}

export const WherePage: FC<IProps> = (props) => {

  return (
    <div className="WherePage">
      <Layout>
        <Box id="WhereSection" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", mt: { xs: 7, sm: 7, md: 12 } }}>
          <WhereDescriptionSection />
          <GeolocationSection />
        </Box>
        <Box id="PatientRulesSection" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", mt: 4 }}>
          <PatientRulesSection />
        </Box>
      </Layout>
    </div >
  )
}
