import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FC } from 'react';

interface IProps {
    open: boolean;
    title: string;
    content: string;
    handleClose: () => void;
    handleConfirm: () => void;

}

export const DeleteConfirm: FC<IProps> = (props) => {
    return (
        <div className="DeleteConfirm">
            <Dialog
                open={props.open}
                onClose={props.handleClose}
            >
                <DialogTitle color="error">
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText color="error">
                        {props.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} variant="outlined" color="primary">Annuler</Button>
                    <Button onClick={props.handleConfirm} variant="contained" color="error">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
