import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import { IPlanningShowFilter } from "../../../interfaces";
import { EnumPlanningBy } from "../../../enums";



interface IGlobalSliceState {
    datePlanning: string,
    planningBy: EnumPlanningBy,
    planningShowFilter: IPlanningShowFilter,
    // spacesInfos: ISpaceInfos[],
}

const initialState: IGlobalSliceState = {
    datePlanning: dayjs().locale('fr').format(),
    planningBy: EnumPlanningBy.week,
    planningShowFilter: {
        spaceWorkshop: true,
        spaceTerrace: true,
    },
    // spacesInfos: [],
}

export const globalSlice = createSlice({
    name: "globalSlice",
    initialState,
    reducers: {
        setDatePlanningAction: (state, action) => {
            // state.datePlanning = getBeginningOfTheWeek(action.payload).format()
            state.datePlanning = action.payload
        },
        setPlanningByAction: (state, action) => {
            state.planningBy = action.payload
        },
        setPlanningShowFilter: (state, action) => {
            state.planningShowFilter = action.payload
        },
    }
})

export const {
    setDatePlanningAction,
    setPlanningByAction,
    setPlanningShowFilter,
} = globalSlice.actions

export default globalSlice.reducer


