import { Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Flexbox } from './utils/Flexbox';


interface IProps {
  title: string,
  case01: string,
  case01_2?: string,
  case01_3?: string,
  case02: React.JSX.Element | string,
  case03?: string,
  case04?: React.JSX.Element | string,

}

export const SmallTable: FC<IProps> = (props) => {

  const theme = useTheme()

  return (
    <Flexbox direction='column' sx={{ alignItems: "flex-start", mt: 2 }}>
      <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.primary.main, mt: 2, mb: 0 }}>
        {props.title}
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1, mb: 2 }} >
        <Grid xs={8} sx={{
          borderBottomStyle: props.case03 ? "solid" : "unset",
          borderRightStyle: "solid",
          borderWidth: 1,
          borderColor: theme.palette.grey[300],
        }}>
          <Typography>
            {props.case01}
          </Typography>
          {props.case01_2 && <Typography sx={{
            mt: 1,
            pt: 1,
            borderTopStyle: "solid",
            borderWidth: 1,
            borderColor: theme.palette.grey[300],
          }}>
            {props.case01_2}
          </Typography>
          }
          {props.case01_3 && <Typography sx={{
            mt: 1,
            pt: 1,
            borderTopStyle: "solid",
            borderWidth: 1,
            borderColor: theme.palette.grey[300],
          }}>
            {props.case01_3}
          </Typography>
          }

        </Grid>
        <Grid xs={4} sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottomStyle: props.case03 ? "solid" : "unset",
          borderWidth: 1,
          borderColor: theme.palette.grey[300],
        }}>
          <Typography sx={{ textAlign: "center" }}>
            {props.case02}
          </Typography>
        </Grid>
        {
          props.case03 && <>
            <Grid xs={8} sx={{
              borderRightStyle: "solid",
              borderWidth: 1,
              borderColor: theme.palette.grey[300],
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}>
              <Typography>
                {props.case03}
              </Typography>

            </Grid>
            <Grid xs={4} sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderWidth: 1,
              borderColor: theme.palette.grey[300],
            }}>
              <Typography>
                {props.case04}
              </Typography>
            </Grid>
          </>
        }

      </Grid>
    </Flexbox>
  )
}
