import { FC, useEffect, useState } from 'react'
import { IEmptySlot, IReservationPrototype, ISpace } from '../../interfaces'
import { Flexbox } from '../utils/Flexbox'
import { Typography, useTheme } from '@mui/material'
import { getDayjsFormat, getEmptySlotHeight, getReservationsOfDate, isWeekend } from '../../utils/helpers'
import 'dayjs/locale/fr'
import { CreateReservationDialog } from './CreateReservationDialog'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../redux/store'
import { useMutation } from '@apollo/client'
import { GQL_MUTATION_CREATE_RESERVATION } from '../../graphql/Mutations'
import { addMessage } from '../messages/Message'
import { EnumDayjsFormat, EnumMessageType, EnumSpaceReference } from '../../enums'
import { getSpaces } from '../../redux/features/spaces/spaceSlice'
import { Dayjs } from "dayjs"
import 'dayjs/locale/fr'


interface IProps {
    emptySlot: IEmptySlot,
    hourHeight: number,
    minuteHeight: number,
    space: ISpace,
    date: Dayjs,
}

export const SpacePlanningEmptySlot: FC<IProps> = (props) => {
    const [showCreateReservationDialog, setShowCreateReservationDialog] = useState(false)
    const [createReservation, { data: createReservationData, loading: createReservationLoading, error: createReservationError }] = useMutation(GQL_MUTATION_CREATE_RESERVATION)

    const theme = useTheme()
    const dispatch = useDispatch()
    // const borderTopWidth = (getEmptySlotHeight(props.emptySlot, props.minuteHeight) / props.hourHeight)
    // const height = getEmptySlotHeight(props.emptySlot, props.minuteHeight)
    const borderTopWidth = 1
    const height = getEmptySlotHeight(props.emptySlot, props.minuteHeight) + (getEmptySlotHeight(props.emptySlot, props.minuteHeight) / props.hourHeight) - borderTopWidth


    const closeCreateReservationDialog = () => {
        setShowCreateReservationDialog(false)
    }

    const confirmCreateReservationDialog = (reservationPrototype: IReservationPrototype) => {
        createReservation({
            // variables: reservationPrototype
            variables: {
                customerId: reservationPrototype.customer?.id,
                // spaceId: space.id,
                // spaceReferences: spaceReferences,
                spaceIds: reservationPrototype.spacesCheckedIds,
                title: reservationPrototype.title,
                startAt: reservationPrototype.startAt,
                endAt: reservationPrototype.endAt,
                price: parseFloat(reservationPrototype.price),
                state: reservationPrototype.state,
                reservationType: reservationPrototype.reservationType,
            }
        })
        setShowCreateReservationDialog(false)
    }
    useEffect(() => {
        if (createReservationError) {
            addMessage({
                // location: "SpacePlanningEmptySlot",
                type: EnumMessageType.Error,
                message: createReservationError.message,
            })
        } else if (createReservationData) {
            if (createReservationData.createReservation.statusCode === 200) {
                dispatch(getSpaces())
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Réservation enregistrée.",
                })
            } else {
                createReservationData.createReservation.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createReservationData, createReservationError])

    return (
        <>
            <Flexbox
                sx={{
                    borderTopStyle: "solid",
                    borderTopColor: theme.palette.background.default,
                    borderTopWidth: borderTopWidth,
                    borderRightStyle: "dashed",
                    borderRightColor: theme.palette.primary.main,
                    borderRightWidth: 1,
                    height: height,
                    // width: "100%",
                    borderRadius: 1,
                    backgroundColor: isWeekend(props.date) ? theme.palette.grey[400] : "inherit",
                    ":hover": {
                        cursor: "pointer",
                        // backgroundColor: theme.palette.primary.light,
                        backgroundColor: theme.palette.grey[300],
                    }
                }}
                onClick={() => setShowCreateReservationDialog(true)}
            >
                <></>
            </Flexbox>
            <CreateReservationDialog
                open={showCreateReservationDialog}
                handleClose={closeCreateReservationDialog}
                handleConfirm={confirmCreateReservationDialog}
                space={props.space}
                emptySlot={props.emptySlot}
            />
        </>
    )
}
