import { FC, useEffect, useState } from 'react';
import { Box, TextField, Typography, useTheme } from "@mui/material";
import { Flexbox } from '../utils/Flexbox';
import SearchIcon from '@mui/icons-material/Search';
import { Spinner } from '../Spiner';
import { getCustomers, setCustomersQuery } from '../../redux/features/customers/customersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../redux/store';
import { CustomerSelectionItem } from './CustomerSelectionItem';
import { ICustomer } from '../../interfaces';

interface IProps {
    handleConfirm: (customer: ICustomer) => void;
}

export const ListCustomersSelection: FC<IProps> = (props) => {

    const customers: ICustomer[] = useSelector((state: IRootState) => state.customersReducer.customers)
    const customersLoading: boolean = useSelector((state: IRootState) => state.customersReducer.customersLoading)
    // const [searchCustomersQuery, setSearchCustomersQuery] = useState<string>("")
    const [searchCustomersQuery, setSearchCustomersQuery] = useState<string>(useSelector((state: IRootState) => state.customersReducer.searchCustomersQuery))

    const theme = useTheme()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCustomers())
    }, [])

    const searchCustomers = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(setCustomersQuery(searchCustomersQuery))
    }

    return (
        <>
            <Flexbox direction='column' sx={{
            }}>
                <Flexbox sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    pb: 1,
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                    borderBottomColor: theme.palette.primary.main,
                }}>
                    <Box component="form" onSubmit={searchCustomers} noValidate
                        sx={{
                            width: "100%"
                        }}
                    >
                        <TextField
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <SearchIcon sx={{ mr: 1, my: 0.5 }} />
                                    <Typography>Rechercher..</Typography>
                                </Box>
                            }
                            color="secondary"
                            size="small"
                            variant="standard"
                            onChange={(event) => setSearchCustomersQuery(event.target.value)}
                            value={searchCustomersQuery}
                            sx={{
                                "div::before": { borderBottomStyle: "none !important" },
                                "div::after": { borderBottomStyle: "none !important" },
                                width: "100%",
                            }}
                        />
                    </Box>
                </Flexbox>
                <Flexbox direction='column' sx={{ width: "100%" }}>
                    {
                        customersLoading ? <Spinner /> : <>
                            {
                                customers !== undefined && customers.map((customer, index) => {
                                    return (
                                        <CustomerSelectionItem key={customer.id} customer={customer} handleConfirm={props.handleConfirm} sx={{ py: 1 }} />
                                    )
                                })
                            }
                        </>
                    }
                </Flexbox>
            </Flexbox>
        </>
    )
}


