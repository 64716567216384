import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { Layout } from "../Layout";
import { WaveIcon } from '../../components/svg_icons/WaveIcon';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { KazOceaList } from '../../components/KazOceaList';
import { EnumKazOceaListMode, EnumTherapist } from '../../enums';
import { TherapistNavigation } from '../../components/TherapistNavigation';


interface IProps {
}

export const AmandineCollard: FC<IProps> = (props) => {

  const theme = useTheme()

  const listElements1 = [
    <>
      des suivis neuropsychologiques de type remédiation cognitive et métacognition qui ont pour objectif de favoriser la prise de conscience et compréhension de son propre fonctionnement psycho-cognitif et comportemental; de proposer des exercices spécifiques et scientifiquement prouvés de stimulation des fonctions cognitives (attention, mémoire, inhibition, flexibilité, organisation/planification,...) et d'élaborer avec le patient des stratégies d'apprentissage en fonction de ses propres forces et faiblesses,...
    </>,
    <>
      des suivis cliniques autour de difficultés d'ordre psycho-affectives/émotionnelles associées ou non à des difficultés cognitives qui contribuent à accompagner petits et grands dans leurs émotions, dans la confiance en soi, l'estime de soi, le sentiment de compétence personnelle mais également à développer et enrichir la prise de conscience de leurs propres modes de fonctionnement, ...
    </>,
  ]

  const listElements2 = [
    <>
      La boîte à outils dans le cadre de ces suivis est composées de jeux (attention, raisonnement, observation, construction, mémoire,...), de livres à thèmes, d'outils spécifiques autour des neurosciences sous forme papiers/crayons ou informatique, d'outils élaborés, partagés et personnalisés.
    </>,
  ]

  const listElements3 = [
    <>
      des séances de guidance parentale et de psycho-éducation avec pour objectif d'offrir des informations sur le fonctionnement cognitif, processus, troubles ; des suggestions et recommandations au regard de difficultés concrètes rapportées dans les milieux de vies (famille, fratrie, école,...) ; d'élaborer des stratégies personnalisées, pratiques et cohérentes dans une perspective essentielle à mes yeux, de soutien à la parentalité.
    </>,
    <>
      En fonction des besoins (bilans, suivis,...), je propose également et sur demande des parents de me rendre dans l'établissement scolaire du jeune (maternelle, primaire, collège, lycée) pour participer aux réunions/ échanges le concernant (Equipe éducatives, Equipe de Suivi de Scolarisation, ...)
    </>,
  ]

  const listElements4 = [
    <>
      L'objectif de ces différents échanges avec parents et/ou adultes qui entourent le jeune est d'amener une approche concrète et efficace afin de favoriser une communication cohérente, respectueuse et durable autour de lui, de permettre aux adultes et au jeune d'avancer sereinement vers l'amélioration de la situation et de faciliter l'épanouissement du système dans son ensemble (familial, scolaire,...) dans un processus de co-création.
    </>,
  ]

  // A cause de React, faut forcer le scrollTo top sur les nouvelles pages.
  useEffect(() => {
    // window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Ajouter un comportement de défilement fluide
      });
    }, 500);
  }, [])

  return (
    <div>
      <Layout>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "800px",
          mx: "auto",
          pt: { xs: 10, md: 16 },
        }}>
          <Avatar
            alt="Amandine COLLARD"
            src={`${process.env.PUBLIC_URL}/images/therapists/Amandine_COLLARD_avatar.jpg`}
            sx={{
              width: "165px",
              height: "165px",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
            }}
          />
          <Typography sx={{ fontFamily: theme.typography.alternative, fontSize: 36, color: theme.palette.secondary.main, mt: 1 }}>
            Amandine COLLARD
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Psychologue Neuropsychologue
          </Typography>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 4, mb: 2 }} />
          <Box sx={{ px: 2, mb: 2 }}>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Passionnée du fonctionnement humain que ce soit dans sa dynamique cognitive, comportementale, psycho-émotionnelle ou relationnelle, je suis diplômée d'un Master II en Sciences psychologiques de l'éducation avec une spécialisation en neuropsychologie clinique à l'université Libre de Bruxelles (Belgique) depuis 2004 et formée au coaching évolutif (Belgique).
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Diplômée depuis une vingtaine d'année et installée sur l'île depuis plus de 15 ans, j'ai eu la chance d'exercer dans différentes structures de santé en Belgique et à la Réunion (milieu hospitalier, centre de rééducation, centre de référence, service de soin à domicile type sessad,...).
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              Installée en libérale depuis 2017, je propose des accompagnements pour enfants, adolescents et adultes dans une dimension intégrative et holistique, centrée sur la personne et son système, dans ce qu'il y a de plus juste pour elle en fonction de là où elle en est, de son besoin, de ses forces/faiblesses et de son environnement, en lui offrant un espace thérapeutique de parole où écoute, bienveillance, non jugement, authenticité, respect, implication et confiance sont garantis.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mt: 4, mb: 1 }}>
              Dans le cadre de mes accompagnements, je propose :
            </Typography>
            <KazOceaList mode={EnumKazOceaListMode.standard} elements={listElements1} textSx={{ fontFamily: theme.typography.writer, fontSize: "16px" }} />
            <KazOceaList mode={EnumKazOceaListMode.alternative} elements={listElements2} textSx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.primary.main }} />

            <KazOceaList mode={EnumKazOceaListMode.standard} elements={listElements3} textSx={{ fontFamily: theme.typography.writer, fontSize: "16px" }} />
            <KazOceaList mode={EnumKazOceaListMode.alternative} elements={listElements4} textSx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.primary.main }} />

            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              La première consultation permettra d'étudier la demande, de définir ensemble des modalités de prise en charge au regard des besoins spécifiques.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              Si besoin, je collabore également avec les autres professionnels de santé ou du social avec votre accord et dans votre intérêt, afin de trouver la prise en charge la plus adaptée à vos besoins. La confidentialité vous est garantie.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              Les consultations psychologiques-neuropsychologiques ne sont pas remboursées par les caisses d'assurance maladie. En revanche, certaines mutuelles offrent la possibilité de prise en charge partielle.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              Les consultations sont sur RDV.
              <br />
              Pour plus d'informations, merci de me contacter au
              &nbsp;
              <Typography
                component="a"
                href='tel:0693547941'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                06 93 54 79 41
              </Typography>
              &nbsp;
              en privilégiant les textos.
            </Typography>

          </Box>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 2, mb: 2 }} />
          <Typography sx={{ mt: -1, color: theme.palette.secondary.main }}>
            Espace Lagon
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ mx: 4, textAlign: "center" }}>
          <Grid xs={12} >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/images/spaces/lagon_03.jpg`}
              sx={{
                width: { xs: "250px", sm: "500px" },
                borderStyle: "solid",
                borderWidth: 2,
                borderColor: theme.palette.secondary.main,
                borderRadius: "24px",
                mt: 1,
              }}
            />
          </Grid>
        </Grid>
        <TherapistNavigation currentTherapist={EnumTherapist.amandineCollard} sx={{ mt: 4 }} />
      </Layout>
    </div >
  )
}
