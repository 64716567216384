import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { Layout } from "../Layout";
import { WaveIcon } from '../../components/svg_icons/WaveIcon';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { EnumTherapist } from '../../enums';
import { TherapistNavigation } from '../../components/TherapistNavigation';


interface IProps {
}

export const AudreyMeese: FC<IProps> = (props) => {

  const theme = useTheme()

  // A cause de React, faut forcer le scrollTo top sur les nouvelles pages.
  useEffect(() => {
    // window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Ajouter un comportement de défilement fluide
      });
    }, 500);
  }, [])

  return (
    <div>
      <Layout>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "800px",
          mx: "auto",
          pt: { xs: 10, md: 16 },
        }}>
          <Avatar
            alt="Audrey MEESE"
            src={`${process.env.PUBLIC_URL}/images/therapists/Audrey_MEESE_avatar.jpg`}
            sx={{
              width: "165px",
              height: "165px",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
            }}
          />
          <Typography sx={{ fontFamily: theme.typography.alternative, fontSize: 36, color: theme.palette.secondary.main, mt: 1 }}>
            Audrey MEESE
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Praticienne en Hypnose Ericksonienne, PNL, Cohérence cardiaque et Psychologie positive, Tabacologue
          </Typography>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 4, mb: 2 }} />
          {/* <Box sx={{ px: 2, mb: 2 }}>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Je me présente
              &nbsp;
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main }}>Audrey MEESE</Typography>
              , 35 ans, gourmande, souriante, bienveillante et pétillante j’aime rire et danser. J’apprécie m’émerveiller et partager ma joie de vivre avec les autres. J’ai exercé en tant qu’infirmière aussi bien en oncologie qu’en addictologie et connu aussi les longues journées sur les routes.
              &nbsp;
              Praticienne certifiée voici les outils que je propose lors des séances : l’Hypnose Ericksonienne (
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", fontStyle: "italic" }}>Idcom international</Typography>
              ), la programmation neuro-linguistique (
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", fontStyle: "italic" }}>Idcom international</Typography>
              ) et la cohérence cardiaque(
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", fontStyle: "italic" }}>F.THOMAS Sophrologue</Typography>
              ). Je qualifie ma pratique de “Facilitatrice positive” en m’appuyant aussi sur mon cursus universitaire de management bien être(Master) et de psychologie positive (DU)..
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Je vous propose de vous accompagner dans les changements de votre vie et leurs étapes : rentrée scolaire, changement de poste, compétitions, projet professionnel, annonce de la maladie, addiction, deuil... Gagnez en sérénité, une meilleure gestion de vos émotions, (sommeil, phobies...) en améliorant votre relation à vous même et aux autres au fil des séances.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              L’
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main }}>Hypnose Ericksonienne</Typography>
              &nbsp;
              je m’appuie sur des contes, des anecdotes métaphoriques et des suggestions indirectes afin de faire lever des résistances au changement. Ce qui vous laisse à vous la possibilité durant l’état de conscience modifié la possibilité d’accéder à vos propres ressources nécessaires pour mettre en oeuvre les changements souhaités.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1, color: theme.palette.secondary.main }}>
              Séance de 1h. Tarif:70 euros
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              La
              &nbsp;
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main }}>PNL</Typography>
              &nbsp;
              permet de se découvrir (remédiation) pour changer les modes de pensées qui vous limitent et par conséquent agir différemment. Il permet aussi d’accompagner les personnes dans la mise en œuvre de leur projet de vie et une meilleure compréhension de son fonctionnement.
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main }}> Séance de 1h. Tarif:70 euros</Typography>

            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              La
              &nbsp;
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main }}>Cohérence cardiaque</Typography>
              &nbsp;est un état d'équilibre physiologique, physique, mental et émotionnel qui amène un ressourcement, un recentrage. Cet état particulier peut être induit par une pratique simple qui doit être volontaire. Il est possible de construire une habitude positive pour votre santé physique et mentale et votre mieux être avec gestion du stress et des troubles du sommeil.
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main }}> Séance de 1h15 + support . Tarif:70 euros</Typography>
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              La
              &nbsp;
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main }}>Psychologie positive</Typography>
              &nbsp;
              aussi appelée “science du bonheur” met en avant les différents facteurs de notre vie qui favorisent un fonctionnement dit optimal voir épanouissant. Dans la pratique, on utilise plusieurs leviers pour donner du sens à nos actions, regarder au travers un prisme positif, améliorer nos relations aux autres... L'objectif est de développer avec vous les mécanismes qui vous font du bien.
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main }}>Séance de 1h. Tarif:70 euros</Typography>
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              Il s’agit d’une activité déconventionnée avec règlement à la séance. Vous pouvez toujours vous renseigner auprès de votre mutuelle pour savoir si un remboursement est possible. La consultation ne substitue pas à un avis médical adapté.
              Retrouvez toutes informations pratiques concernant ces spécialités sur la page facebook @virgulepositive et sur le site.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mt: 4, mb: 1 }}>
              Vous pouvez me contacter au
              &nbsp;
              <Typography
                component="a"
                href='tel:0692036369'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                0692036369
              </Typography>
              &nbsp;
              ou sur
              &nbsp;
              <Typography
                component="a"
                href='https://calendly.com/audrey_virgulepositive-hypnose-pnl-psychologiepositive/seance-d-hypnose-ericksonienne'
                target='blank'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                calendy
              </Typography>
            </Typography>
          </Box> */}
          <Box sx={{ px: 2, mb: 2 }}>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Je me présente
              &nbsp;
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main }}>Audrey MEESE</Typography>
              , 36 ans, gourmande, souriante, bienveillante et pétillante j’aime rire et danser. J’apprécie m’émerveiller et partager ma joie de vivre avec les autres. J’ai exercé en tant qu’infirmière aussi bien en oncologie qu’en addictologie et connu aussi les longues journées sur les routes.
              &nbsp;
              Praticienne certifiée voici les outils que je propose lors des séances : l’Hypnose Ericksonienne transtemporelle , la lecture d’aura (
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", fontStyle: "italic" }}>Idcom international</Typography>
              ), la programmation neuro-linguistique (
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", fontStyle: "italic" }}>Idcom international</Typography>
              ) et la cohérence cardiaque(
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", fontStyle: "italic" }}>F.THOMAS Sophrologue</Typography>
              ). Je qualifie ma pratique de “Facilitatrice positive” en m’appuyant aussi sur mon cursus universitaire de management bien être(Master) et de psychologie positive (DU). Actuellement je finalise ma formation de Tabacologue auprès de l’Université Paris Saclay pour mieux vous aider au sevrage tabagique.
              <br />
              <br />
              Je vous propose de vous accompagner dans les changements de votre vie et leurs étapes : rentrée scolaire, changement de poste, compétitions, projet professionnel,PMA, compétences psychosociales, Bien être au travail,  annonce de la maladie, addiction, deuil... Gagnez en sérénité, une meilleure gestion de vos émotions et de votre stress qui impactent sur votre sommeil, vos phobies  en améliorant votre relation à vous même et aux autres au fil des séances.
              <br />
              <br />
              L’
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main }}>Hypnose Ericksonienne</Typography>
              &nbsp;
              je m’appuie sur des contes, des anecdotes métaphoriques et des suggestions indirectes afin de faire lever des résistances au changement. Ce qui vous laisse à vous la possibilité durant l’état de conscience modifié la possibilité d’accéder à vos propres ressources nécessaires pour mettre en œuvre les changements souhaités.
              <br />
              <br />
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1, color: theme.palette.secondary.main }}>
                1ère consultation prévoir 1h30 tarif :95 euros (voir 2h à 150 euros selon ce qui est vu ensemble)                     Séance de suivi 1h. Tarif:75 euros
              </Typography>
              <br />
              <br />
              La
              &nbsp;
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main }}>PNL</Typography>
              &nbsp;
              permet de se découvrir (remédiation) pour changer les modes de pensées qui vous limitent et par conséquent agir différemment. Il permet aussi d’accompagner les personnes dans la mise en œuvre de leur projet de vie et une meilleure compréhension de son fonctionnement.
              <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1, color: theme.palette.secondary.main }}>Séance de 1h. Tarif:75 euros</Typography>
              <br />
              <br />
              La <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1, color: theme.palette.secondary.main }}>Cohérence cardiaque</Typography> est un état d'équilibre physiologique, physique, mental et émotionnel qui amène un ressourcement, un recentrage.
              Cet état particulier peut être induit par une pratique simple qui doit être volontaire. Il est possible de construire une habitude positive pour votre santé physique et mentale et votre mieux être avec gestion du stress et des troubles du sommeil.<Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1, color: theme.palette.secondary.main }}>Séance de 1h30 + support . Tarif:95 euros</Typography>
              <br />
              <br />
              La <Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main }}>Psychologie positive</Typography>  aussi appelée “science du bonheur” met en avant les différents facteurs de notre vie qui favorisent un fonctionnement dit optimal voir épanouissant. Dans la pratique, on utilise plusieurs leviers pour donner du sens à nos actions, regarder au travers un prisme positif, améliorer nos relations aux autres... L'objectif est de développer avec vous les mécanismes qui vous font du bien.<Typography component="span" sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main }}>Séance de 1h. Tarif:75 euros</Typography>
              <br />
              <br />
              Il s’agit d’une activité déconventionnée avec règlement à la séance si paiement en espèce et en paiement en ligne (avant consultation). Vous pouvez toujours vous renseigner auprès de votre mutuelle pour savoir si un remboursement est possible. La consultation ne substitue pas à un avis médical adapté. Retrouvez toutes informations pratiques concernant ces spécialités sur la page facebook @virgulepositive et sur le site.
              <br />
              <br />
              Vous pouvez me contacter au
              &nbsp;
              <Typography
                component="a"
                href='tel:0692036369'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                0692036369
              </Typography>
              &nbsp;
              ou sur
              &nbsp;
              <Typography
                component="a"
                href='https://www.crenolibre.fr/prendre-rdv/94871_audrey-meese'
                target='blank'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                crenolibre
              </Typography>
            </Typography>
          </Box>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 2, mb: 2 }} />
          <Typography sx={{ mt: -1, color: theme.palette.secondary.main }}>
            Espace Sel marin
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ mx: 4, textAlign: "center" }}>
          <Grid xs={12} >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/images/spaces/sel_marin.jpg`}
              sx={{
                width: { xs: "250px", sm: "500px" },
                borderStyle: "solid",
                borderWidth: 2,
                borderColor: theme.palette.secondary.main,
                borderRadius: "24px",
                mt: 1,
              }}
            />
          </Grid>
        </Grid>
        <TherapistNavigation currentTherapist={EnumTherapist.audreyMeese} sx={{ mt: 4 }} />
      </Layout>
    </div >
  )
}
