import { FC } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Home } from './pages/Home'
// import { Page404 } from './pages/Page404';
import { NAVIGATE_ADMIN_CUSTOMERS_PAGE, NAVIGATE_ADMIN_LOGIN, NAVIGATE_ADMIN_PAGE, NAVIGATE_ADMIN_SPACES_PAGE, NAVIGATE_BUILDING, NAVIGATE_LANDING_PAGE, NAVIGATE_ROOT, NAVIGATE_SPACE_TERRACE_PAGE, NAVIGATE_SPACE_WORKSHOP_PAGE, NAVIGATE_SPACE_WORKSHOP_RULES_PAGE, NAVIGATE_THERAPIST_AMANDINE_BLACHE, NAVIGATE_THERAPIST_AMANDINE_COLLARD, NAVIGATE_THERAPIST_AUDREY_MEESE, NAVIGATE_THERAPIST_BENEDICTE_LE_PARC, NAVIGATE_THERAPIST_ERWAN_LEBLOND, NAVIGATE_THERAPIST_FRANCOIS_HOARAU, NAVIGATE_THERAPIST_LISE_ROUSSELIERE, NAVIGATE_THERAPIST_LOUIS_TIERSONNIER, NAVIGATE_THERAPIST_MALIKA_FONTAINE, NAVIGATE_THERAPIST_MARINE_DUPEYRON, NAVIGATE_THERAPIST_VALERIE_PAYET, NAVIGATE_THERAPIST_VANESSA_LEVY, NAVIGATE_THERAPIST_VERONIQUE_FONTAINE, NAVIGATE_WHERE_PAGE } from './utils/Navigation';

import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { KAZOCEA_WEB_JWT_TOKEN } from "./redux/features/global/apolloSlice";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Building } from './pages/Building';
import { VanessaLevy } from './pages/therapists/VanessaLevy';
import { ValeriePayet } from './pages/therapists/ValeriePayet';
import { AudreyMeese } from './pages/therapists/AudreyMeese';
import { MalikaFontaine } from './pages/therapists/MalikaFontaine';
import { LiseRousseliere } from './pages/therapists/LiseRousseliere';
import { BenedicteLeParc } from './pages/therapists/BenedicteLeParc';
import { MarineDupeyron } from './pages/therapists/MarineDupeyron';
import { VeroniqueFontaine } from './pages/therapists/VeroniqueFontaine';
import { AmandineCollard } from './pages/therapists/AmandineCollard';
import { AmandineBlache } from './pages/therapists/AmandineBlache';
import { WherePage } from './pages/WherePage';
import { SpaceWorkshopPage } from './pages/SpaceWorkshopPage';
import { SpaceTerracePage } from './pages/SpaceTerracePage';
import { AdminPage } from './pages/admin/AdminPage';
import { AdminLoginPage } from './pages/admin/AdminLoginPage';
import { AdminCustomersPage } from './pages/admin/AdminCustomersPage';
import { EnumSpaceReference } from './enums';
import { AdminSpacesPage } from './pages/admin/AdminSpacesPage';
import { FrancoisHoarau } from './pages/therapists/FrancoisHoarau';
import { ErwanLeblond } from './pages/therapists/ErwanLeblond';
import { LouisTiersonnier } from './pages/therapists/LouisTiersonnier';
import { SpaceWorkshopRulesPage } from './pages/SpaceWorkshopRulesPage';
import { apolloClient } from './ApolloClient';


declare module '@mui/material/styles' {
  interface Palette {
    cancel: Palette['primary'];
  }

  interface PaletteOptions {
    cancel: PaletteOptions['primary'];
  }

  interface TypographyVariants {
    primary: React.CSSProperties;
    secondary: React.CSSProperties;
    alternative: React.CSSProperties;
    writer: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    primary?: React.CSSProperties;
    secondary?: React.CSSProperties;
    alternative?: React.CSSProperties;
    writer?: React.CSSProperties;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    cancel: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    cancel: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    cancel: true;
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    cancel: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    primary: true;
    secondary: true;
    alternative: true;
    writer: true;
  }
}


const theme = createTheme({
  palette: {
    primary: {
      main: "#ED6C57",
    },
    secondary: {
      main: "#1EA8C0",
      contrastText: "#FFF",
    },
    cancel: {
      main: "#888888",
      contrastText: '#F5F3F5',
    },
    background: {
      default: "#F5F3F5",
    },

  },
  typography: {
    // fontFamily: [
    //   '-apple-system',
    //   'BlinkMacSystemFont',
    //   '"Segoe UI"',
    //   'Roboto',
    //   '"Helvetica Neue"',
    //   'Arial',
    //   'sans-serif',
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"',
    // ].join(','),
    primary: {
      fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'].join(','),
    },
    secondary: {
      // fontFamily: 'Barlow, sans-serif',
      fontFamily: "Barlow",
    },
    alternative: {
      fontFamily: "Dosis",
    },
    writer: {
      // fontFamily: 'Playpen Sans',
      fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'].join(','),
    },
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         textTransform: 'none',
  //       },
  //     },
  //   },
  // },
});

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors) {
//     graphQLErrors.map(({ message, locations, path }) => {
//       console.log(`Graphql ERROR : ${message}`)
//       console.log(`removing jwt_token..`);
//       localStorage.removeItem(KAZOCEA_WEB_JWT_TOKEN)
//       return false
//     })
//   }
// })

// const apolloCache = new InMemoryCache()

// let link = from([
//   errorLink,
//   new HttpLink({
//     uri: process.env.REACT_APP_KAZOCEA_CORE_URI,
//     headers: {
//       FrontApp: "kazocea_web",
//       FrontAppToken: process.env.REACT_APP_FRONTAPP_TOKEN || "",
//     }
//   })
// ])

// const kazoceaJwtToken = localStorage.getItem(KAZOCEA_WEB_JWT_TOKEN)
// if (kazoceaJwtToken) {
//   link = from([
//     errorLink,
//     new HttpLink({
//       uri: process.env.REACT_APP_KAZOCEA_CORE_URI,
//       headers: {
//         FrontApp: "kazocea_web",
//         FrontAppToken: process.env.REACT_APP_FRONTAPP_TOKEN || "",
//         authToken: kazoceaJwtToken,
//       }
//     })
//   ])
// }

// export const apolloClient = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: link,
// })

interface IProps { }

export const App: FC<IProps> = (props) => {
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div className="App">
            <BrowserRouter>
              <Routes>
                <Route path={`${NAVIGATE_ROOT}`} element={<Home />} />
                <Route path={`${NAVIGATE_LANDING_PAGE}`} element={<Home />} />
                <Route path={`${NAVIGATE_THERAPIST_VALERIE_PAYET}`} element={<ValeriePayet />} />
                <Route path={`${NAVIGATE_THERAPIST_VANESSA_LEVY}`} element={<VanessaLevy />} />
                <Route path={`${NAVIGATE_THERAPIST_AUDREY_MEESE}`} element={<AudreyMeese />} />
                <Route path={`${NAVIGATE_THERAPIST_MALIKA_FONTAINE}`} element={<MalikaFontaine />} />
                <Route path={`${NAVIGATE_THERAPIST_LISE_ROUSSELIERE}`} element={<LiseRousseliere />} />
                <Route path={`${NAVIGATE_THERAPIST_BENEDICTE_LE_PARC}`} element={<BenedicteLeParc />} />
                <Route path={`${NAVIGATE_THERAPIST_MARINE_DUPEYRON}`} element={<MarineDupeyron />} />
                <Route path={`${NAVIGATE_THERAPIST_VERONIQUE_FONTAINE}`} element={<VeroniqueFontaine />} />
                <Route path={`${NAVIGATE_THERAPIST_AMANDINE_COLLARD}`} element={<AmandineCollard />} />
                <Route path={`${NAVIGATE_THERAPIST_AMANDINE_BLACHE}`} element={<AmandineBlache />} />
                <Route path={`${NAVIGATE_THERAPIST_FRANCOIS_HOARAU}`} element={<FrancoisHoarau />} />
                <Route path={`${NAVIGATE_THERAPIST_ERWAN_LEBLOND}`} element={<ErwanLeblond />} />
                <Route path={`${NAVIGATE_THERAPIST_LOUIS_TIERSONNIER}`} element={<LouisTiersonnier />} />
                <Route path={`${NAVIGATE_WHERE_PAGE}`} element={<WherePage />} />
                <Route path={`${NAVIGATE_SPACE_WORKSHOP_PAGE}`} element={<SpaceWorkshopPage />} />
                <Route path={`${NAVIGATE_SPACE_TERRACE_PAGE}`} element={<SpaceTerracePage />} />

                <Route path={`${NAVIGATE_SPACE_WORKSHOP_RULES_PAGE}`} element={<SpaceWorkshopRulesPage />} />


                <Route path={`${NAVIGATE_ADMIN_PAGE}`} element={<AdminPage />} />
                <Route path={`${NAVIGATE_ADMIN_LOGIN}`} element={<AdminLoginPage />} />
                <Route path={`${NAVIGATE_ADMIN_CUSTOMERS_PAGE}`} element={<AdminCustomersPage />} />
                {/* <Route path={`${NAVIGATE_ADMIN_WORKSHOP_PAGE}`} element={<AdminSpacePage spaceReference={EnumSpaceReference.workshop} />} />
                <Route path={`${NAVIGATE_ADMIN_TERRACE_PAGE}`} element={<AdminSpacePage spaceReference={EnumSpaceReference.terrace} />} /> */}
                <Route path={`${NAVIGATE_ADMIN_SPACES_PAGE}`} element={<AdminSpacesPage />} />


                <Route path={`${NAVIGATE_BUILDING}`} element={<Building />} />
                {/* <Route path="/*" element={<Page404 />} /> */}
              </Routes>
            </BrowserRouter>
          </div>
        </LocalizationProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}



