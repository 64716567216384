import { Box, TextField, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { AdminLayout } from './AdminLayout'
import { useNavigate } from 'react-router'
import { TitleSection } from '../../components/TitleSection'
import { EnumSpaceReference, EnumTitleSectionMode } from '../../enums'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../redux/store'
import { Spinner } from '../../components/Spiner'
import { DateTimePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { Flexbox } from '../../components/utils/Flexbox'
import { SpacesPlanning } from '../../components/plannings/SpacesPlanning'
import { ISpace } from '../../interfaces'
import { getSpaces } from '../../redux/features/spaces/spaceSlice'

interface IProps {
}

export const AdminSpacesPage: FC<IProps> = (props) => {

  const spaces: ISpace[] = useSelector((state: IRootState) => state.spaceReducer.spaces)
  const spaceLoading: boolean = useSelector((state: IRootState) => state.spaceReducer.spaceLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSpaces())
  }, [])
  return (
    <div className="AdminSpacePage">
      <AdminLayout>
        <Flexbox direction='column' >
          {
            spaceLoading
              ?
              <Spinner />
              :
              spaces && <Flexbox direction='column' sx={{ width: "100%" }}>
                <TitleSection title="Planning" mode={EnumTitleSectionMode.admin} />
                <Flexbox sx={{ width: "100%" }}>
                  <SpacesPlanning />
                </Flexbox>
              </Flexbox>
          }
        </Flexbox>
      </AdminLayout>
    </div >
  )
}
