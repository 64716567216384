// import "../css/pages/Home.css"
import { Box, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import { Flexbox } from '../components/utils/Flexbox'
import FacebookIcon from '@mui/icons-material/Facebook';



interface IProps {
}




export const FacebookFeed: FC<IProps> = (props) => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  const theme = useTheme()

  // useEffect(() => {
  //   console.log("actual window.innerWidth : ", window.innerWidth)
  //   const handleResize = () => {
  //     setDeviceWidth(window.innerWidth);
  //     console.log("window.innerWidth : ", window.innerWidth)
  //   };
  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);


  return (
    <Flexbox sx={{ width: "100%" }}>
      {/* <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fkazocea%2Fposts%2Fpfbid02sX37PdTqSv2RNXUHPpKxS6RhdPtEWtKSZd1FqG6GuFw5jtQpaKu9H2UNU8GNEE8Ll&show_text=true&width=500" width="500" height="626" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}
      {/* <iframe
              src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fkazocea%2Fposts%2Fpfbid02sX37PdTqSv2RNXUHPpKxS6RhdPtEWtKSZd1FqG6GuFw5jtQpaKu9H2UNU8GNEE8Ll&show_text=true&width=500"
              width="500"
              height="626"
              style={{ border: "none", overflow: "hidden" }}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}

      {/* <div className="fb-page" data-href="https://www.facebook.com/kazocea" data-show-posts="true" data-width="500" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="false"><blockquote cite="https://www.facebook.com/kazocea" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/kazocea">KAZ OCEA</a></blockquote></div> */}
      {/* <Box sx={{
        width: { xs: 300, sm: 800 },
        bgcolor: "green",
      }}> */}
      <div className="fb-page"
        data-href="https://www.facebook.com/kazocea"
        data-show-posts="true"
        data-width={deviceWidth <= 600 ? "300" : "800"}
        data-height="600"
        data-small-header="true"
        data-adapt-container-width="false"
        data-hide-cover="true"
        data-show-facepile="false">
        <blockquote cite="https://www.facebook.com/kazocea"
          className="fb-xfbml-parse-ignore">
          {/* <a href="https://www.facebook.com/kazocea">KAZ OCEA sur Facebook</a> */}
          <a href="https://www.facebook.com/kazocea" target='_blank'>
            <FacebookIcon sx={{
              fontSize: 50,
              color: theme.palette.secondary.main,
              ":hover": {
                color: theme.palette.primary.main,
              }
            }} />
          </a>
        </blockquote>
      </div>

      {/* </Box> */}

    </Flexbox>
  )
}
