

export enum versionInfos {
    webVersion = "1.1.1",
    apiExpectedVersion = "1.0.1"
}


export enum EnumKazoceaLogoMode {
    standard = "standard",
    accueil = "accueil",
    accueilMobile = "accueilMobile",
    footer = "footer",
    logoOnly = "logoOnly",
}

export enum EnumTherapist {
    amandineBlache = "amandineBlache",
    amandineCollard = "amandineCollard",
    audreyMeese = "audreyMeese",
    benedicteLeParc = "benedicteLeParc",
    liseRousseliere = "liseRousseliere",
    malikaFontaine = "malikaFontaine",
    marineDupeyron = "marineDupeyron",
    valeriePayet = "valeriePayet",
    vanessaLevy = "vanessaLevy",
    veroniqueFontaine = "veroniqueFontaine",
    francoisHoarau = "francoisHoarau",
    erwanLeblond = "erwanLeblond",
    louisTiersonnier = "louisTiersonnier",
}

export enum EnumTherapistMode {
    card = "card",
    badge = "badge",
}

export enum EnumSpaceReference {
    workshop = "workshop",
    terrace = "terrace",
}

export enum EnumWriterSpanMode {
    colorSecondary = "colorSecondary",
    italic = "italic",
    bold = "bold",
}

export enum EnumKazOceaListMode {
    standard = "standard",
    alternative = "alternative",
    table = "table",
}

export enum EnumMessageType {
    Success = 1,
    Error,
    Info,
    Warning,
}

export enum EnumTitleSectionMode {
    standard = "standard",
    admin = "admin",
}

export enum EnumReservationState {
    created = "created",
    confirmed = "confirmed",
}

export enum EnumReservationFrequency {
    day = "day",
    week = "week",
    month = "month",
}

export enum EnumReservationType {
    standard = "standard",
    disabled = "disabled",
}

export enum EnumDayjsFormat {
    date = "DD/MM/YYYY",
    date_hours_minutes = "DD/MM/YYYY HH:mm",
    date_hours_minutes_secondes = "DD/MM/YYYY HH:mm:ss",
    hours_minutes = "HH:mm",
    day_name_day_number = "dddd DD",
    day_number = "DD",
    day_small = "ddd",
}

export enum EnumPlanningBy {
    week = "week",
    month = "month",
}

export enum EnumHighlightSpanMode {
    standard = "standard",
    alternative = "alternative",
    black = "black",
}

export enum EnumPlanningShowFilterAction {
    toggleSpaceWorkshop = "toggleSpaceWorkshop",
    toggleSpaceTerrace = "toggleSpaceTerrace",
}

export enum EnumGraphQLErrorCode {
    session_expired = "SESSION_EXPIRED"
}