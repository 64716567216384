import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ICustomer, IDuplicateReservationPrototype, IReservation, ISpace, ISpaceInfos } from '../../interfaces';
import { Flexbox } from '../utils/Flexbox';
import dayjs, { Dayjs } from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import { EnumMessageType, EnumReservationFrequency, EnumReservationState, EnumReservationType } from '../../enums';
import 'dayjs/locale/fr';
import { CustomerSelection } from '../customers/CustomerSelection';
import { checkSlotAvailable, getDayjsDuration, getSpacesPriceForSlot } from '../../utils/helpers';
import { addMessage } from '../messages/Message';
import { LockOpenOutlined, LockOutlined } from '@mui/icons-material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../redux/store';
// import { getSpacesInfos } from '../../redux/features/global/globalSlice';

interface IProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: (reservation: IReservation) => void;
    space: ISpace,
    reservation: IReservation,
    handleDeleteConfirm: () => void;
    handleConfirmDuplicate: (duplicateReservationPrototype: IDuplicateReservationPrototype) => void;
}

export const UpdateReservationDialog: FC<IProps> = (props) => {

    var stateToSend: IReservation = props.reservation

    const [customer, setCustomer] = useState<ICustomer | undefined>(props.reservation.customer)
    const [startAt, setStartAt] = useState<Dayjs>(dayjs.utc(props.reservation.startAt).local())
    const [endAt, setEndAt] = useState<Dayjs>(dayjs.utc(props.reservation.endAt).local())

    const [price, setPrice] = useState<string>(props.reservation.price)
    const [title, setTitle] = useState<string>(props.reservation.title)
    const [state, setState] = useState<EnumReservationState>(props.reservation.state)
    const [spacesCheckedIds, setSpacesCheckedIds] = useState<string[]>(props.reservation.spaceIds)

    const [disabledDeleteConfirm, setDisabledDeleteConfirm] = useState(true);
    const [tabValue, setTabValue] = useState<number>(0)
    const [frequency, setFrequency] = useState<EnumReservationFrequency>(EnumReservationFrequency.day)
    const [quantity, setQuantity] = useState<number>(1)

    // const spacesInfos: ISpaceInfos[] = useSelector((state: IRootState) => state.globalReducer.spacesInfos)
    const spaces: ISpace[] = useSelector((state: IRootState) => state.spaceReducer.spaces)

    // const dispatch = useDispatch()
    // useEffect(() => {
    //     dispatch(getSpacesInfos())
    // }, [])

    const theme = useTheme()

    const prepareStateToSend = () => {
        stateToSend = {
            ...stateToSend,
            customer,
            startAt,
            endAt,
            price,
            title,
            state,
            spaceIds: spacesCheckedIds,
        }
    }

    useEffect(() => {
        // Il ne faut pas mettre çà, sinon on ne voit plus le price de la DB.
        // setPrice(getSpacesPriceForSlot(props.space, startAt, endAt).toString())
    }, [startAt, endAt])

    const resetState = () => {
        setCustomer(props.reservation.customer)
        setStartAt(dayjs.utc(props.reservation.startAt).local())
        setEndAt(dayjs.utc(props.reservation.endAt).local())
        setTitle(props.reservation.title)
        setState(props.reservation.state)
        setDisabledDeleteConfirm(true)
        setSpacesCheckedIds(props.reservation.spaceIds)
    }

    const checkDatas = () => {
        var res = true
        // Le customer doit être sélectionné si reservation standard:
        if (props.reservation.reservationType === EnumReservationType.standard && !customer) {
            res = false
            addMessage({
                type: EnumMessageType.Error,
                message: "Le locataire est obligatoire."
            })
        }
        // Un espace doit être sélectionné:
        if (spacesCheckedIds.length <= 0) {
            res = false
            addMessage({
                type: EnumMessageType.Error,
                message: "Aucun espace sélectionné."
            })
        }
        if (getDayjsDuration(startAt, endAt) <= 0) {
            res = false
            addMessage({
                type: EnumMessageType.Error,
                message: "Les dates sont incohérentes."
            })
        }
        if (!checkSlotAvailable(props.space, startAt, endAt, props.reservation)) {
            res = false
            addMessage({
                type: EnumMessageType.Error,
                message: "Une autre réservation existe déjà sur ce créneau."
            })
        }
        return res
    }

    const handleConfirm = () => {
        if (checkDatas()) {
            // On unset les valeur en cas de rappel de la dialog:
            prepareStateToSend()
            resetState()
            props.handleConfirm(stateToSend)
        }
    }

    const handlePrice = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPrice(event.currentTarget.value)
    }

    const handleStartAt = (newStartAt: Dayjs | null) => {
        if (newStartAt) {
            setStartAt(newStartAt)
        }
    }
    const handleEndAt = (newEndAt: Dayjs | null) => {
        if (newEndAt) {
            setEndAt(newEndAt)
        }
    }

    const handleCustomerSelection = (customer: ICustomer) => {
        setCustomer(customer)
    }

    const handleTitle = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTitle(event.currentTarget.value)
    }

    const handleState = (newState: string) => {
        if (
            newState === EnumReservationState.created ||
            newState === EnumReservationState.confirmed
        ) {
            setState(newState)
        } else {
            console.error("state ", newState, " unknown !")
        }
    }

    const handleClose = () => {
        resetState()
        props.handleClose()
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }

    const handleFrequency = (newFrequency: string) => {
        if (
            newFrequency === EnumReservationFrequency.day ||
            newFrequency === EnumReservationFrequency.week ||
            newFrequency === EnumReservationFrequency.month
        ) {
            setFrequency(newFrequency)
        } else {
            console.error("frequency ", newFrequency, " unknown !")
        }
    }

    const handleQuantity = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setQuantity(parseInt(event.currentTarget.value))
    }

    const checkDuplicateDatas = () => {
        var res = true
        // La quantity doit être un entier positif
        if (!quantity || isNaN(quantity) || quantity <= 0) {
            res = false
            addMessage({
                type: EnumMessageType.Error,
                message: "La quantité est incohérente."
            })
        }
        return res
    }

    const handleConfirmDuplicate = () => {
        if (checkDuplicateDatas()) {
            // On unset les valeur en cas de rappel de la dialog:
            // prepareStateToSend()
            // resetState()
            const duplicateReservationPrototype: IDuplicateReservationPrototype = {
                reservation: props.reservation,
                frequency,
                quantity,
            }
            props.handleConfirmDuplicate(duplicateReservationPrototype)
        }
    }

    const handleSpacesChecked = (spaceId: string) => {
        if (spacesCheckedIds.includes(spaceId)) {
            setSpacesCheckedIds(spacesCheckedIds.filter(aSpaceId => aSpaceId !== spaceId));
        } else {
            setSpacesCheckedIds([...spacesCheckedIds, spaceId])
        }
    }

    return (
        <>
            <Dialog
                open={props.open}
                onClose={handleClose}
                fullWidth
            >
                <Flexbox sx={{ justifyContent: "flex-start", borderBottom: 1, borderColor: theme.palette.divider }}>
                    <Tabs value={tabValue} onChange={handleChangeTab}>
                        <Tab label="Modifier" id='tab-0' />
                        <Tab label="Dupliquer" id='tab-1' />
                    </Tabs>
                </Flexbox>
                {
                    tabValue === 0 && <>
                        <DialogContent>
                            <Flexbox direction='column'
                                sx={{
                                    marginTop: 1,
                                }}
                            >
                                <Flexbox sx={{ width: "100%", justifyContent: "space-between" }} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                        <DateTimePicker
                                            label="Du"
                                            value={startAt}
                                            onChange={(newStartAt) => handleStartAt(newStartAt)}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                        <DateTimePicker
                                            label="Au"
                                            value={endAt}
                                            onChange={(newEndAt) => handleEndAt(newEndAt)}
                                        />
                                    </LocalizationProvider>
                                </Flexbox>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Libellé"
                                    color="primary"
                                    onChange={(event) => { handleTitle(event) }}
                                    value={title}
                                    autoComplete='off'
                                    type="text"
                                    autoFocus
                                />
                                {
                                    props.reservation.reservationType === EnumReservationType.standard && <>

                                        <Flexbox sx={{ width: "100%", mt: 1 }}>
                                            <CustomerSelection handleConfirm={handleCustomerSelection} customer={customer} />
                                        </Flexbox>
                                    </>

                                }
                                <Flexbox
                                    sx={{
                                        width: "100%",
                                        justifyContent: "space-between",
                                        alignItems: "flex-start",
                                    }}>
                                    <Typography sx={{ mt: 1, color: theme.palette.text.secondary }}>
                                        Espaces:
                                    </Typography>
                                    <FormGroup>
                                        <Flexbox>
                                            {
                                                spaces.map((space) =>
                                                    <FormControlLabel key={`space-infos-checkbox-${space.id}`} control={
                                                        <Checkbox
                                                            checked={spacesCheckedIds.includes(space.id)}
                                                            onChange={() => handleSpacesChecked(space.id)}
                                                        />
                                                    } label={space.title} />
                                                )
                                            }
                                        </Flexbox>
                                    </FormGroup>
                                </Flexbox>
                                {
                                    props.reservation.reservationType === EnumReservationType.standard && <>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="Prix"
                                            color="primary"
                                            onChange={(event) => { handlePrice(event) }}
                                            value={price}
                                            autoComplete='off'
                                            type="number"
                                        />
                                    </>
                                }
                                {
                                    props.reservation.reservationType === EnumReservationType.standard && <>
                                        <FormControl fullWidth sx={{ mt: 2 }}>
                                            <InputLabel id="select-reservation-state">Statut</InputLabel>
                                            <Select
                                                id="select-reservation-state"
                                                value={state}
                                                label="Statut"
                                                onChange={(event) => { handleState(event.target.value) }}
                                                sx={{ width: "50%" }}
                                            >
                                                <MenuItem value={EnumReservationState.created}>Créé</MenuItem>
                                                <MenuItem value={EnumReservationState.confirmed}>Confirmé</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </>
                                }
                            </Flexbox>
                        </DialogContent>
                        <DialogActions>
                            <Flexbox sx={{ justifyContent: "space-between", width: "100%" }}>
                                <Flexbox>
                                    <IconButton onClick={() => { setDisabledDeleteConfirm(!disabledDeleteConfirm) }} sx={{
                                        color: disabledDeleteConfirm ? theme.palette.primary.main : theme.palette.error.main
                                    }}>
                                        {
                                            disabledDeleteConfirm ? <LockOutlined /> : <LockOpenOutlined />
                                        }
                                    </IconButton>
                                    <Button onClick={props.handleDeleteConfirm} disabled={disabledDeleteConfirm} variant="contained" color="error">
                                        Supprimer
                                    </Button>

                                </Flexbox>
                                <Flexbox sx={{ gap: 1 }}>
                                    <Button onClick={handleClose} variant="outlined" color="primary">Annuler</Button>
                                    <Button onClick={handleConfirm} variant="contained" color="secondary">
                                        Modifier
                                    </Button>
                                </Flexbox>
                            </Flexbox>
                        </DialogActions>
                    </>
                }
                {
                    tabValue === 1 && <>
                        <DialogContent>
                            <Flexbox direction='column'
                                sx={{
                                    marginTop: 1,
                                }}
                            >
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="select-reservation-frequency">Récurrence</InputLabel>
                                    <Select
                                        id="select-reservation-frequency"
                                        value={frequency}
                                        label="Récurrence"
                                        onChange={(event) => { handleFrequency(event.target.value) }}
                                        sx={{ width: "50%" }}
                                    >
                                        <MenuItem value={EnumReservationFrequency.day}>jour</MenuItem>
                                        <MenuItem value={EnumReservationFrequency.week}>semaine</MenuItem>
                                        <MenuItem value={EnumReservationFrequency.month}>mois</MenuItem>
                                    </Select>
                                </FormControl>
                                <Flexbox sx={{ width: "100%", mt: 2, justifyContent: "flex-start" }}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        label="Quantité"
                                        color="primary"
                                        onChange={(event) => { handleQuantity(event) }}
                                        value={quantity}
                                        autoComplete='off'
                                        type="number"
                                        sx={{ width: "50%" }}
                                    />
                                </Flexbox>
                                <Divider sx={{ width: "100%", my: 2 }} />
                                <Typography>
                                    La réservation
                                    &nbsp;
                                    <Typography component="span" sx={{ color: props.reservation.state === EnumReservationState.created ? theme.palette.primary.main : theme.palette.secondary.main }}>
                                        {props.reservation.title}
                                    </Typography>
                                    &nbsp;
                                    sera dupliquée pour
                                    &nbsp;
                                    {
                                        quantity === 1 && <>
                                            {frequency === EnumReservationFrequency.day && <>le jour suivant.</>}
                                            {frequency === EnumReservationFrequency.week && <>la semaine suivante.</>}
                                            {frequency === EnumReservationFrequency.month && <>le mois suivant.</>}
                                        </>
                                    }
                                    {
                                        quantity > 1 && <>
                                            {frequency === EnumReservationFrequency.day && <>les {quantity} jours suivants.</>}
                                            {frequency === EnumReservationFrequency.week && <>les {quantity} semaines suivantes.</>}
                                            {frequency === EnumReservationFrequency.month && <>les {quantity} mois suivants.</>}
                                        </>
                                    }

                                </Typography>
                            </Flexbox>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="outlined" color="primary">Annuler</Button>
                            <Button onClick={handleConfirmDuplicate} variant="contained" color="secondary">
                                Dupliquer
                            </Button>
                        </DialogActions>
                    </>
                }
            </Dialog >
        </>
    )
}
