import { Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { KazOceaSpan } from '../../components/KazOceaSpan';
import { KazOceaList } from '../../components/KazOceaList';
import { EnumKazOceaListMode, EnumWriterSpanMode } from '../../enums';
import { WriterSpan } from '../../components/WriterSpan';


interface IProps {
}

export const ErgoText: FC<IProps> = (props) => {

    const theme = useTheme()

    const listElements = [
        <>
            <WriterSpan mode={EnumWriterSpanMode.bold} content="Troubles spécifiques des apprentissages" /> (dysgraphie, dyslexie …)
        </>,
        <>
            <WriterSpan mode={EnumWriterSpanMode.bold} content="Troubles développementaux de la coordination" /> (TDC ou dyspraxie)
        </>,
        <>
            <WriterSpan mode={EnumWriterSpanMode.bold} content="Troubles du déficit de l'attention avec ou sans hyperactivité" /> (TDA/H)
        </>,
        <>
            <WriterSpan mode={EnumWriterSpanMode.bold} content="Troubles du Spectre de l'Autisme" /> (TSA)
        </>,
        <>
            <WriterSpan mode={EnumWriterSpanMode.bold} content="Troubles neurologiques" /> (paralysie cérébrale, traumatisme crânien, infirmité motrice cérébrale, polyhandicap, syndrome cérébelleux ..)
        </>,
        <>
            <WriterSpan mode={EnumWriterSpanMode.bold} content="Troubles moteurs" />
        </>,
        <>
            <WriterSpan mode={EnumWriterSpanMode.bold} content="Retard du développement" />
        </>,
        <>
            <WriterSpan mode={EnumWriterSpanMode.bold} content="Déficience intellectuelle" />
        </>,
    ]

    const listElements2 = [
        <>
            Des <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="bilans" /> des capacités et incapacités, des habitudes de vie, des facteurs environnementaux et humains, ainsi que des situations de handicap rencontrées au quotidien.
        </>,
        <>
            Des <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="actes de rééducation" /> visant à la récupération des fonctions déficitaires (cognitives, motrices, sensorielles et sensitives) en lien avec les activités de vie quotidienne (repas, toilette, habillage, organisation …) et les activités scolaires (écriture, manipulation des outils scolaires, …) dans le but de favoriser et développer l'autonomie de l'enfant.
        </>,
        <>
            Des <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="actes de réadaptation" /> visant la recherche de compensations motrices, cognitives et matérielles par un travail de positionnement, l'utilisation d'outils adaptés et la création d'aides personnalisées (cartes mentales, supports pédagogiques adaptés, séquençage d'activités ..)
        </>,
        <>
            Des <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="préconisations et informations" /> auprès de la famille, de l'équipe éducative et des intervenants extérieurs concernant la mise en place de compensations humaines (AESH, soutien scolaire…) pédagogiques et matérielles (mobilier, outil informatique).
        </>,
    ]


    return (
        <>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mb: 1 }}>
                Qui sommes-nous ?
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
                Nous sommes deux ergothérapeutes, spécialisés dans l'accompagnement d'enfants en situation de handicap, de tout âge.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mt: 4 }}>
                Erwan LEBLOND
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
                Ergothérapeute Diplômé d'Etat (Meulan-Les Mureaux)
                <br />
                Formations complémentaires : Ergothérapie et TSA, comprendre l'autisme et assurer efficacement sa rééducation.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mt: 2 }}>
                Louis TIERSONNIER
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
                Ergothérapeute Diplômé d'Etat (Berck-Sur-Mer).
                <br />
                Formations complémentaires : Prise en charge spécifique en ergothérapie TSA, les troubles neurodéveloppementaux – DYS et TDAH.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1, mt: 4 }}>
                Nous sommes en capacité d'accompagner des jeunes présentant les atteintes spécifiques suivantes :
            </Typography>
            <KazOceaList mode={EnumKazOceaListMode.standard} elements={listElements} textSx={{ fontFamily: theme.typography.writer, fontSize: "16px" }} />
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mb: 1, mt: 4 }}>
                Qu'est ce que l'ergothérapie ?
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
                L'ergothérapie est une profession de santé paramédicale visant à prévenir, réduire ou supprimer les
                situations de handicap des enfants de tout âge. Elle se base sur des activités significatives visant à
                préserver et développer leur indépendance (capacité à faire) et leur autonomie (capacité à gérer et à
                décider) au quotidien, à l'école et dans leurs loisirs.
                <br />
                L'ergothérapie propose une approche globale de l'enfant et se pratique au travers de la <WriterSpan mode={EnumWriterSpanMode.bold} content="rééducation, de la réadaptation" />, par et pour l'activité.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
                L'ergothérapeute travaille en étroite collaboration avec l'ensemble des professionnels gravitant
                autour de l'enfant et de sa famille.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mb: 0, mt: 4 }}>
                Que fait l'ergothérapeute ?
            </Typography>
            <KazOceaList mode={EnumKazOceaListMode.standard} elements={listElements2} textSx={{ fontFamily: theme.typography.writer, fontSize: "16px" }} />
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mt: 4, mb: 1 }}>
                Infos pratiques
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
                Les séances ont lieu au cabinet et/ou sur le lieu de vie de l'enfant (domicile, milieu scolaire,
                institution, etc..)
                <br />
                Nous vous accueillons au cabinet, les lundis entre 8h et 10h et les vendredis entre 16h et 18h. Le reste du temps nous intervenons à domicile ou à l'école.
                <br />
                Prise de rdv par téléphone ou par mail :
                &nbsp;
                <Typography
                    component="a"
                    href='tel:0692025019'
                    sx={{
                        fontFamily: theme.typography.writer,
                        fontSize: "16px",
                        color: theme.palette.secondary.main,
                        textDecoration: "none",
                        ":hover": { textDecoration: "underline" }
                    }}>
                    06 92 02 50 19
                </Typography>
                <br />
                <Typography
                    component="a"
                    href="mailto:louis.tiersonnier@abcdergo.fr"
                    sx={{
                        fontFamily: theme.typography.writer,
                        fontSize: 16,
                        color: theme.palette.secondary.main,
                        textDecoration: "none",
                        ":hover": {
                            cursor: "pointer",
                            textDecoration: "underline",
                        },
                    }}>
                    louis.tiersonnier@abcdergo.fr
                </Typography>
                <br />
                <Typography
                    component="a"
                    href="mailto:erwan.leblond@abcdergo.fr"
                    sx={{
                        fontFamily: theme.typography.writer,
                        fontSize: 16,
                        color: theme.palette.secondary.main,
                        textDecoration: "none",
                        ":hover": {
                            cursor: "pointer",
                            textDecoration: "underline",
                        },
                    }}>
                    erwan.leblond@abcdergo.fr
                </Typography>
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1, mt: 4 }}>
                Les séances durent en moyenne 45 minutes et se déroulent au cabinet, à domicile ou à l'école.
                <br />
                Les bilans durent entre 2h00 et 3h00.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1, mt: 4 }}>
                Le coût de la séance est de 50 euros au cabinet et 55 euros à domicile/école.
                <br />
                Le côut d’un bilan en ergothérapie est de 190 euros.
                <br />
                Le financement : Les séances d'ergothérapie se font <WriterSpan mode={EnumWriterSpanMode.bold} content="sur prescription médicale" />. Elles ne sont pas
                prises en charge par la Sécurité Sociale mais peuvent être financées par la MDPH (dans le cas d'un
                handicap reconnu), la PCO (plateforme de coordination et d'orientation), sur demande spécifique par
                les prestations extra-légales de la Sécurité Sociales, certaines mutuelles et des comités d'entreprise.
            </Typography>

            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1, mt: 4 }}>
                Nous sommes référencés à la PCO (Plateforme de Coordination et d'Orientation).
            </Typography>



        </ >
    )
}
