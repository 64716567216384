import { FC, useEffect, useState } from 'react'
import { IDuplicateReservationPrototype, IReservation, ISpace } from '../../interfaces'
import { Flexbox } from '../utils/Flexbox'
import { Typography, useTheme } from '@mui/material'
import { getReservationHeight, truncateString } from '../../utils/helpers'
import { EnumMessageType, EnumPlanningBy, EnumReservationState, EnumReservationType } from '../../enums'
import { UpdateReservationDialog } from './UpdateReservationDialog'
import { useMutation } from '@apollo/client'
import { addMessage } from '../messages/Message'
import { useDispatch, useSelector } from 'react-redux'
import { GQL_MUTATION_DELETE_RESERVATION, GQL_MUTATION_DUPLICATE_RESERVATION, GQL_MUTATION_UPDATE_RESERVATION } from '../../graphql/Mutations'
import { IRootState } from '../../redux/store'
import { getSpaces } from '../../redux/features/spaces/spaceSlice'
// import 'dayjs/locale/fr'

interface IProps {
    reservation: IReservation,
    hourHeight: number,
    minuteHeight: number,
    planningBy: EnumPlanningBy,
    space: ISpace,
}

export const SpacePlanningReservationSlot: FC<IProps> = (props) => {

    const [showUpdateReservationDialog, setShowUpdateReservationDialog] = useState(false)
    const [updateReservation, { data: updateReservationData, loading: updateReservationLoading, error: updateReservationError }] = useMutation(GQL_MUTATION_UPDATE_RESERVATION)
    const [deleteReservation, { data: deleteReservationData, loading: deleteReservationLoading, error: deleteReservationError }] = useMutation(GQL_MUTATION_DELETE_RESERVATION)
    const [duplicateReservation, { data: duplicateReservationData, loading: duplicateReservationLoading, error: duplicateReservationError }] = useMutation(GQL_MUTATION_DUPLICATE_RESERVATION)

    const theme = useTheme()
    const dispatch = useDispatch()
    const borderTopWidth = 1
    const height = getReservationHeight(props.reservation, props.minuteHeight) + getReservationHeight(props.reservation, props.minuteHeight) / props.hourHeight - borderTopWidth
    const backgroundColor = props.reservation.reservationType === EnumReservationType.disabled
        ?
        theme.palette.grey[600]
        :
        props.reservation.state === EnumReservationState.created ? theme.palette.primary.light : theme.palette.secondary.main

    const backgroundColorHover = props.reservation.state === EnumReservationState.created ? theme.palette.primary.dark : theme.palette.secondary.dark

    const closeUpdateReservationDialog = () => {
        setShowUpdateReservationDialog(false)
    }

    const confirmUpdateReservationDialog = (reservation: IReservation) => {
        updateReservation({
            // variables: reservation
            variables: {
                ...reservation,
                reservationId: reservation.id,
                customerId: reservation.customer?.id,
                price: parseFloat(reservation.price),
            }
        })
        setShowUpdateReservationDialog(false)
    }
    useEffect(() => {
        if (updateReservationError) {
            addMessage({
                // location: "SpacePlanningReservationSlot",
                type: EnumMessageType.Error,
                message: updateReservationError.message,
            })
        } else if (updateReservationData) {
            if (updateReservationData.updateReservation.statusCode === 200) {
                dispatch(getSpaces())
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Réservation modifiée.",
                })
            } else {
                updateReservationData.updateReservation.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updateReservationData, updateReservationError])

    const confirmDeleteReservationDialog = () => {
        deleteReservation({
            variables: {
                reservationId: props.reservation.id
            }
        })
        setShowUpdateReservationDialog(false)
    }
    useEffect(() => {
        if (deleteReservationError) {
            addMessage({
                type: EnumMessageType.Error,
                message: deleteReservationError.message,
            })
        } else if (deleteReservationData) {
            if (deleteReservationData.deleteReservation.statusCode === 200) {
                dispatch(getSpaces())
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Réservation supprimée.",
                })
            } else {
                deleteReservationData.deleteReservation.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteReservationData, deleteReservationError])

    const confirmDuplicateReservationDialog = (duplicateReservationPrototype: IDuplicateReservationPrototype) => {
        duplicateReservation({
            variables: {
                reservationId: duplicateReservationPrototype.reservation.id,
                frequency: duplicateReservationPrototype.frequency,
                quantity: duplicateReservationPrototype.quantity,
            }
        })
        setShowUpdateReservationDialog(false)
    }
    useEffect(() => {
        if (duplicateReservationError) {
            addMessage({
                // location: "SpacePlanningReservationSlot",
                type: EnumMessageType.Error,
                message: duplicateReservationError.message,
            })
        } else if (duplicateReservationData) {
            if (duplicateReservationData.duplicateReservation.statusCode === 200) {
                dispatch(getSpaces())
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Réservation dupliquée.",
                })
            } else {
                duplicateReservationData.duplicateReservation.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [duplicateReservationData, duplicateReservationError])

    return (
        <>
            <Flexbox
                sx={{
                    backgroundColor: backgroundColor,
                    color: theme.palette.secondary.contrastText,
                    borderTopStyle: "solid",
                    // borderTopColor: theme.palette.secondary.main,
                    borderTopColor: theme.palette.secondary.contrastText,
                    borderTopWidth: borderTopWidth,
                    borderRightStyle: "dashed",
                    borderRightColor: theme.palette.primary.main,
                    borderRightWidth: 1,
                    height: height,
                    borderRadius: 1,
                    // width: "100%",
                    ":hover": {
                        cursor: "pointer",
                        backgroundColor: backgroundColorHover,
                    }
                }}
                onClick={() => setShowUpdateReservationDialog(true)}
            >
                <Flexbox direction='column'>
                    <Typography sx={{
                        overflowWrap: "anywhere",
                        fontSize: props.planningBy === EnumPlanningBy.month ? 12 : 16,
                    }}>
                        {props.planningBy === EnumPlanningBy.month ? truncateString(props.reservation.title, 5) : props.reservation.title}
                    </Typography>
                    <Typography sx={{ fontSize: 10, overflowWrap: "anywhere" }}>
                        {
                            props.planningBy === EnumPlanningBy.month
                                ?
                                truncateString(props.reservation.customer?.fullname || "", 5)
                                :
                                props.reservation.customer?.fullname
                        }
                    </Typography>
                </Flexbox>
            </Flexbox>
            <UpdateReservationDialog
                open={showUpdateReservationDialog}
                handleClose={closeUpdateReservationDialog}
                handleConfirm={confirmUpdateReservationDialog}
                handleDeleteConfirm={confirmDeleteReservationDialog}
                space={props.space}
                reservation={props.reservation}
                handleConfirmDuplicate={confirmDuplicateReservationDialog}
            />
        </>
    )
}
