import { FC, useState } from 'react'
import { Box, Typography, useTheme } from "@mui/material";
import * as MuiCarousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import { red } from '@mui/material/colors';
import { WaveIcon } from './svg_icons/WaveIcon';
import { EnumTitleSectionMode } from '../enums';
import { Flexbox } from './utils/Flexbox';

interface IProps {
    title: String,
}

export const SubtitleSection: FC<IProps> = (props) => {
    const theme = useTheme()

    return (
        <>
            <Flexbox sx={{ width: "100%", justifyContent: "flex-start", mb: 2 }}>
                <Typography sx={{
                    fontFamily: theme.typography.alternative,
                    color: theme.palette.secondary.main,
                    fontSize: { xs: 16, sm: 20, md: 30 },
                    fontWeight: "regular",
                    textAlign: "center",
                    width: "100%",
                }}>
                    {props.title}
                </Typography>
            </Flexbox>
        </>

    )
}


