// import "../css/pages/Home.css"
import { Box } from '@mui/material'
import { FC } from 'react'
import { Layout } from "./Layout"

import { WhereDescriptionSection } from '../sections/WhereDescriptionSection'
import { GeolocationSection } from '../sections/GeolocationSection'
import { PatientRulesSection } from '../sections/PatientRulesSection'
import { SpaceWorkshopRulesSection } from '../sections/SpaceWorkshopRulesSection'

interface IProps {
}

export const SpaceWorkshopRulesPage: FC<IProps> = (props) => {

  return (
    <div className="SpaceWorkshopRulesPage">
      <Layout>
        <Box id="SpaceWorkshopRules" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", mt: { xs: 7, sm: 7, md: 12 } }}>
          <SpaceWorkshopRulesSection />
        </Box>
      </Layout>
    </div >
  )
}
