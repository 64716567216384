import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { Layout } from "../Layout";
import { WaveIcon } from '../../components/svg_icons/WaveIcon';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { WriterSpan } from '../../components/WriterSpan';
import { EnumTherapist, EnumWriterSpanMode } from '../../enums';
import { TherapistNavigation } from '../../components/TherapistNavigation';
import { KazOceaSpan } from '../../components/KazOceaSpan';
import { SmallTable } from '../../components/SmallTable';


interface IProps {
}

export const VeroniqueFontaine: FC<IProps> = (props) => {

  const theme = useTheme()


  // A cause de React, faut forcer le scrollTo top sur les nouvelles pages.
  useEffect(() => {
    // window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Ajouter un comportement de défilement fluide
      });
    }, 500);
  }, [])

  return (
    <div>
      <Layout>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "800px",
          mx: "auto",
          pt: { xs: 10, md: 16 },
        }}>
          <Avatar
            alt="Véronique FONTAINE"
            src={`${process.env.PUBLIC_URL}/images/therapists/Veronique_FONTAINE_avatar.jpg`}
            sx={{
              width: "165px",
              height: "165px",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
            }}
          />
          <Typography sx={{ fontFamily: theme.typography.alternative, fontSize: 36, color: theme.palette.secondary.main, mt: 1 }}>
            Véronique FONTAINE
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Naturopathie, Sophrologie,
            <br />
            Sonothérapie et Massage bien être,
            <br />
            Périnatalité et santé de la femme
          </Typography>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 4, mb: 2 }} />
          <Box sx={{ px: 2, mb: 2 }}>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mb: 1 }}>
              Qui suis-je?
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Auparavant psychologue, je suis aujourd'hui <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="Conseillère naturopathe et sophrologue" />. Je me suis spécialisée dans l’accompagnement de la <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="femme de la puberté à l’après-ménopause" /> ainsi qu’en <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="naturopédiatrie" />.
              <br />
              Ce qui m'anime ? Mon attachement fort à la <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="Nature" />, ma vocation à <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="aider les personnes à prendre soin d’elles-mêmes" /> et le profond désir de <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="transmettre" />.
              <br />
              <br />
              La naturopathie est avant tout au service de la <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="prévention" /> et est une <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="approche holistique" /> : elle prend en compte l’être humain dans sa globalité et cherche à agir sur l’<WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="origine des maux" />. Elle "<WriterSpan mode={EnumWriterSpanMode.italic} content="vise à préserver et optimiser la santé globale de l’individu ainsi qu’à aider l’organisme à s’auto-régénérer grâce à des moyens naturels... " />" (d’après la FENA – Fédération Française des écoles de Naturopathie). Ainsi, la <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="nutrition, la micronutrition, la phytothérapie et l’aromathérapie, la sophrologie, les massages bien-être, la relaxation par les sons" /> sont les outils qui fondent ma pratique.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mt: 4, mb: 1 }}>
              Ce que je propose
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Un suivi spécifique de la période de <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="préconception, de la grossesse et du post-partum" />. J’ai à cœur de proposer une approche adaptée aux problématiques de <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="fertilité" />, au parcours de procréation médicalement assistée <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="(PMA)" /> et à <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="la naturopathie féminine" />. Ces suivis sont en complément du suivi médical.
              <br />
              J’accompagne également les <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="enfants de 0 à 12 ans" /> environ et la <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="famille" /> dans une démarche de <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="prévention et d’éducation de la santé au quotidien" />.
              <br />
              J’anime également des <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="ateliers" /> autour de la périnatalité (une grossesse et un post-partum en bonne forme, l’alimentation du tout petit et de l’enfant, ...) et du cycle féminin.
              <br />
              <br />
              Les <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="massages bien-être" /> et les séances de <WriterSpan mode={EnumWriterSpanMode.colorSecondary} content="sonothérapie" /> font partie des précieux outils que j’aime à proposer dans les accompagnements ou indépendamment.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mt: 4, mb: 1 }}>
              Prendre rendez-vous
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 1 }}>
              Je vous reçois les lundi et vendredi à <KazOceaSpan />, les autres jours au centre-ville de Saint-Pierre, à domicile (selon le secteur) ou en visioconférence et uniquement sur rendez-vous.
              <br />
              <br />
              Pour prendre rendez-vous ou avoir des informations, il suffit de me contacter au
              &nbsp;
              <Typography
                component="a"
                href='tel:0692108321'
                sx={{
                  fontFamily: theme.typography.writer,
                  fontSize: "16px",
                  color: theme.palette.secondary.main,
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" }
                }}>
                0692 108 321
              </Typography>. Je privilégie l’échange téléphonique avant une première rencontre, ce qui est plus chaleureux et aussi me permet de mieux répondre à la demande qui est formulée.
            </Typography>
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", color: theme.palette.secondary.main, mt: 4, mb: 1 }}>
              Voici quelques prestations et tarifs disponibles.
            </Typography>
            <SmallTable
              title="Naturopathie :"
              case01="1ère séance : bilan et définition du programme
              d’hygiène vitale personnalisé (1h30 environ)"
              case02="70 euros"
              case03="Séances de suivi (1h environ)"
              case04="50 euros"
            />
            <SmallTable
              title="Naturopathie spécifique (Préconception, grossesse, post-partum, famille)"
              case01="1ère séance : bilan et définition du programme d’hygiène vitale personnalisé (2h environ)"
              case02="80 euros ou 90 euros en couple/famille"
              case03="Séances de suivi (1h environ)"
              case04="50 euros"
            />
            <Typography sx={{ fontFamily: theme.typography.writer, fontSize: "16px", mb: 4 }}>
              Ces séances prennent en compte le(la) partenaire le cas échéant, et sa présence et participation sont fortement encouragées.
            </Typography>
            <SmallTable
              title="Sophrologie"
              case01="1ère séance : découverte de la sophrologie, définition de l’objectif et du programme personnalisé (1h30 environ)"
              case02="70 euros"
              case03="Séances suivantes (1h environ)"
              case04="40 euros"
            />
            <SmallTable
              title="Massages bien-être"
              case01="Ayurvédique Prénatal"
              case01_2="Relaxant aux huiles essentielles"
              case02=<Typography>60 min : 60 euros
                <br />
                90 min : 80 euros
              </Typography>
              case03="Ayurvédique énergétique Abhyanga"
              case04=<Typography>75 min : 80 euros
                <br />
                90 min : 90 euros
              </Typography>
            />
            <SmallTable
              title="Relaxation sonore (« sonothérapie »)"
              case01="Voyage sonore (utilisation de divers instruments sonores : bols chantants, gong, carillons, bols de cristal, sansula...)"
              case01_2="Relaxation aux bols chantants (« tibétains »)"
              case01_3="Diapasons « thérapeutiques »"
              case02="60 min : 60 euros"
            />
          </Box>
          <WaveIcon color="primary" sx={{ width: "300px", mt: 2, mb: 2 }} />
          <Typography sx={{ mt: -1, color: theme.palette.secondary.main }}>
            Espace Alizé
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ mx: 4, textAlign: "center" }}>
          <Grid xs={12} sm={6} >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/images/spaces/lagon_01.jpg`}
              sx={{
                width: { xs: "250px", sm: "500px" },
                borderStyle: "solid",
                borderWidth: 2,
                borderColor: theme.palette.secondary.main,
                borderRadius: "24px",
                mt: 1,
              }}
            />
          </Grid>
          <Grid xs={12} sm={6} >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/images/spaces/lagon_02.jpg`}
              sx={{
                width: { xs: "250px", sm: "500px" },
                borderStyle: "solid",
                borderWidth: 2,
                borderColor: theme.palette.secondary.main,
                borderRadius: "24px",
                mt: 1,
              }}
            />
          </Grid>
        </Grid>
        <TherapistNavigation currentTherapist={EnumTherapist.veroniqueFontaine} sx={{ mt: 4 }} />
      </Layout>
    </div >
  )
}
