import { FC } from 'react';
import { Typography, useTheme } from "@mui/material";
import { EnumHighlightSpanMode } from '../enums';

interface IProps {
    content: string,
    mode?: EnumHighlightSpanMode,
    bold?: boolean,

}

export const HighlightSpan: FC<IProps> = (props) => {
    const theme = useTheme()

    const getColor = () => {
        if (props.mode === EnumHighlightSpanMode.alternative) {
            return theme.palette.secondary.main
        }
        if (props.mode === EnumHighlightSpanMode.black) {
            return theme.palette.text.primary
        }
        return theme.palette.primary.main
    }

    return (
        <>
            {/* {
                (!props.mode || props.mode === EnumHighlightSpanMode.standard) &&
                <Typography
                    component="span"
                    sx={{
                        color: theme.palette.primary.main,
                        fontWeight: props.bold ? "700" : "unset",
                    }}
                >
                    {props.content}
                </Typography>
            }
            {
                props.mode === EnumHighlightSpanMode.alternative &&
                <Typography
                    component="span"
                    sx={{
                        color: theme.palette.secondary.main,
                        fontWeight: props.bold ? "700" : "unset",
                    }}
                >
                    {props.content}
                </Typography>
            } */}
            <Typography
                component="span"
                sx={{
                    color: getColor(),
                    fontWeight: props.bold ? "700" : "unset",
                }}
            >
                {props.content}
            </Typography>
        </>
    )
}


