import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

// import "../css/components/ResponsiveAppBar.css"
// import { Link, useLocation } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { NAVIGATE_ROOT, NAVIGATE_WHERE_PAGE, isActivePath } from '../utils/Navigation';
import { Link, useTheme } from '@mui/material';
import { KazoceaLogo } from './KazoceaLogo';
import { EnumKazoceaLogoMode } from '../enums';
// import { MetadocLogo } from './MetadocLogo';

interface IPage {
    title: string,
    path: string,
    domId: string,
    highlight: boolean,
    alternative: boolean,
}


const pages: IPage[] = [
    {
        title: 'Accueil',
        path: NAVIGATE_ROOT,
        domId: 'AccueilSection',
        highlight: true,
        alternative: false,
    },
    {
        title: 'Nos thérapeutes',
        path: NAVIGATE_ROOT,
        domId: 'TherapistsSection',
        highlight: false,
        alternative: false,
    },
    {
        title: 'Nos espaces à louer',
        path: NAVIGATE_ROOT,
        domId: 'SpacesSection',
        highlight: false,
        alternative: false,
    },
    {
        title: 'Nous situer',
        path: NAVIGATE_WHERE_PAGE,
        domId: 'WhereSection',
        highlight: false,
        alternative: false,
    },

    {
        title: 'Le jour de votre rendez-vous',
        path: NAVIGATE_WHERE_PAGE,
        domId: 'PatientRulesSection',
        highlight: false,
        alternative: true,
    },
];

const ResponsiveAppBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useTheme()


    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleButtonNavMenu = (page: IPage) => {
        setAnchorElNav(null);
        // navigate(page.path)
        const section = document.getElementById(page.domId);
        if (section) {
            // section.scrollIntoView({ behavior: 'smooth' }); // Le comportement 'smooth' permet un défilement fluide
            const offset = -100; // Ajustez cette valeur selon la distance à laquelle vous souhaitez vous arrêter au-dessus de la section
            const elementPosition = section.offsetTop + offset;
            window.scrollTo({
                top: elementPosition,
                behavior: 'smooth',
            });
        } else {
            navigate(`${page.path}?scrollTo=${page.domId}`)
        }
    };

    return (
        <AppBar className='ResponsiveAppBar' position='fixed' sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
            {/* <Container maxWidth="xl"> */}
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    {/* Mobile */}
                    <Box sx={{ display: { width: '100%', xs: 'flex', md: 'none', justifyContent: 'space-between', alignItems: 'center' } }} >
                        <Typography
                            component="a"
                            href={NAVIGATE_ROOT}
                            sx={{
                                textDecoration: 'none',
                            }}
                        >
                            <KazoceaLogo mode={EnumKazoceaLogoMode.accueilMobile} sx={{}} />
                        </Typography>
                        <Box>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="default"
                                className={`menu-icon ${Boolean(anchorElNav) ? "active" : ""}`}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page, index) => (
                                    <MenuItem key={index} onClick={handleCloseNavMenu}>
                                        <Link sx={{ textDecoration: "none" }} color={page.highlight ? "primary" : "secondary"} onClick={() => handleButtonNavMenu(page)} className={`${isActivePath(page.path, location.pathname) ? "active" : ""}`}>
                                            {page.title}
                                        </Link>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                    </Box>

                    {/* Desktop */}
                    <Box sx={{ width: '100%', display: { xs: 'none', md: 'flex', justifyContent: 'space-between', alignItems: 'center' } }} >
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href={NAVIGATE_ROOT}
                            sx={{
                                mr: 2,
                                display: { alignItems: 'center' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'black',
                                textDecoration: 'none',
                            }}
                            className="menu-link"
                        >
                            <KazoceaLogo mode={EnumKazoceaLogoMode.accueil} sx={{}} />
                        </Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            {pages.map((page, index) => (
                                <Button color={page.highlight ? "primary" : "secondary"}
                                    key={index}
                                    onClick={() => handleButtonNavMenu(page)}
                                    sx={{
                                        my: 2,
                                        fontSize: "18px",
                                        fontWeight: page.highlight ? "bold" : "normal",
                                        textTransform: page.alternative ? "none" : "uppercase",
                                        fontStyle: page.alternative ? "italic" : "inherit",
                                    }}
                                >
                                    {page.title}
                                </Button>
                            ))}
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar >
    );
};
export default ResponsiveAppBar;
